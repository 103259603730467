import { configureStore } from "@reduxjs/toolkit";
import API from "../API"
import userManagement from "../reducers/userManagement";
import clusterManagement from "../reducers/clusterManagement";
import data from "../reducers/data";
import setups from "../reducers/setups";
import reports from "../reducers/reports";
import setupManagement from "../reducers/setupManagement";

export default configureStore({
  reducer: {userManagement, clusterManagement, data, setups, reports, setupManagement},
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(API),
  
});