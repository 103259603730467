import {connect} from 'react-redux'
import Layout from '../reportIcon';

// const mapStateToProps = state => ({
//     suppliers: state.suppliers,
//     createSupplierData: state.createSupplierData
// })

// const mapDispatchToProps = dispatch => ({
//     getSuppliers: () => dispatch(getSuppliers()),
//     setCreateSupplier: (supplierData) => dispatch(setCreateSupplier(supplierData)),
//     createSupplier: () => dispatch(createSupplier()),
// })

export default connect(null, null)(Layout)