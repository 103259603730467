import theme from '../theme';
import classes from "../styles/common"
import { Typography } from '@mui/material';
import { debug as isDebug } from '../config'


function validateEmail(text) {
    let isValid = true
    if (typeof text !== "undefined") {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(text)) {
          isValid = false;
        }
      } else {
          isValid = false
      }
    return isValid
}


function emailWarning(text, color="error"){
    let output
    if (validateEmail(text) || text==="" || text===null || text===undefined) {
      output =  ""
    } else {
      output = <Typography color={color} style={{fontSize:"12px"}} >Please enter a valid email address</Typography> 
    }
    return output
  };


function clone(value) {
    let _ = require("underscore")
    return _.clone(value)
}


function comparePassword(password, password2){
    let compare = false
    if (password === password2) {
        compare = true
    }
    return compare
}


function isEmpty(value){
    let _ = require("underscore")
    if (_.isEmpty(value)){
      return true
    }
    return false
}

function isEqual(value1, value2){
    let _ = require("underscore")
    if (_.isEqual(value1, value2)){
        return true
    }
    return false
}

function coolJoin(input, separator, terminator){
    if (input.length === 1){
      return input
    }
    return input.slice(0, input.length-1).join(separator) + terminator + input[input.length-1]
  }

function checkPassword(str) {
    if (str) {
        if (str.length < 12) {
            return("Password is too short");
        } else if (str.length > 50) {
            return("Password is too long");
        } else if (str.search(/\d/) === -1) {
            return("Password should contain a number");
        } else if (str.search(/[a-z]/) === -1) {
            return("Password should contain a lower case (non-capital) letter");
        } else if (str.search(/[A-Z]/) === -1) {
            return("Password should contain an upper case (capital) letter");
        } else if (str.search(/[!@#$%^&*(\\/)_+]/) === -1) {
            return("Password should contain a special character, like ! ? $ * )");
        }
        return("ok");
    }
}

function passwordFeedback(str, color="error") {
  if ([null, ""].includes(str)){
      return ""
  }
  let response = checkPassword(str)
  let message
  if (response === "ok") {
      message = ""
  } else {
      message = <Typography color={color} style={{fontSize:"12px"}} >{response}</Typography> 
  }
  return message
}


function generateUUID()
{
    // from here: https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/8809472#8809472
	var d = new Date().getTime();
	
	if( window.performance && typeof window.performance.now === "function" )
	{
		d += performance.now();
	}
	
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c)
	{
		var r = (d + Math.random()*16)%16 | 0;
		d = Math.floor(d/16);
		return (c==='x' ? r : (r&0x3|0x8)).toString(16);
	});

return uuid;
}

function debug(text, variable=null)
{
    let show = isDebug
    if(variable !== null && show){
        return console.log(text, variable)
    } else if (show) {
        return console.log(text)
    }
}


export {comparePassword, clone, isEmpty, isEqual, coolJoin, checkPassword, passwordFeedback, generateUUID, debug, validateEmail, emailWarning}