import React from "react";
import theme from "../theme";
import ReactModal from "react-modal";
import { isEmpty } from "../general/utils";
import { Navigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import classes from "../styles/manageClusters";
import { isMobile } from "react-device-detect";
import { applyMode } from "../styles/manageClusters";
import RefreshIcon from '@mui/icons-material/Refresh';
import { inputLabelClasses } from "@mui/material/InputLabel";
import { clone, isEqual, coolJoin, debug } from "../general/utils";
import { INITIAL, LOADING, SUCCEEDED } from "../general/requestStates";
import { Typography, Grid, Autocomplete, TextField as MuiTextField, Button, Card, Tooltip, CircularProgress, IconButton} from "@mui/material";


class ManageClusters extends React.Component {
  emptySelection = {
    clients: [],
    countries: [],
    sites: [],
    buildings: [],
    computers: [],
  };

  emptyCluster = {
    name: "",
    sid: null,
    selection: clone(this.emptySelection),
  };

  selection = clone(this.emptySelection);
  state = {
    current: { name: "", sid: null, selection: this.selection },
    initial: null,
    clusters: [],
    tip: "",
    refresh: false
  };

  showModal = false;
  mode = "blank";
  clearTip = false;

  warnings = {
    clients: null,
    countries: null,
    sites: null,
    buildings: null,
  };


  componentDidMount() {
    const { getClusters, getFilterOptions } = this.props;
    const { refresh } = this.state
    getFilterOptions({clients: [], countries: [], sites: [], buildings: [], computers: [], refresh: false});
    getClusters();
  }


  componentDidUpdate(prevProps) {
    const { getClustersRS, submitDataRS, deleteDataRS, clusters, latestSubmission } = this.props;
    const { tip } = this.state;

    if (getClustersRS === SUCCEEDED && prevProps.getClustersRS === LOADING) {
      // clusters were retreived
      this.clearTip = false;
      this.setState({ clusters });
    } else if (
      submitDataRS === SUCCEEDED &&
      prevProps.submitDataRS === LOADING
    ) {
      // a cluster was saved
      let replacedCluster = this.state.clusters.findIndex(
        (e) => e.sid === latestSubmission.sid
      );
      let new_clusters;
      if (replacedCluster !== -1) {
        new_clusters = clone(this.state.clusters);
        new_clusters[replacedCluster] = latestSubmission;
      } else {
        new_clusters = clone(this.state.clusters);
        new_clusters.push(latestSubmission);
      }
      this.clearTip = false;
      this.setState({
        clusters: new_clusters,
        initial: latestSubmission,
        current: clone(latestSubmission),
        tip: "Cluster saved!",
      });
    } else if (
      deleteDataRS === SUCCEEDED &&
      prevProps.deleteDataRS === LOADING
    ) {
      // a cluster was deleted
      let deletedCluster = this.state.clusters.findIndex(
        (e) => e.sid === this.state.current.sid
      );
      let new_clusters = clone(this.state.clusters);
      new_clusters.splice(deletedCluster, 1);
      this.selection = clone(this.emptySelection);
      this.clearTip = false;
      this.setState({
        clusters: new_clusters,
        current: clone(this.emptyCluster),
        initial: null,
        tip: "Cluster deleted!",
      });
    } else if (tip && this.clearTip) {
      this.setState({ tip: "" });
    } else if (tip) {
      this.clearTip = true;
    }
  }

  handleSelect(key, event, value) {
    const { getFilterOptions } = this.props;
    const { current } = this.state;
    this.selection[key] = value;
    current.selection = this.selection;
    getFilterOptions(this.selection);
    this.setState({...this.selection});
  }

  handleNameChange(event) {
    const { current } = this.state;
    current.name = event.target.value;
    this.setState({});
  }

  handleSelectCluster(event, value) {
    const { getFilterOptions } = this.props;
    const { current } = this.state;
    if (value) {
      current.name = value.name;
      current.sid = value.sid;
      this.selection = value.selection;
      this.selectClusterScope(value);
    } else {
      current.name = "";
      current.sid = null;
      this.selection = clone(this.emptySelection);
    }
    current.selection = this.selection;
    getFilterOptions(value ? value.selection : null);
    this.setState({ initial: value, current });
  }

  handleSave() {
    const { submitData } = this.props;
    const { current } = this.state;
    submitData({ database: "ergopak_applications", table: "cluster", record: current });
  }

  actuallyDelete() {
    const { deleteData } = this.props;
    const { current } = this.state;
    deleteData({ table: "cluster", record: current });
    this.toggleModal();
  }

  handleCancel() {
    const { initial } = this.state;
    let new_current = clone(initial);
    this.selection = clone(initial.selection);
    new_current.selection = this.selection;
    this.setState({ current: new_current });
  }

  toggleModal(reRenderNow = false) {
    this.showModal = !this.showModal;
    if (reRenderNow) {
      this.setState({});
    }
  }

  selectClusterScope(cluster) {
    this.selection = clone(cluster.selection);
  }

  generateWarnings() {
    this.generateWarning("computers", "buildings");
    this.generateWarning("buildings", "sites");
    this.generateWarning("sites", "clients");
    this.generateWarning("sites", "countries");
  }

  generateWarning(child_key, parent_key) {
    const children = this.selection[child_key];
    const parent = this.selection[parent_key];
    let warning;
    if (children.length) {
      let sid_key =
        parent_key !== "countries"
          ? `parent_${parent_key.slice(0, -1)}_sid`
          : "parent_country_sid";
      let parent_sids = children.map((e) => e[sid_key]);
      let barren_parents = parent.filter((e) => !parent_sids.includes(e.sid));
      if (barren_parents.length) {
        let barren_string = coolJoin(
          barren_parents.map((e) => e.name),
          ", ",
          " and "
        );
        warning = (
          <Typography style={classes(theme, isMobile).warning}>
            Warning: no {child_key} from {barren_string} are included.
          </Typography>
        );
      }
    }
    this.warnings[parent_key] = warning ? warning : null;
  }

  getMode() {
    const { getClustersRS, submitDataRS, deleteDataRS, getFilterOptionsRS } = this.props;
    const { current, initial } = this.state;
    if ([INITIAL, LOADING].includes(getFilterOptionsRS)) {
      this.mode = "refreshing";
    }
    if ([LOADING, INITIAL].includes(getClustersRS)) {
      this.mode = "init";
    } else if (submitDataRS === LOADING) {
      this.mode = "saving";
    } else if (deleteDataRS === LOADING) {
      this.mode = "deleting";
    } else if (initial) {
      if (isEqual(current, initial)) {
        this.mode = "view";
      } else {
        this.mode = "modify";
      }
    } else if (!isEqual(current, this.emptyCluster)) {

      this.mode = "new"

    } else {
      this.mode = "blank";
    }
  }

  checkClusterValidity() {
    const { current } = this.state;
    let s = this.selection;
    let selectionLength =
      s.clients.length +
      s.countries.length +
      s.sites.length +
      s.buildings.length +
      s.computers.length;
    if (current.name === "" || selectionLength === 0) {
      return false;
    } else {
      return true;
    }
  }

  makeSaveButton(modeStyles) {
    let button = (
      <Button
        variant="contained"
        style={classes(theme, isMobile).button}
        disabled={modeStyles.disableSaveButton}
        onClick={this.handleSave.bind(this)}
      >
        {" "}
        Save{" "}
      </Button>
    );
    if (modeStyles.disableSaveButton) {
      button = (
        <Tooltip title="To save, you must make at least one selection and specify a cluster name.">
          <span>{button}</span>
        </Tooltip>
      );
    }
    return button;
  }

  navigateUser() {
    const { oktaUser } = this.props
    if (oktaUser) {
      return <Navigate to="/okta/login" />
    }
    return <Navigate to="/login" />
  }

  render() {
    debug("rendering clusterManagement. state: ", this.state, this.selection);
    const {
      clientOptions,
      countryOptions,
      siteOptions,
      buildingOptions,
      computerOptions,
      user,
    } = this.props;
    if (isEmpty(user)) {
      return this.navigateUser()
    }
    const { clients, countries, sites, buildings, computers } = this.selection;
    const { current, clusters, initial, tip } = this.state;
    const {getFilterOptions} = this.props
    this.getMode();
    let modeStyles = applyMode(this.mode, this.checkClusterValidity.bind(this));
    this.generateWarnings();

    let redirect = isEmpty(this.props.user) || this.props.user.is_admin ? "" :  <Navigate to="/home" replace={true}/>


    return (
      <div>
        <ReactModal
          isOpen={this.showModal}
          onAfterOpen={this.afterOpenModal}
          ariaHideApp={false}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%",
              borderStyle: "solid",
              borderColor: "black",
              borderWidth: "2px",
              borderRadius: "1em",
            },
          }}
        >
          <div
            style={{
              textAlign: "center",
              marginBottom: "1em",
            }}
          >
            <Typography>Are you sure ? </Typography>
          </div>

          <Grid>
            <Button
              variant="contained"
              style={{ margin: "1em" }}
              onClick={this.actuallyDelete.bind(this)}
            >
              Yes, delete it forever!
            </Button>
            <Button
              variant="contained"
              onClick={this.toggleModal.bind(this, true)}
              style={{ margin: "1em" }}
            >
              No
            </Button>
          </Grid>
        </ReactModal>
        {/* styling for the block is in the div */}
        <div style={classes(theme, isMobile).mainCard}>
            <Grid
              // container
              // direction="column"
              // alignItems="flex-start"
              // justifyContent="center"
            >
              <div>
                <Grid container direction="row" alignItems="flex-end">
                  {/* select a cluster style */}
                  <Autocomplete
                    sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                    style={isMobile ? {...classes(theme, isMobile).filterField, width: "100%"} : classes(theme, isMobile).filterField}
                    options={clusters}
                    getOptionLabel={(e) => e.name}
                    onChange={this.handleSelectCluster.bind(this)}
                    isOptionEqualToValue={(o, v) => o.sid === v.sid}
                    value={initial}
                    inputProps={{ style: { color: "white" } }}
                    disabled={modeStyles.disableClusterSelect}
                    hidden={modeStyles.hideClusterSelect}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.sid}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        
                        label={modeStyles.selectClusterLabel}
                        variant="outlined"
                        sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, path:{color:"#FFFFFF"}, label:{color:"#FFFFFF"}}}
                      />
                    )}
                  />
                  {/* type cluster name */}
                  <div>
                    <MuiTextField
                      inputProps={{ style: { color: "white" } }}
                      InputLabelProps={{sx: {color: "#FFFFFF", [`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}}
                      label={modeStyles.newClusterNameLabel}
                      variant="standard"
                      style={ isMobile ? {...classes(theme, isMobile).filterField, width: "100%", display: modeStyles.hideClusterName ? "none" : null} : 
                      {...classes(theme, isMobile).filterField, width: "18em", display: modeStyles.hideClusterName ? "none" : null}}
                      value={current.name}
                      onChange={this.handleNameChange.bind(this)}
                    />
                    {/* <Card
                      style={{
                        height: "0.75em",
                        borderColor: "#323232",
                      }}
                      sx={{div:{color:'#323232'}}}
                    /> */}
                  </div>
                  <div hidden={modeStyles.hideClusterSelect}>
                    <Button
                        variant="outlined"
                        onClick={() => {getFilterOptions({clients: [], countries: [], sites: [], buildings: [], computers: [], refresh: true})}}
                        style={{ margin: "1em", color: '#059df5', borderColor:'#059df5'}}
                      > 
                      <Grid container direction="row" alignItems="center">
                        <RefreshIcon sx={{color:'#059df5'}} style={{height: "0.8em", marginLeft: "-0.3em", color: '#059df5'}} />
                        Refresh Cluster Scope
                      </Grid>
                    </Button> 
                  </div>
                  <div hidden={modeStyles.hideSpinner}>
                    <Grid
                      container
                      direction="row"
                      style={{ margin: theme.spacing(1) }}
                      alignItems="center"
                    >
                      <CircularProgress
                        style={{ marginRight: theme.spacing(1) }}
                      />
                      <Typography style={classes(theme, isMobile).tips}>
                        {" "}
                        {modeStyles.spinnerText}{" "}
                      </Typography>
                    </Grid>
                  </div>
                  {tip ? (
                    <Typography
                      style={{
                        ...classes(theme, isMobile).tips,
                        marginBottom: theme.spacing(1.5),
                      }}
                    >
                      {" "}
                      {tip}{" "}
                    </Typography>
                  ) : null}
                </Grid>
              </div>

              <div hidden={modeStyles.hideScope}>
                <Typography
                  style={{
                    ...classes(theme).label,
                    fontSize: "1.2rem",
                    marginBottom: theme.spacing(-0.5),
                  }}
                >
                  {" "}
                  Cluster scope:{" "}
                </Typography>

                <div>
                  <Grid container direction="row">
                    <div>
                      <Autocomplete
                        sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                        style={classes(theme, isMobile).longFilterField}
                        multiple
                        options={clientOptions ? clientOptions : []}
                        value={clients}
                        isOptionEqualToValue={(o, v) => o.sid === v.sid}
                        getOptionLabel={(option) => option.display_name}
                        onChange={this.handleSelect.bind(this, "clients")}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label="Clients"
                            variant="outlined"
                            sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, path:{color:"#FFFFFF"}}}
                          />
                        )}
                      />
                      {this.warnings.clients}
                    </div>

                    <div>
                      <Autocomplete
                        sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                        style={classes(theme, isMobile).longFilterField}
                        multiple
                        options={countryOptions ? countryOptions : []}
                        value={countries}
                        isOptionEqualToValue={(o, v) => o.sid === v.sid}
                        getOptionLabel={(option) => option.display_name}
                        onChange={this.handleSelect.bind(this, "countries")}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label="Countries"
                            variant="outlined"
                            sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, path:{color:"#FFFFFF"}}}
                          />
                        )}
                      />
                      {this.warnings.countries}
                    </div>
                  </Grid>
                </div>

                <div>
                  <Grid container direction="column">
                    <Autocomplete
                      sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                      style={classes(theme, isMobile).longFilterField}
                      multiple
                      options={siteOptions ? siteOptions : []}
                      value={sites}
                      isOptionEqualToValue={(o, v) => o.sid === v.sid}
                      getOptionLabel={(option) => option.display_name}
                      onChange={this.handleSelect.bind(this, "sites")}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label={
                            this.selection.sites.length
                              ? "Sites"
                              : this.selection.computers.length
                              ? "Only listed computers will be included"
                              : this.selection.buildings.length
                              ? "Only listed buildings will be included"
                              : "All sites in scope"
                          }
                          variant="outlined"
                          sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, path:{color:"#FFFFFF"}}}
                        />
                      )}
                    />

                    {this.warnings.sites}

                    <Autocomplete
                      sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                      style={classes(theme, isMobile).longFilterField}
                      multiple
                      options={buildingOptions ? buildingOptions : []}
                      value={buildings}
                      isOptionEqualToValue={(o, v) => o.sid === v.sid}
                      getOptionLabel={(option) => option.display_name}
                      onChange={this.handleSelect.bind(this, "buildings")}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label={
                            this.selection.buildings.length
                              ? "Buildings"
                              : this.selection.computers.length
                              ? "Only listed computers will be included"
                              : "All buildings in scope"
                          }
                          variant="outlined"
                          sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, path:{color:"#FFFFFF"}}}
                        />
                      )}
                    />

                    {this.warnings.buildings}

                    <Autocomplete
                      sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                      style={classes(theme, isMobile).longFilterField}
                      multiple
                      options={computerOptions ? computerOptions : []}
                      value={computers}
                      isOptionEqualToValue={(o, v) => o.sid === v.sid}
                      getOptionLabel={(option) => option.display_name}
                      onChange={this.handleSelect.bind(this, "computers")}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label={
                            this.selection.computers.length
                              ? "Computers"
                              : "All computers in scope"
                          }
                          variant="outlined"
                          sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, path:{color:"#FFFFFF"}}}
                        />
                      )}
                    />
                  </Grid>
                </div>

                <div>
                  <Grid container direction="row">
                    {modeStyles.hideSaveButton
                      ? null
                      : this.makeSaveButton(modeStyles)}
                    {modeStyles.hideCancelButton ? null : (
                      <Button
                        variant="contained"
                        style={classes(theme).button}
                        onClick={this.handleCancel.bind(this)}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                    )}
                    {modeStyles.hideDeleteButton ? null : (
                      <Button
                        variant="contained"
                        style={classes(theme).button}
                        onClick={this.toggleModal.bind(this, true)}
                        disabled={modeStyles.disableDeleteButton}
                      >
                        {" "}
                        Delete
                      </Button>
                    )}
                  </Grid>
                </div>
              </div>
            </Grid>
        </div>
        {redirect}
      </div>
    );
  }
}

export default styled(ManageClusters)({ theme });
