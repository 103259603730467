import { clone, isEmpty } from "../general/utils";


const emptyOptions = {
  clients: [],
  countries: [],
  sites: [],
  buildings: [],
  computers: [],
  lines: [],
  orders: []
}


function getReportDataInstance(sector, sid, userScope) {
  for (var i in userScope) {
    if (userScope[i][sector + "_sid"] === sid) {
        return userScope[i];
    }
  }
}


//gets all sites that exist in a selected country
function getSiteSpecificFilter(countries, selections) {
  const { sites } = selections;
  let filteredSites = [];
  for (let i=0; i<sites.length; i++) {
    let cmp = sites[i];
    for (let j=0; j<countries.length; j++){
      if (countries[j].sid === cmp.parent_country_sid){
        filteredSites.push(cmp)
      }
    }
  }
  return filteredSites;
}

//gets all buildings that exist in a selected site
function getBuildingSpecificFilter(sites, selections) {
  const { buildings } = selections;
  let filteredBuildings = [];
  for (let i=0; i<buildings.length; i++) {
    let cmp = buildings[i];
    for (let j=0; j<sites.length; j++){
      if (sites[j].sid === cmp.parent_site_sid){
        filteredBuildings.push(cmp)
      }
    }
  }
  return filteredBuildings;
}

//gets all computers in a selected building
function getComputerSpecificFilter(buildings, selections) {
  const { computers } = selections;
  let filteredComputers = [];
  for (let i=0; i<computers.length; i++) {
    let cmp = computers[i];
    for (let j=0; j<buildings.length; j++){
      if (buildings[j].sid === cmp.parent_building_sid){
        filteredComputers.push(cmp)
      }
    }
  }
  return filteredComputers;
}

//gets all lines in a selected computer
function getLineSpecificFilter(computers, selections) {
  const { lines } = selections;
  let filteredLines = [];
  for (let i=0; i<lines.length; i++) {
    let cmp = lines[i];
    for (let j=0; j<computers.length; j++){
      if (computers[j].sid === cmp.parent_computer_sid){
        filteredLines.push(cmp)
      }
    }
  }
  return filteredLines;
}

//gets all orders in a selected line
function getOrderSpecificFilter(lines, selections) {
  const { orders } = selections;
  let filteredOrders = [];
  for (let i=0; i<orders.length; i++) {
    let cmp = orders[i];
    for (let j=0; j<lines.length; j++){
      if (lines[j].sid === cmp.origin){
        filteredOrders.push(cmp)
      }
    }
  }
  return filteredOrders;
}




function getReportStates(key, value, selections, userScope, state) {
  let singleValue = !Array.isArray(value) ? value : isEmpty(value) ? null : value[0];
  let report = singleValue ? getReportDataInstance(key, singleValue.sid, userScope) : singleValue;
  let stateChanges = clone(emptyOptions);
  
  //sets the values if all filters if a client was selected
  if (key === "client") {
    stateChanges["clients"] = value;
    let [clientOptions, countryOptions, siteOptions, buildingOptions, computerOptions, lineOptions, orderOptions] = getReportOptions(userScope, stateChanges)
    if (stateChanges["clients"].length){
      if (value.length >= countryOptions){
        stateChanges["countries"] = countryOptions;
      }
    }
    
    return stateChanges;
  }

  const { clients } = state;
  stateChanges["clients"] = !isEmpty(clients) ? clients : [{ sid: report["client_sid"], name: report["client_name"] }];

  //sets the values if all filters if a country was selected
  if (key === "country") {
    stateChanges["countries"] = value;
    let [clientOptions, countryOptions, siteOptions, buildingOptions, computerOptions, lineOptions, orderOptions] = getReportOptions(userScope, stateChanges)
    stateChanges["sites"] = getSiteSpecificFilter(value, selections);
    return stateChanges;
  }
  const { countries } = state;
  stateChanges["countries"] = !isEmpty(countries) ? countries: [{ sid: report["country_sid"], name: report["country_name"], parent_client_sid: report["country_parent_client_sid"] }];


  //sets the values if all filters if a site was selected
  if (key === "site") {
    stateChanges["sites"] = value;
    stateChanges["buildings"] = getBuildingSpecificFilter(value, selections);
    return stateChanges;
  }
  const { sites } = state;
  // keeps the buildings if present
  // otherwise add the building to the current selection
  stateChanges["sites"] = !isEmpty(sites) ? sites: [{ sid: report["site_sid"], name: report["site_name"], parent_client_sid: report["site_parent_client_sid"], parent_country_sid: report["site_parent_country_sid"] }];
  
  //sets the values if all filters if a building was selected
  if (key === "building") {
    stateChanges["buildings"] = value;
    stateChanges["computers"] = getComputerSpecificFilter(value, selections);
    return stateChanges;
  }
  const { buildings } = state;
  // keeps the buildings if present
  // otherwise add the building to the current selection
  stateChanges["buildings"] = !isEmpty(buildings) ? buildings : [{ sid: report["building_sid"], name: report["building_name"], parent_site_sid: report["building_parent_site_sid"], full_path: report["building_site_path"]}];
  
  //sets the values if all filters if a computer was selected
  if (key === "computer") {
    stateChanges["computers"] = value;
    stateChanges["lines"] = getLineSpecificFilter(value, selections);
    return stateChanges;
  }
  const { computers } = state;
  // keeps the buildings if present
  // otherwise add the building to the current selection
  stateChanges["computers"] = !isEmpty(computers) ? computers : [{ sid: report["computer_sid"], name: report["computer_name"], parent_building_sid: report['computer_parent_building_sid'], building_path: report['computer_building_path'],  full_path: report['computer_full_path']}];
  // adds the computer to the current selection
  
  //sets the values if all filters if a line was selected
  if (key === "line") {
    stateChanges["lines"] = value;
    stateChanges["orders"] = getOrderSpecificFilter(value, selections);
    return stateChanges;
  }
  const { lines } = state;
  // keeps the buildings if present
  // otherwise add the building to the current selection
  stateChanges["lines"] = !isEmpty(lines) ? lines : [{ sid: report["line_sid"], name: report["line_name"], parent_computer_sid: report['line_parent_computer_sid'], building_path: report['line_building_computer_path'], computer_path: report['line_computer_path'], full_path: report['line_full_path'], line_id: report['line_id']}];
  
  //sets the values if all filters if a order was selected
  if (key === "order") {
    stateChanges["orders"] = value;
    return stateChanges
  }
  return stateChanges
}



function getReportOptions(userScope, selection){
    const { clients, countries, sites, buildings, computers, lines, orders } = selection;
    let test = []

    let sectors = [[], [], [], [], [], [], []];
    for (var i in userScope) {
        test.push(userScope[i])
        const singleData = userScope[i];
        
        const { 
          client_sid, 
          client_name,
          client_child_country_sid, 
          country_sid, 
          country_name,
          country_parent_client_sid, 
          site_sid,
          site_parent_client_sid, 
          site_parent_country_sid, 
          site_name, 
          building_sid, 
          building_parent_site_sid, 
          building_name, 
          building_site_path, 
          computer_sid,
          computer_parent_building_sid, 
          computer_name, 
          computer_building_path, 
          computer_full_path, 
          line_sid, 
          line_parent_computer_sid, 
          line_name, 
          line_computer_path, 
          line_building_computer_path, 
          line_full_path, 
          line_id, 
          order_sid, 
          order_batchnumber, 
          order_start_date, 
          order_end_date, 
          order_status, 
          order_origin } = singleData;
        
        //AVALIBLE OPTIONS IF NOTHING IS SELECTED
        if (!clients.length && !countries.length && !sites.length && !buildings.length && !computers.length && !lines.length && !orders.length) {
          if (!sectors[0].some((e) => e.sid === client_sid)) {
            sectors[0].push({ sid: client_sid, name: client_name, child_country_sid: client_child_country_sid });
          }
          if (!sectors[1].some((e) => e.sid === country_sid)) {
            sectors[1].push({ sid: country_sid, name: country_name, parent_client_sid: country_parent_client_sid});
          }
          if (!sectors[2].some((e) => e.sid === site_sid)) {
            sectors[2].push({ sid: site_sid, name: site_name, parent_client_sid: site_parent_client_sid, 
              parent_country_sid: site_parent_country_sid });
          }
          if (!sectors[3].some((e) => e.sid === building_sid)) {
            sectors[3].push({ sid: building_sid, name: building_name, parent_site_sid: building_parent_site_sid,
            full_path: building_site_path});
          }
          if (!sectors[4].some((e) => e.sid === computer_sid)) {
            sectors[4].push({ sid: computer_sid, name: computer_name, parent_building_sid: computer_parent_building_sid,
            building_path: computer_building_path, full_path: computer_full_path});
          }
          if (!sectors[5].some((e) => e.sid === line_sid)) {
            sectors[5].push({ sid: line_sid, name: line_name, parent_computer_sid: line_parent_computer_sid,
            building_path: line_building_computer_path, computer_path: line_computer_path, full_path: line_full_path,
            line_id: line_id});
          }
          if (!sectors[6].some((e) => e.batchnumber === order_batchnumber)) {
            sectors[6].push({sid:order_sid, batchnumber: order_batchnumber, StartDate: order_start_date, 
            EndDate: order_end_date, isFinished: order_status, origin: order_origin});
          }
        }



        //AVALIBLE OPTIONS IF A CLIENT IS SELECTED
        if (clients.length && !countries.length && !sites.length && !buildings.length && !computers.length && !lines.length && !orders.length) {

          if (!sectors[0].some((e) => e.sid === client_sid)) {
            sectors[0].push({ sid: client_sid, name: client_name, child_country_sid: client_child_country_sid });
          }

          for (let i = 0; i<clients.length; i++){
            if (country_parent_client_sid.includes(clients[i].sid) && !sectors[1].some((e) => e.sid === country_sid)) {
              sectors[1].push({ sid: country_sid, name: country_name, parent_client_sid: country_parent_client_sid });
            }
          }

          for (let i = 0; i<clients.length; i++){
            if (clients[i].sid === site_parent_client_sid && !sectors[2].some((e) => e.sid === site_sid)) {
              sectors[2].push({ sid: site_sid, name: site_name, parent_client_sid: site_parent_client_sid, 
                parent_country_sid: site_parent_country_sid });
            }
          }

          for (let i = 0; i<sectors[2].length; i++){
            if (sectors[2][i].sid === building_parent_site_sid && !sectors[3].some((e) => e.sid === building_sid)) {
              sectors[3].push({ sid: building_sid, name: building_name, parent_site_sid: building_parent_site_sid,
              full_path: building_site_path});
            }
          }
          for (let i = 0; i<sectors[3].length; i++){
            if (sectors[3][i].sid === computer_parent_building_sid && !sectors[4].some((e) => e.sid === computer_sid)) {
              sectors[4].push({ sid: computer_sid, name: computer_name, parent_building_sid: computer_parent_building_sid,
              building_path: computer_building_path, full_path: computer_full_path});
            }
          }
          for (let i = 0; i<sectors[4].length; i++){
            if (sectors[4][i].sid === line_parent_computer_sid && !sectors[5].some((e) => e.sid === line_sid)) {
              sectors[5].push({ sid: line_sid, name: line_name, parent_computer_sid: line_parent_computer_sid,
              building_path: line_building_computer_path, computer_path: line_computer_path, full_path: line_full_path,
              line_id: line_id});
            }
          }
          for (let i = 0; i<sectors[5].length; i++){
            if (sectors[5][i].sid === order_origin && !sectors[6].some((e) => e.batchnumber === order_batchnumber)) {
              sectors[6].push({sid: order_sid, batchnumber: order_batchnumber, StartDate: order_start_date, 
              EndDate: order_end_date, isFinished: order_status, origin: order_origin});
            }
          }
        }
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


        //AVALIBLE OPTIONS IF A CLIENT AND COUNTRY IS SELECTED
        if (clients.length && countries.length && !sites.length && !buildings.length && !computers.length && !lines.length
          && !orders.length) {

            if (!sectors[0].some((e) => e.sid === client_sid)) {
              sectors[0].push({ sid: client_sid, name: client_name, child_country_sid: client_child_country_sid });
            }

            for (let i = 0; i<clients.length; i++){
              if (country_parent_client_sid.includes(clients[i].sid) && !sectors[1].some((e) => e.sid === country_sid)) {
                sectors[1].push({ sid: country_sid, name: country_name, parent_client_sid: country_parent_client_sid });
              }
            }


            for (let i = 0; i<clients.length; i++){
              for (let j = 0; j<countries.length; j++){
                if (clients[i].sid === site_parent_client_sid && countries[j].sid === site_parent_country_sid && !sectors[2].some((e) => e.sid === site_sid)) {
                  sectors[2].push({ sid: site_sid, name: site_name, parent_client_sid: site_parent_client_sid, 
                    parent_country_sid: site_parent_country_sid });
                }
              }
            }


            for (let i = 0; i<sectors[2].length; i++){
              if (sectors[2][i].sid === building_parent_site_sid && !sectors[3].some((e) => e.sid === building_sid)) {
                sectors[3].push({ sid: building_sid, name: building_name, parent_site_sid: building_parent_site_sid,
                full_path: building_site_path});
              }
            }
            for (let i = 0; i<sectors[3].length; i++){
              if (sectors[3][i].sid === computer_parent_building_sid && !sectors[4].some((e) => e.sid === computer_sid)) {
                sectors[4].push({ sid: computer_sid, name: computer_name, parent_building_sid: computer_parent_building_sid,
                building_path: computer_building_path, full_path: computer_full_path});
              }
            }
            for (let i = 0; i<sectors[4].length; i++){
              if (sectors[4][i].sid === line_parent_computer_sid && !sectors[5].some((e) => e.sid === line_sid)) {
                sectors[5].push({ sid: line_sid, name: line_name, parent_computer_sid: line_parent_computer_sid,
                building_path: line_building_computer_path, computer_path: line_computer_path, full_path: line_full_path,
                line_id: line_id});
              }
            }
            for (let i = 0; i<sectors[5].length; i++){
              if (sectors[5][i].sid === order_origin && !sectors[6].some((e) => e.batchnumber === order_batchnumber)) {
                sectors[6].push({sid:order_sid, batchnumber: order_batchnumber, StartDate: order_start_date, 
                EndDate: order_end_date, isFinished: order_status, origin: order_origin});
              }
            }
        }

        //AVALIBLE OPTIONS IF A CLIENT AND COUNTRY AND SITE IS SELECTED
        if (clients.length && countries.length && sites.length && !buildings.length && !computers.length && !lines.length && !orders.length){

          if (!sectors[0].some((e) => e.sid === client_sid)) {
            sectors[0].push({ sid: client_sid, name: client_name, child_country_sid: client_child_country_sid });
          }

          for (let i = 0; i<clients.length; i++){
            if (country_parent_client_sid.includes(clients[i].sid) && !sectors[1].some((e) => e.sid === country_sid)) {
              sectors[1].push({ sid: country_sid, name: country_name, parent_client_sid: country_parent_client_sid });
            }
          }

          for (let i = 0; i<clients.length; i++){
            for (let j = 0; j<countries.length; j++){
              if (clients[i].sid === site_parent_client_sid && countries[j].sid === site_parent_country_sid && !sectors[2].some((e) => e.sid === site_sid)) {
                sectors[2].push({ sid: site_sid, name: site_name, parent_client_sid: site_parent_client_sid, 
                  parent_country_sid: site_parent_country_sid });
              }
            }
          }

          for (let i = 0; i<sites.length; i++){
            if (sites[i].sid === building_parent_site_sid && !sectors[3].some((e) => e.sid === building_sid)) {
              sectors[3].push({ sid: building_sid, name: building_name, parent_site_sid: building_parent_site_sid,
              full_path: building_site_path});
            }
          }
          for (let i = 0; i<sectors[3].length; i++){
            if (sectors[3][i].sid === computer_parent_building_sid && !sectors[4].some((e) => e.sid === computer_sid)) {
              sectors[4].push({ sid: computer_sid, name: computer_name, parent_building_sid: computer_parent_building_sid,
              building_path: computer_building_path, full_path: computer_full_path});
            }
          }
          for (let i = 0; i<sectors[4].length; i++){
            if (sectors[4][i].sid === line_parent_computer_sid && !sectors[5].some((e) => e.sid === line_sid)) {
              sectors[5].push({ sid: line_sid, name: line_name, parent_computer_sid: line_parent_computer_sid,
              building_path: line_building_computer_path, computer_path: line_computer_path, full_path: line_full_path,
              line_id: line_id});
            }
          }
          for (let i = 0; i<sectors[5].length; i++){
            if (sectors[5][i].sid === order_origin && !sectors[6].some((e) => e.batchnumber === order_batchnumber)) {
              sectors[6].push({sid: order_sid, batchnumber: order_batchnumber, StartDate: order_start_date, 
              EndDate: order_end_date, isFinished: order_status, origin: order_origin});
            }
          }
        }

        //AVALIBLE OPTIONS IF A CLIENT AND COUNTRY AND SITE AND BUILDING IS SELECTED
        if (clients.length && countries.length && sites.length && buildings.length && !computers.length && !lines.length && !orders.length){
          if (!sectors[0].some((e) => e.sid === client_sid)) {
            sectors[0].push({ sid: client_sid, name: client_name, child_country_sid: client_child_country_sid });
          }
                    for (let i = 0; i<clients.length; i++){
            if (country_parent_client_sid.includes(clients[i].sid) && !sectors[1].some((e) => e.sid === country_sid)) {
              sectors[1].push({ sid: country_sid, name: country_name, parent_client_sid: country_parent_client_sid });
            }
          }

          for (let i = 0; i<clients.length; i++){
            for (let j = 0; j<countries.length; j++){
              if (clients[i].sid === site_parent_client_sid && countries[j].sid === site_parent_country_sid && !sectors[2].some((e) => e.sid === site_sid)) {
                sectors[2].push({ sid: site_sid, name: site_name, parent_client_sid: site_parent_client_sid, 
                  parent_country_sid: site_parent_country_sid });
              }
            }
          }
          
          for (let i = 0; i<sites.length; i++){
            if (sites[i].sid === building_parent_site_sid && !sectors[3].some((e) => e.sid === building_sid)) {
              sectors[3].push({ sid: building_sid, name: building_name, parent_site_sid: building_parent_site_sid,
              full_path: building_site_path});
            }
          }
          for (let i = 0; i<sectors[3].length; i++){
            if (sectors[3][i].sid === computer_parent_building_sid && !sectors[4].some((e) => e.sid === computer_sid)) {
              sectors[4].push({ sid: computer_sid, name: computer_name, parent_building_sid: computer_parent_building_sid,
              building_path: computer_building_path, full_path: computer_full_path});
            }
          }
          for (let i = 0; i<sectors[4].length; i++){
            if (sectors[4][i].sid === line_parent_computer_sid && !sectors[5].some((e) => e.sid === line_sid)) {
              sectors[5].push({ sid: line_sid, name: line_name, parent_computer_sid: line_parent_computer_sid,
              building_path: line_building_computer_path, computer_path: line_computer_path, full_path: line_full_path,
              line_id: line_id});
            }
          }
          for (let i = 0; i<sectors[5].length; i++){
            if (sectors[5][i].sid === order_origin && !sectors[6].some((e) => e.batchnumber === order_batchnumber)) {
              sectors[6].push({sid:order_sid, batchnumber: order_batchnumber, StartDate: order_start_date, 
              EndDate: order_end_date, isFinished: order_status, origin: order_origin});
            }
          }
        }

        //AVALIBLE OPTIONS IF A CLIENT AND COUNTRY AND SITE AND BUILDING AND COMPUTER IS SELECTED
        if (clients.length && countries.length && sites.length && buildings.length && computers.length && !lines.length && !orders.length){
          if (!sectors[0].some((e) => e.sid === client_sid)) {
            sectors[0].push({ sid: client_sid, name: client_name, child_country_sid: client_child_country_sid });
          }
          for (let i = 0; i<clients.length; i++){
            if (country_parent_client_sid.includes(clients[i].sid) && !sectors[1].some((e) => e.sid === country_sid)) {
              sectors[1].push({ sid: country_sid, name: country_name, parent_client_sid: country_parent_client_sid });
            }
          }

          for (let i = 0; i<clients.length; i++){
            for (let j = 0; j<countries.length; j++){
              if (clients[i].sid === site_parent_client_sid && countries[j].sid === site_parent_country_sid && !sectors[2].some((e) => e.sid === site_sid)) {
                sectors[2].push({ sid: site_sid, name: site_name, parent_client_sid: site_parent_client_sid, 
                  parent_country_sid: site_parent_country_sid });
              }
            }
          }
          for (let i = 0; i<sites.length; i++){
            if (sites[i].sid === building_parent_site_sid && !sectors[3].some((e) => e.sid === building_sid)) {
              sectors[3].push({ sid: building_sid, name: building_name, parent_site_sid: building_parent_site_sid,
              full_path: building_site_path});
            }
          }
          for (let i = 0; i<buildings.length; i++){
            if (buildings[i].sid === computer_parent_building_sid && !sectors[4].some((e) => e.sid === computer_sid)) {
              sectors[4].push({ sid: computer_sid, name: computer_name, parent_building_sid: computer_parent_building_sid,
              building_path: computer_building_path, full_path: computer_full_path});
            }
          }
          for (let i = 0; i<computers.length; i++){
            if (computers[i].sid === line_parent_computer_sid && !sectors[5].some((e) => e.sid === line_sid)) {
              sectors[5].push({ sid: line_sid, name: line_name, parent_computer_sid: line_parent_computer_sid,
              building_path: line_building_computer_path, computer_path: line_computer_path, full_path: line_full_path,
              line_id: line_id});
            }
          }
          for (let i = 0; i<sectors[5].length; i++){
            if (sectors[5][i].sid === order_origin && !sectors[6].some((e) => e.batchnumber === order_batchnumber)) {
              sectors[6].push({sid:order_sid, batchnumber: order_batchnumber, StartDate: order_start_date, 
              EndDate: order_end_date, isFinished: order_status, origin: order_origin});
            }
          }
        }


        //AVALIBLE OPTIONS IF A CLIENT AND COUNTRY AND SITE AND BUILDING AND COMPUTER AND LINE IS SELECTED
        if (clients.length && countries.length && sites.length && buildings.length && computers.length && lines.length && !orders.length){
          if (!sectors[0].some((e) => e.sid === client_sid)) {
            sectors[0].push({ sid: client_sid, name: client_name, child_country_sid: client_child_country_sid });
          }
          for (let i = 0; i<clients.length; i++){
            if (country_parent_client_sid.includes(clients[i].sid) && !sectors[1].some((e) => e.sid === country_sid)) {
              sectors[1].push({ sid: country_sid, name: country_name, parent_client_sid: country_parent_client_sid });
            }
          }

          for (let i = 0; i<clients.length; i++){
            for (let j = 0; j<countries.length; j++){
              if (clients[i].sid === site_parent_client_sid && countries[j].sid === site_parent_country_sid && !sectors[2].some((e) => e.sid === site_sid)) {
                sectors[2].push({ sid: site_sid, name: site_name, parent_client_sid: site_parent_client_sid, 
                  parent_country_sid: site_parent_country_sid });
              }
            }
          }
          for (let i = 0; i<sites.length; i++){
            if (sites[i].sid === building_parent_site_sid && !sectors[3].some((e) => e.sid === building_sid)) {
              sectors[3].push({ sid: building_sid, name: building_name, parent_site_sid: building_parent_site_sid,
              full_path: building_site_path});
            }
          }
          for (let i = 0; i<buildings.length; i++){
            if (buildings[i].sid === computer_parent_building_sid && !sectors[4].some((e) => e.sid === computer_sid)) {
              sectors[4].push({ sid: computer_sid, name: computer_name, parent_building_sid: computer_parent_building_sid,
              building_path: computer_building_path, full_path: computer_full_path});
            }
          }
          for (let i = 0; i<computers.length; i++){
            if (computers[i].sid === line_parent_computer_sid && !sectors[5].some((e) => e.sid === line_sid)) {
              sectors[5].push({ sid: line_sid, name: line_name, parent_computer_sid: line_parent_computer_sid,
              building_path: line_building_computer_path, computer_path: line_computer_path, full_path: line_full_path,
              line_id: line_id});
            }
          }
          for (let i = 0; i<lines.length; i++){
            if (lines[i].sid === order_origin && !sectors[6].some((e) => e.batchnumber === order_batchnumber)) {
              sectors[6].push({sid:order_sid, batchnumber: order_batchnumber, StartDate: order_start_date, 
              EndDate: order_end_date, isFinished: order_status, origin: order_origin});
            }
          }
        }

        //AVALIBLE OPTIONS IF A CLIENT AND COUNTRY AND SITE AND BUILDING AND COMPUTER AND LINE AND ORDER IS SELECTED
        if (clients.length && countries.length && sites.length && buildings.length && computers.length && lines.length && orders.length){
          if (!sectors[0].some((e) => e.sid === client_sid)) {
            sectors[0].push({ sid: client_sid, name: client_name, child_country_sid: client_child_country_sid });
          }
          for (let i = 0; i<clients.length; i++){
            if (country_parent_client_sid.includes(clients[i].sid) && !sectors[1].some((e) => e.sid === country_sid)) {
              sectors[1].push({ sid: country_sid, name: country_name, parent_client_sid: country_parent_client_sid });
            }
          }

          for (let i = 0; i<clients.length; i++){
            for (let j = 0; j<countries.length; j++){
              if (clients[i].sid === site_parent_client_sid && countries[j].sid === site_parent_country_sid && !sectors[2].some((e) => e.sid === site_sid)) {
                sectors[2].push({ sid: site_sid, name: site_name, parent_client_sid: site_parent_client_sid, 
                  parent_country_sid: site_parent_country_sid });
              }
            }
          }
          for (let i = 0; i<sites.length; i++){
            if (sites[i].sid === building_parent_site_sid && !sectors[3].some((e) => e.sid === building_sid)) {
              sectors[3].push({ sid: building_sid, name: building_name, parent_site_sid: building_parent_site_sid,
              full_path: building_site_path});
            }
          }
          for (let i = 0; i<buildings.length; i++){
            if (buildings[i].sid === computer_parent_building_sid && !sectors[4].some((e) => e.sid === computer_sid)) {
              sectors[4].push({ sid: computer_sid, name: computer_name, parent_building_sid: computer_parent_building_sid,
              building_path: computer_building_path, full_path: computer_full_path});
            }
          }
          for (let i = 0; i<computers.length; i++){
            if (computers[i].sid === line_parent_computer_sid && !sectors[5].some((e) => e.sid === line_sid)) {
              sectors[5].push({ sid: line_sid, name: line_name, parent_computer_sid: line_parent_computer_sid,
              building_path: line_building_computer_path, computer_path: line_computer_path, full_path: line_full_path,
              line_id: line_id});
            }
          }
          for (let i = 0; i<lines.length; i++){
            if (lines[i].sid === order_origin && !sectors[6].some((e) => e.batchnumber === order_batchnumber)) {
              sectors[6].push({sid:order_sid, batchnumber: order_batchnumber, StartDate: order_start_date, 
              EndDate: order_end_date, isFinished: order_status, origin: order_origin});
            }
          }
        }
    }
    return sectors
}


export { getReportOptions, getReportStates }