import {connect} from 'react-redux'
import Layout from '../pages/home.js';
import { getSessionData, completeLogIn } from '../reducers/userManagement'
import { getData, getOrders, getProducts, getOverviewOrders, getLines } from '../reducers/data'
import { setLatestReportFilters, setFilterSelections } from '../reducers/reports.js';



const mapStateToProps = state => ({             //selecting the part of the data from the store that the connected component needs
    user: state.userManagement.user,
    oktaUser: state.userManagement.oktaUser,
    completeLogIn: state.userManagement.completeLogIn,
    getDataRS: state.data.getDataRS,
    getPandasRS: state.data.getPandasRS,
    getOrdersRS: state.data.getOrdersRS,
    orders: state.data.orders,
    products: state.data.products,
    clusterLines: state.data.clusterLines,
    getLinesRS: state.data.getLinesRS,
    overviewOrders: state.data.overviewOrders,
    latestQuery: state.data.latestQuery,
    getSessionDataRS: state.userManagement.getSessionDataRS,
    getProductsRS: state.data.getProductsRS,
    getOverviewOrdersRS: state.data.getOverviewOrdersRS,
    latestReportFilters: state.reports.latestReportFilters

})


const mapDispatchToProps = dispatch => ({
    getLines: () => dispatch(getLines()),
    getSessionData: () => dispatch(getSessionData()),
    getData: (payload) => dispatch(getData(payload)),
    getOrders: () => dispatch(getOrders()),
    getProducts:() => dispatch(getProducts()),
    getOverviewOrders:() => dispatch(getOverviewOrders()),
    setLatestReportFilters:(payload) => dispatch(setLatestReportFilters(payload)),
    setFilterSelections:(payload) => dispatch(setFilterSelections(payload)),
    completeLogIn: () => dispatch(completeLogIn()),


})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
