const INITIAL = 'INITIAL'
const LOADING = 'LOADING'
const SUCCEEDED = 'SUCCEEDED'
const FAILED = 'FAILED'

export {
    INITIAL,
    LOADING,
    SUCCEEDED,
    FAILED
}