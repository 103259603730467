import { createSlice } from '@reduxjs/toolkit';

export const thisReducer = createSlice({
  name: 'setups',
  initialState: {
    activeLine: null,
  },
  reducers: {  
    setLine: (state, action) => {
      state.activeLine = action.payload
    },
  },
})

export const { setLine } = thisReducer.actions

export default thisReducer.reducer