import 'svg2pdf.js'
import * as d3 from "d3";
import { jsPDF } from 'jspdf';
import * as React from 'react';
import { connect } from "react-redux";
import { useEffect, useRef } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import {Button, FormControl, FormControlLabel, Grid, RadioGroup, Radio, Switch} from '@mui/material';
import { divergingBarChart } from "../graphs/divergingBarChart";
import autoTable from 'jspdf-autotable';
import { blue } from '@mui/material/colors';
import classes from "../styles/components/stationOperatorPerformance";
import theme from "../theme";
import { isMobile } from "react-device-detect";
import { BrowserView, MobileView } from "react-device-detect";




function DrawDivergingBarChart(props) {

  const [data, setDataChecked] = React.useState(false)
  const handleDataChange = (event) => {
    setDataChecked(event.target.checked)
  }

  const [scale_path, setScalePathChecked] = React.useState(false)
  const handleScalePathChange = (event) => {
    setScalePathChecked(event.target.checked)
  }

  const [order, setOrderChecked] = React.useState("descending")
  const handleDataOrderChange = (event) => {
    setOrderChecked(event.target.value)
  }

  const [unknown, setUnknownChecked] = React.useState(false)
  const handleHideUnknown = (event) => {
    setUnknownChecked(event.target.checked)
  }

  const [group, setGroupedChecked] = React.useState(false)
  const handleGroupByLine = (event) => {
    setGroupedChecked(event.target.checked)
  }

  const chartRef = useRef();
  let pandas = props.pandas
  let pdfFilters = props.pdfFilters
  let dateFrom = new Date(pdfFilters.filterData.dateFrom).toLocaleString('en-GB').split(',')[0]
  let dateTo = new Date(pdfFilters.filterData.dateTo).toLocaleString('en-GB').split(',')[0]
  let orders = pdfFilters.filterData.orders
  let lines = pdfFilters.filterData.lines
  let computers = pdfFilters.filterData.computers
  let computerString = ""
  let orderString = ""
  let lineString = ""

  let unknownCount = 0
  // getting data for PDF
  for (let i = 0; i<pandas.length; i++){
    if (pandas[i].worker){
      unknownCount += 1
    }
  }
  if (orders){
    for (let i=0; i<orders.length; i++){
      i === orders.length -1 ? orderString += orders[i].batchnumber : orderString += orders[i].batchnumber + ", "
    }
  }
  if (lines){
    for (let i=0; i<lines.length; i++){
      i === lines.length -1 ? lineString += lines[i].line_id : lineString += lines[i].line_id + ", "
    }
  }
  if (computers){
    for (let i=0; i<computers.length; i++){
      i === computers.length -1 ? computerString += computers[i].name : computerString += computers[i].name + ", "
    }
  }


  let sortedPandas = Array.from(
    d3.group(pandas, (o) => o.scale).values()
  ).map((a) => a[0].scale ? ({ 
    scale: a[0].scale,
    path: a[0].path,
    line_id: a[0].line_id,
    total: d3.sum(a, (o) => o.total),
  }): "");


  const chartGenerate = divergingBarChart(pandas,

    {
      x: d => data ? d.worker_total : d.total,
      y: d => data ? d.worker : !scale_path ? d.scale : d.path,
      maxThreshold: d => d.maxThreshold,    
      minThreshold: d => d.minThreshold,
      z: d => data ? d.worker_value : d.value,
      
      yDomain: group ? 
                  sortedPandas.slice()
                  .sort((a,b) => order === "ascending" ? d3.ascending(a.total, b.total) : d3.descending(a.total, b.total))
                  .sort((a,b) => d3.ascending(a.line_id, b.line_id))
                  .map((s) => !scale_path ? s.scale : s.path)
                :      
                d3.groupSort(pandas, 
                  ([d]) => data ? (order === "ascending" ? d.worker_total : -d.worker_total) : (order === "ascending" ? d.total : -d.total), 
                  d => data ? d.worker : !scale_path ? d.scale : d.path),
      marginRight: 70,
      marginLeft: 260,
      hideUnknown: unknown
    }, 
  ); 
  
  const chart = Object.assign(chartGenerate.svg)
        
  const attachSvg = function () {
    chartRef.current.append(chart)
    return () => chart.remove()
  }
    
  useEffect(attachSvg);


  const pdfDownload = function download(more_stuff){
    const doc = new jsPDF('l', 'mm', 'a4')
    const rows = chartGenerate.rows.chartRows
    const x = 14
    const y = 70
    const width = 269
    var height
    rows <= 3 ? height = 20 : rows <= 8 ? height = 50 : rows <= 11 ? height = 60 : height = 100
  
    doc.setTextColor(6,95,146);
    doc.setFontSize(25);
    doc.text(14, 20, 'Station and Operator Giveaway Report');
    
    doc.setFillColor(204, 204,204,0);
    doc.rect(x, y, width, height, "F");
    
    autoTable(doc, {
      startY: 30,
      theme: 'grid',
      styles: {lineColor: [0,0,0]},
      bodyStyles: {cellWidth: 'auto'},
      columnStyles: { 0: {  fillColor: [6,95,146], textColor: [255,255,255] }, 1:{  fillColor: [238,238,238], textColor: [0,0,0] }},
      body: [
        ['Date Range:', dateFrom + ' - ' + dateTo],
        ['Computers:', computerString.length ? computerString : 'All'],
        ['Lines:', lineString.length ? lineString : 'All'],
        ['Orders:', orderString.length ? orderString : "None"],
      ],
    })
    doc.svg(chartGenerate.svg, { x, y, width, height}).then(() => {
      doc.save('Station-and-Operator-Giveaway.pdf')
    })
  }

  return ( 
    <div>
      <div>
      <BrowserView>
        <div>
          <Grid container direction="row" alignItems="center">
            <Button variant="outlined" onClick={pdfDownload} style={{margin: "1em", marginTop: "-3.9em", marginLeft: "24.2em", borderColor:'#059df5', color:'#059df5'}}>
              <DownloadIcon style={{ height: "0.8em", marginLeft: "-0.3em", color: '#059df5'}} /> PDF
            </Button>
          </Grid>
        </div>
        <div>
        <FormControl
          component="fieldset"
          style={classes(theme, isMobile).bulletBox}
        >
          <Grid container direction="row">
          <FormControlLabel
          disabled={group}
          style = {{marginLeft:"0em"}}
            label={<span style={{ color:"white" }}> Worker Data </span>}
            control = {<Switch
              sx={{color: blue[800],'&.Mui-checked': {color: blue[600]}}}
              checked = {data}
              onChange = {handleDataChange}
              inputProps = {{ 'aria-label': 'controlled' }} 
            />}
          />
          <FormControlLabel
            style = {{marginLeft:"2.2em"}}
            disabled={unknownCount === 3}
            control = {<Switch
              sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}
              checked = {unknown}
              onChange = {handleHideUnknown}
              inputProps = {{ 'aria-label': 'controlled' }} />}
              label={<span style={{ color:"white" }}>Unknown Data</span>}
          />
          </Grid>
          <Grid container direction="row">
            <FormControlLabel
              disabled={data} 
              style = {{marginLeft:"0em"}}
              label={ <span style={{ color:"white" }}> Group By Line </span>}
              control = {<Switch
                sx={{color: blue[800],'&.Mui-checked': {color: blue[600]}}}
                checked = {group}
                onChange = {handleGroupByLine}
                inputProps = {{ 'aria-label': 'controlled' }} 
              />}
            />

            <FormControlLabel
              disabled={data}
              style = {{marginLeft:"1.4em"}}
              label={ <span style={{ color:"white" }}> Scale Path </span>}
              control = {<Switch
                sx={{color: blue[800],'&.Mui-checked': {color: blue[600]}}}
                checked = {scale_path}
                onChange = {handleScalePathChange}
                inputProps = {{ 'aria-label': 'controlled' }} 
              />}
            />
          </Grid>


          <FormControl>
            <RadioGroup
              row
              value = {order}
              onChange = {handleDataOrderChange}
            >
              <FormControlLabel 
              style = {{marginLeft:"-0.1em"}}
                value="descending" 
                label={<span style={{ color:"white" }}> Sort Descending </span>}
                control={<Radio 
                  sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}/>} 
              />
              <FormControlLabel 
                value="ascending" 
                control={<Radio 
                  sx={{color: blue[800],'&.Mui-checked': {  color: blue[600]}}}/>} 
                label={<span style={{ color:"white" }}> Sort Ascending </span>} 
                style={{marginLeft:"1.4em"}}
              />
            </RadioGroup>
          </FormControl>        
        </FormControl>
          
        </div>
        </BrowserView>

        <MobileView>
        <div>
        <Grid container direction="row" alignItems="center">
            <Button variant="outlined" onClick={pdfDownload} style={{margin: "1em", marginTop: "-7.7em", marginLeft: "8.2em", color: '#059df5', borderColor:'#059df5'}}>
              <DownloadIcon style={{ height: "0.8em", marginLeft: "-0.3em", color: '#059df5'}} /> PDF
            </Button>
          </Grid>
        </div>
        <div>
        <FormControl
          component="fieldset"
          style={classes(theme, isMobile).bulletBox}
        >
          <Grid container direction="row">
          <FormControlLabel
          disabled={group}
            label={<span style={{ color:"white" }}> Worker Data </span>}
            control = {<Switch
              sx={{color: blue[800],'&.Mui-checked': {color: blue[600]}}}
              checked = {data}
              onChange = {handleDataChange}
              inputProps = {{ 'aria-label': 'controlled' }} 
            />}
          />
          <FormControlLabel
            disabled={unknownCount === 3}
            control = {<Switch
              sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}
              checked = {unknown}
              onChange = {handleHideUnknown}
              inputProps = {{ 'aria-label': 'controlled' }} />}
              label={<span style={{ color:"white" }}>Unknown Data</span>}
          />
            <FormControlLabel
              disabled={data} 
              label={ <span style={{ color:"white" }}> Group By Line </span>}
              control = {<Switch
                sx={{color: blue[800],'&.Mui-checked': {color: blue[600]}}}
                checked = {group}
                onChange = {handleGroupByLine}
                inputProps = {{ 'aria-label': 'controlled' }} 
              />}
            />

            <FormControlLabel
              disabled={data}
              label={ <span style={{ color:"white" }}> Scale Path </span>}
              control = {<Switch
                sx={{color: blue[800],'&.Mui-checked': {color: blue[600]}}}
                checked = {scale_path}
                onChange = {handleScalePathChange}
                inputProps = {{ 'aria-label': 'controlled' }} 
              />}
            />
          </Grid>


          <FormControl>
            <RadioGroup
              row
              value = {order}
              onChange = {handleDataOrderChange}
            >
              <FormControlLabel 
                value="descending" 
                label={<span style={{ color:"white" }}> Sort Descending </span>}
                control={<Radio 
                  sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}/>} 
              />
              <FormControlLabel 
                value="ascending" 
                control={<Radio 
                  sx={{color: blue[800],'&.Mui-checked': {  color: blue[600]}}}/>} 
                label={<span style={{ color:"white" }}> Sort Ascending </span>} 
              />
            </RadioGroup>
          </FormControl>        
        </FormControl>
          
        </div>
        </MobileView>
      </div>
      <div ref={chartRef} style={{marginTop:"2em"}}/>
    </div>
  )
}

const mapStateToProps = state => ({
    pandas: state.reports.pandas,
    getPandasRS: state.reports.getPandasRS,
    hideFilters: state.reports.hideFilters,
    pdfFilters: state.reports.pdfFilters
})

export default connect(mapStateToProps)(DrawDivergingBarChart)