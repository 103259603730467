const styles = (theme, isMobile) => (isMobile ? mobile(theme) : browser(theme))


const browser = (theme) => ({
  background: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    width: "100%",
    height: "10rem",
    textColor: "white",
    borderColor: "white",
    backgroundColor: "white",
  },
  panoramaLogo: {
    minWidth: "10%",
    marginTop: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    height: theme.spacing(8),
  },
  loginContainer: {
    width: "60%",
    height: '90%',
    margin: "auto",
    borderWidth: "1px", 
    borderRadius: "1em",     
    borderStyle: "solid",
    borderColor: "#323232",
    padding: theme.spacing(1),
    backgroundColor: "#323232",
    marginTop: theme.spacing(2),
  },
  loginText: {
    margin: "auto",
    color: "white",
    textAlign: "center",
    justifyContent:"center",
    marginTop: theme.spacing(1.5),
  },
  emailField: {
    width: "19em",
    margin: "auto",
    marginTop: theme.spacing(3),
  },
  passwordField: {
    width: "19em",
    margin: "auto",
    marginTop: theme.spacing(1),
  },
  passwordCheckbox: {
    marginLeft: "0.2rem",
    marginTop: theme.spacing(2),
  },
  loginButton: {
    width: "10em",
    margin: "auto",
    marginTop: theme.spacing(2),
  },
  oktaLogo: {
    margin: "auto",
    height: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  warning: {
    color: "red",
    textAlign: "center",
    fontSize: theme.spacing(1),
    marginTop: theme.spacing(1.5),
  },
  oktaWarning: {
    textAlign: "center",
    fontSize: theme.spacing(1),
    color: theme.palette.primary.main,
  },
})


const mobile = theme => ({
  ...browser(theme),
  panoramaLogo: {
    minWidth: "10%",
    marginTop: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    height: theme.spacing(5),
  },
  header: {
    width: "100%",
    height: "8rem",
    textColor: "white",
    borderColor: "white",
    backgroundColor: "white",
  },
  loginContainer: {
    width: "60%",
    height: '90%',
    margin: "auto",
    borderWidth: "1px", 
    borderRadius: "1em",     
    borderStyle: "solid",
    borderColor: "#323232",
    padding: theme.spacing(1),
    backgroundColor: "#323232",
    marginTop: theme.spacing(3),
  },
  emailField: {
    width: "19em",
    margin: "auto",
    marginTop: theme.spacing(1),
  },
})

export default styles;
