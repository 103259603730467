import { createSlice } from '@reduxjs/toolkit';
import { debug } from '../general/utils';
import {INITIAL, LOADING, SUCCEEDED, FAILED} from '../general/requestStates'

const today = new Date().getTime()
export const reports = createSlice({
  name: 'reports',
  initialState: {
    hideFilters: false,
    getReportRS: null,
    reportFile: null,
    pandas: null,
    getPandasRS: null,
    getOrdersRS: null,
    orders: [],
    pdfFilters: null,
    reportDataFile: null,
    reportData: null,
    refreshData: null,
    refreshDataRS: INITIAL,
    getReportDataRS: INITIAL,
    getReportDataFileRS: INITIAL,
    filterSelections: {
      dateFrom: new Date().getTime(),
      dateTo: new Date().getTime(),
      clients: [],
      countries: [],
      sites: [],
      buildings: [],
      computers: [],
      orders: [],
      products: [],
      lines: [],
    },
    latestReportFilters: {
      dateFrom: new Date().getTime(),
      dateTo: new Date().getTime(),
      clients: [],
      countries: [],
      sites: [],
      buildings: [],
      computers: [],
      orders: [],
      products: [],
      lines: [],
    },
    latestRequestId: 1,
  },
  reducers: {
    setPdfFilters: (state, action) => {
      state.pdfFilters = action.payload
    },
    hideAllFilters: (state) => {
      state.hideFilters = !state.hideFilters
    },
    getReportData: (state) => {
      debug("Fetching Report Data...")
      debug("Getting Report Data...")
      state.getReportDataRS = LOADING
    },
    getReportDataSuccess: (state, action) => {
      debug("Report Data recieved")
      debug("Successfully retrieved Report Data")
      state.getReportDataRS = SUCCEEDED
      state.reportData = action.payload
    },
    getReportDataFail:  (state) => {
      debug("Failed to get report data")
      debug("Failed to retrieve Report Data")
      state.getReportDataRS = FAILED
    },
    getOrders: (state, action) => {
      state.getOrdersRS = LOADING
    },
    getClustersSuccess: (state, action) => {
      debug("Successfully retrieved Orders")
      state.getOrdersRS = LOADING
      state.orders = action.payload
    },
    getClustersFail: (state, action) => {
      debug("Failed to retrieve Orders")
      state.getOrdersRS = FAILED
    },
    getReport: (state) => {
      debug("reducer for getReport called!")
      debug("Loading Reports...")
      state.getReportRS = LOADING
    },
    getReportSuccess: (state, action) => {
      debug("reducer for getReportSuccess called!")
      debug("Successfully retrieved Reports")
      state.getReportRS = SUCCEEDED
      state.reportFile = action.payload
    },
    getReportFail:  (state) => {
      debug("Failed to retrieve Reports")
      state.getReportRS = FAILED
    },
    getPandas: (state) => {
      debug("reducer for getPandas called!")
      debug("Loading Pandas...")
      state.getPandasRS = LOADING
      // state.pandas = null
      state.latestRequestId = state.latestRequestId + 1
    },
    getPandasSuccess: (state, action) => {
      debug("reducer for getPandasSuccess called!")
      debug("Successfully retrieved Pandas", action.payload.request_id)
      debug("state: ", state.latestRequestId)
      if (action.payload.request_id === state.latestRequestId - 1) {
        state.getPandasRS = SUCCEEDED
        state.pandas = action.payload.report_data
      } else {
        debug("received some pandas data, but it's not the droids we are looking for.")
      }
      
    },
    getPandasFail:  (state) => {
      debug("reducer for getPandasFailed called!")
      debug("Failed to retrieve Pandas")
      state.getPandasRS = FAILED
    },
    refreshData: (state) => {
      state.refreshDataRS = LOADING
      debug("reducer for refreshData called!")
    },
    refreshDataSuccess: (state, action) => {
      debug("Data has been refreshed")
      state.refreshDataRS = SUCCEEDED
      state.refreshData = action.payload
    },
    refreshDataFail: (state) => {
      debug("Refresh Data has failed")
      state.refreshDataRS = FAILED
    },
    getReportDataFile: (state) => {
      debug("reducer for getReportDataFile called!")
      state.getReportDataFileRS = LOADING
    },
    getReportDataFileSuccess: (state) => {
      debug("reducer for getReportDataFileSuccess called!")
      state.getReportDataFileRS = SUCCEEDED
    },
    getReportDataFileFail: (state) => {
      debug.log("reducer for getReportDataFileFail called!")
      state.getReportDataFileRS = FAILED
    },
    setFilterSelections: (state, action) => {
      let selections = action.payload
      if (selections.reportName !== 'Overview Dashboard'){
        selections.dateFrom = Number.isInteger(selections.dateFrom) ? selections.dateFrom : selections.dateFrom.getTime()
        selections.dateTo = Number.isInteger(selections.dateTo) ? selections.dateTo : selections.dateTo.getTime()
      }
      state.filterSelections = action.payload
    },
    setLatestReportFilters: (state, action) => {
      let selections = action.payload
      if (selections.reportName !== 'Overview Dashboard'){
        selections.dateFrom = Number.isInteger(selections.dateFrom) ? selections.dateFrom : selections.dateFrom.getTime()
        selections.dateTo = Number.isInteger(selections.dateTo) ? selections.dateTo : selections.dateTo.getTime()
      }
      state.latestReportFilters = action.payload
    }
  },
})

export const { setPdfFilters, hideAllFilters, getReport, getPandas, getOrders, getReportDataFile, getReportData, setFilterSelections, setLatestReportFilters, refreshData } = reports.actions

export default reports.reducer
