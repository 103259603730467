import commonstyles from "../common";
import { isMobile } from "react-device-detect";

const styles = (theme, isMobile) => (isMobile ? mobile(theme) : browser(theme))

const browser = (theme) => ({
    tableHeadings: {
        color: "white", 
        borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid",
    },
    tableCell: {
        color: "black", 
        borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid",
    }
})

const mobile = (theme) => ({
    tableHeadings: {
        color: "white", 
        borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid",
    },
    tableCell: {
        color: "black", 
        borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid",
    }
})

export default styles;