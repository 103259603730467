import 'svg2pdf.js'
import { jsPDF } from 'jspdf';
import { connect } from "react-redux";
import { useEffect, useRef} from "react";
import {Grid, Button} from '@mui/material';
import { pieChart } from "../graphs/pieChart";
import DownloadIcon from "@mui/icons-material/Download";
import autoTable from 'jspdf-autotable';
import { BrowserView, MobileView } from "react-device-detect";


function DrawPieChart(props) {
  const chartRef = useRef();
  let pandas = props.pandas
  let hideFilters = props.hideFilters
  let pdfFilters = props.pdfFilters
  let dateFrom = new Date(pdfFilters.filterData.dateFrom).toLocaleString('en-GB').split(',')[0]
  let dateTo = new Date(pdfFilters.filterData.dateTo).toLocaleString('en-GB').split(',')[0]
  let orders = pdfFilters.filterData.orders
  let lines = pdfFilters.filterData.lines
  let computers = pdfFilters.filterData.computers
  let computerString = ""
  let orderString = ""
  let lineString = ""

  // getting data for PDF
  if (orders){
    for (let i=0; i<orders.length; i++){
      i === orders.length -1 ? orderString += orders[i].batchnumber : orderString += orders[i].batchnumber + ", "
    }
  }
  if (lines){
    for (let i=0; i<lines.length; i++){
      i === lines.length -1 ? lineString += lines[i].line_id : lineString += lines[i].line_id + ", "
    }
  }
  if (computers){
    for (let i=0; i<computers.length; i++){
      i === computers.length -1 ? computerString += computers[i].name : computerString += computers[i].name + ", "
    }
  }
  
  //generating chart
  const chartGenerate = pieChart(pandas, 
    {
      graphTitle:                 d => d.pie_label,
      value:                      d => d.pie_value,
      totalItems:                 d => d.items_label,
      totalItemsValue:            d => d.total_items,
      avgItemsPerMinute:          d => d.avg_time_label,
      avgItemsPerMinuteValue:     d => d.ipm,
      batchGiveaway:              d => d.giveaway_label,
      batchGiveawayValue:         d => d.giveaway,
      orderComplete:              d => d.orderComplete,
      orderCompleteValue:         d => d.orderCompleteValue,
      
      height: 300
    }
  ); 

  const chart = Object.assign(chartGenerate.svg, chartGenerate.otherStuff)
        
  const attachSvg = function () {
    chartRef.current.append(chart)
    return () => chart.remove()
  }
    
  useEffect(attachSvg);

  //creating PDF
  const pdfDownload = function download(more_stuff){
    const doc = new jsPDF()
    const x = 14
    const y = 70
    const width = 182
    const height = 110
    
    doc.setTextColor(6,95,146);
    doc.setFontSize(25);
    doc.text(14, 20, 'Order and Line Status Report');
    
    doc.setFillColor(204, 204,204,0);
    doc.rect(x, y, width, height, "F");
    
    autoTable(doc, {
      startY: 30,
      theme: 'grid',
      styles: {lineColor: [0,0,0]},
      bodyStyles: {cellWidth: 'auto'},
      columnStyles: { 0: {  fillColor: [6,95,146], textColor: [255,255,255] }, 1:{  fillColor: [238,238,238], textColor: [0,0,0] }},
      body: [
        ['Date Range:', dateFrom + ' - ' + dateTo],
        ['Computers:', computerString.length ? computerString : 'All'],
        ['Lines:', lineString.length ? lineString : 'All'],
        ['Orders:', orderString.length ? orderString : "None"],
      ],
    })

    doc.svg(chartGenerate.svg, { x, y, width, height}).then(() => {
      doc.save('Order-and-Line-Status.pdf')
    })
  }

  return (
    <div>
      <div>
        <BrowserView>
          <Grid container direction="row" alignItems="center">
            <Button variant="outlined" onClick={pdfDownload} style={{margin: "1em", marginTop: "-3.9em", marginLeft: "24.2em", color: '#059df5', borderColor:'#059df5'}}>
              <DownloadIcon style={{ height: "0.8em", marginLeft: "-0.3em", color: '#059df5'}} /> PDF
            </Button>
          </Grid>
        </BrowserView>
        
        <MobileView>
          <Grid container direction="row" alignItems="center">
            <Button variant="outlined" onClick={pdfDownload} style={{margin: "1em", marginTop: "-7.7em", marginLeft: "8.2em", color: '#059df5', borderColor:'#059df5'}}>
              <DownloadIcon style={{ height: "0.8em", marginLeft: "-0.3em", color: '#059df5'}} /> PDF
            </Button>
          </Grid>
        </MobileView>
      </div>
      <div ref={chartRef} />
    </div>
  );
}

const mapStateToProps = state => ({
  pandas: state.reports.pandas,
  hideFilters: state.reports.hideFilters,
  pdfFilters: state.reports.pdfFilters
})

export default connect(mapStateToProps)(DrawPieChart)