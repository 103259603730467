import {connect} from 'react-redux';
import Layout from '../productivityReport';
import { getReport, getPandas, getReportDataFile, getReportData } from "../../reducers/reports";



const mapStateToProps = state => ({              
  pandas : state.reports.pandas
})

const mapDispatchToProps = dispatch => ({
  getReportDataFile: (payload) => dispatch(getReportDataFile(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)