import { debug } from './general/utils';

var saveAs = require('file-saver');


function apiRequest(requestType, next, apiEndpoint, successPath, failPath, requestBody=null, headers=null) {
    
    let fetchDict = {
        method: requestType,
        headers: headers ? headers : {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }
    if (requestType !== "GET") {fetchDict["body"] = JSON.stringify(requestBody)}

    fetch(window.location.origin + apiEndpoint, fetchDict)
    .then( response => {
        if (response.status === 403) {
            next({
                type: "userManagement/autoLogOut",
                payload: "API error"
            })
        }
        
        else if (response.headers.get('Content-Disposition')){
            var filename =  response.headers.get('Content-Disposition').split('filename=')[1] || response.headers.get('filename');
            
            response.blob().then(blob => {
                if (["'", '"', "_"].includes(filename.slice(-1))) {
                    filename = filename.substring(1, filename.length - 1)
                }
                
                saveAs(blob, filename)
                next({
                    type: successPath,
                    file: blob
                })
            })
        } else {response.json()
            .then( response => {
                next({
                    type: successPath,
                    payload: response
                })
            }).catch(error => {
                debug('API: ' + failPath, error)
                next({
                    type: failPath,
                    payload: "API error"
                })
            })
        }
    })
}

function postRequest(next, apiEndpoint, successPath, failPath, requestBody) {
    apiRequest("POST", next, apiEndpoint, successPath, failPath, requestBody)
}

function patchRequest(next, apiEndpoint, successPath, failPath, requestBody) {
    apiRequest("PATCH", next, apiEndpoint, successPath, failPath, requestBody)
}

function getRequest(next, apiEndpoint, successPath, failPath, headers=null) {
    apiRequest("GET", next, apiEndpoint, successPath, failPath, "", headers)
}


const store = store => next => action => {
    console.log('APIClient middleware: ', action)
    next(action)

    switch (action.type){
        default:
            break


        case "userManagement/login":
            debug("doing login API call")
            fetch(window.location.origin + '/api/login', {
                method: "POST",
                body: JSON.stringify(action.payload),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then( response => response.json() )
            .then( response => {
                debug('API: log in attempt succeeded.', response )
                let type = response.validated ? 'userManagement/loginSuccess' : 'userManagement/invalidCredentials'
                next({
                    type,
                    payload: response.user
                })
            })
            .catch( error => {
                debug('API: log in error!', error)
                next({
                    type: 'userManagement/loginFailed',
                    payload: "API error"
                })
            })
            break


            case "userManagement/refreshUserScope":
                fetch(window.location.origin + '/api/refresh-user-scope', {
                    method: "POST",
                    body: JSON.stringify(action.payload),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                })
                .then( response => response.json() )
                .then( response => {
                    debug('API: log in attempt succeeded.', response )
                    let type = 'userManagement/refreshScopeSucceeded'
                    next({
                        type,
                        payload: response.user
                    })
                })
                .catch( error => {
                    debug('API: log in error!', error)
                    next({
                        type: 'userManagement/refreshScopeFailed',
                        payload: "API error"
                    })
                })
                break


            // case 'userManagement/refreshUserScope':
            // postRequest(
            //     next,
            //     '/api/refresh-user-scope',
            //     'userManagement/refreshScopeSucceeded',
            //     'userManagement/refreshScopeFailed',
            //     action.payload             
            // )
            // console.log(action.payload)
            // break

        // USERS PAGE
        case 'userManagement/getAllUsernames':
            getRequest(
                next,
                '/api/users/all',
                'userManagement/getAllUsernamesSucceeded',
                'userManagement/getAllUsernamesFail'
            )
            break

        case 'clusterManagement/getClusterNames':
            getRequest(
                next,
                '/api/users/clusters',
                'clusterManagement/getClusterNamesSuccess',
                'clusterManagement/getClusterNamesFail'
            )
            break


        case 'userManagement/getUserData':
            getRequest(
                next,
                '/api/users/user/',
                'userManagement/getUserDataSucceeded',
                'userManagement/getUserDataFail'
            )
            break

        case 'userManagement/getReportOptions':
            getRequest(
                next,
                '/api/users/reportOptions',
                'userManagement/getReportOptionsSucceeded',
                'userManagement/getReportOptionsFail'
            )
            break


        case 'userManagement/submitUserData':
            postRequest(
                next,
                '/api/users/user',
                'userManagement/submitUserDataSucceeded',
                'userManagement/submitUserDataFailed',
                action.payload
            )
            break

        case 'userManagement/updateUserData':
            patchRequest(
                next,
                '/api/users/user',
                'userManagement/updateUserDataSucceeded',
                'userManagement/updateUserDataFailed',
                action.payload
            )
            break
        // END USERS PAGE
        
        // SETUPS PAGE
        case 'setupManagement/getSetups':
            getRequest(
                next,
                '/api/setups',
                'setupManagement/getSetupsSuccess',
                'setupManagement/getSetupsFail'
            )
            break


        case 'setupManagement/postSetup':
            postRequest(
                next,
                '/api/setups',
                'setupManagement/postSetupSuccess',
                'setupManagement/postSetupFail',
                action.payload
            )
            break
        // SETUPS PAGE END        

        case 'userManagement/logOut':
            getRequest(
                next,
                '/api/logout',
                'userManagement/logOutSuccess',
                'userManagement/logOutFail'
            )
            break
            

        case 'userManagement/getSessionData':
            getRequest(
                next,
                '/api/get-session-data',
                'userManagement/getSessionDataSucceeded',
                'userManagement/getSessionDataFailed'
            )
            break


        case 'clusterManagement/getFilterOptions':
            postRequest(
                next,
                '/api/get-filter-options',
                'clusterManagement/getFilterOptionsSuccess',
                'clusterManagement/getFilterOptionsFail',
                action.payload
            )
            break


        case 'clusterManagement/getClusters':
            getRequest(
                next,
                '/api/get-clusters',
                'clusterManagement/getClustersSuccess',
                'clusterManagement/getClustersFail'
            )
            break


        case 'data/submitData':
            postRequest(
                next,
                '/api/submit-data',
                'data/submitDataSuccess',
                'data/submitDataFail',
                action.payload
            )
            break


        case 'data/deleteData':
            postRequest(
                next,
                '/api/delete-data',
                'data/deleteDataSuccess',
                'data/deleteDataFail',
                action.payload
            )
            break


        case 'data/getData':
            postRequest(
                next,
                '/api/get-data',
                'data/getDataSuccess',
                'data/getDataFail',
                action.payload
            )
            break

        
        case 'data/getOrders':
            getRequest(
                next,
                '/api/get-orders',
                'data/getOrdersSuccess',
                'data/getOrdersFail'
            )
            break

        case 'data/getProducts':
            getRequest(
                next,
                '/api/get-products',
                'data/getProductsSuccess',
                'data/getProductsFail'
            )
            break
        
        case 'data/getOverviewOrders':
        getRequest(
            next,
            '/api/get-overview-orders',
            'data/getOverviewOrdersSuccess',
            'data/getOverviewOrdersFail'
        )
        break

        case 'reports/getPandas':
            postRequest(
                next,
                '/api/get-pandas',
                'reports/getPandasSuccess',
                'reports/getPandasFail',
                action.payload
            )
            break
        
        case 'data/getLines':
            getRequest(
                next,
                '/api/get-lines',
                'data/getLinesSuccess',
                'data/getLinesFail',
                action.payload
            )
            break

        case 'reports/refreshData':
            debug("refresh data API called")
            postRequest(
                next,
                '/api/refresh-data',
                'reports/refreshDataSuccess',
                'reports/refreshDataFail',
                action.payload
            )
            break

        case 'reports/getReportData':
            getRequest(
                next,
                '/api/reports',
                'reports/getReportDataSuccess',
                'reports/getReportDataFail',
                action.payload
                )
            break

        case 'reports/getReportDataFile':
            let args = 
                "?report_name=" + action.payload.reportName + 
                "&filter_data=" + JSON.stringify(action.payload.filterData) +
                "&file_type=" + action.payload.fileType
            args = args.replace("#", "%23")
            getRequest(
                next,
                '/api/get-report-data-file' + args,
                'reports/getReportDataFileSuccess',
                'reports/getReportDataFileFail',
                {
                    "Content-Disposition": "attachment",
                    'Content-Type': 'application/pdf'}
            )
            break


        // case 'reports/getReport':
        //     console.log("action payload for getReport", action.payload)
        //     fetch(window.location.origin + '/api/get-report', {
        //         method: "POST",
        //         body: JSON.stringify(action.payload),
        //         headers: {
        //             "Content-Disposition": "attachment",
        //             'Content-Type': 'image/png'
        //         },
        //     })
        //     .then( response => {
        //         if (response.headers.get('Content-Disposition')){
        //             const filename =  response.headers.get('Content-Disposition').split('filename=')[1] || response.headers.get('filename');
        //             console.log("Headers are ", response.headers.get('filename'))
        //             response.blob().then(blob => {
        //                 let new_filename = filename
        //                 console.log("filename", filename)
        //                 console.log("and then", filename.slice(-1))
        //                 if (["'", '"', "_"].includes(filename.slice(-1))) {
        //                     new_filename = filename.substring(1, filename.length - 1)
        //                 }
        //                 // saveAs(blob, new_filename)
        //                 next({
        //                     type: 'reports/getReportSuccess',
        //                     payload: blob
        //                 })
        //             })
        //         } else {
        //             response.json().then(response => {
        //                 console.log("response", response)
        //                 next({type: "reports/getReportSuccess"})
        //             })
        //         }
        //     })
        //     .catch( error => {
        //         console.log('API: get-report!', error)
        //         next({
        //             type: 'GET_REPORT_FAILED'
        //         })
        //     })
        //     break
    }
}

export default store