import commonstyles from "./common"
import { isMobile } from "react-device-detect";

const styles = (theme, isMobile) => (isMobile ? mobile(theme) : browser(theme))

const browser = (theme) => ({
    mainCard: {...commonstyles(theme).mainCard},
    textField: commonstyles(theme).textField,
    filterField: {...commonstyles(theme).textField, width: theme.spacing(15)},
    longFilterField: {...commonstyles(theme).textField, width: theme.spacing(30)},
    button: commonstyles(theme).button,
    warning: commonstyles(theme).warning,
    label: commonstyles(theme).label,
    tips: commonstyles(theme).tips
})

const mobile = (theme) => ({
    ...browser(theme),
    textField: {...commonstyles(theme, isMobile).textField, width: theme.spacing("80%"), margin: theme.spacing(1)}
})

// const styles = theme => ({ 
//     mainCard: {...commonstyles(theme).mainCard},
//     textField: commonstyles(theme).textField,
//     filterField: {...commonstyles(theme).textField, width: theme.spacing(15)},
//     longFilterField: {...commonstyles(theme).textField, width: theme.spacing(30)},
//     button: commonstyles(theme).button,
//     warning: commonstyles(theme).warning,
//     label: commonstyles(theme).label,
//     tips: commonstyles(theme).tips
// })

export default styles
