import * as d3 from "d3";
import { debug } from "../general/utils";
import { jsPDF } from 'jspdf';
// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/diverging-bar-chart
export function divergingBarChart(data, {
  hideUnknown,
  x = d => d,                                 // given d in data, returns the (quantitative) x-value
  y = (d, e) => e,
  z = (d, e, f) => f,                       // given d in data, returns the (ordinal) y-value
  maxThreshold= a => a,    
  minThreshold= b => b,
  title,                                      // given d in data, returns the title text
  marginTop = 30,                             // top margin, in pixels
  marginRight,                           // right margin, in pixels
  marginBottom = 10,                          // bottom margin, in pixels
  marginLeft,                            // left margin, in pixels
  width = 800,                                // outer width of chart, in pixels
  height,                                     // the outer height of the chart, in pixels
  xType = d3.scaleLinear,                     // type of x-scale
  xDomain,                                    // [xmin, xmax]
  xRange = [marginLeft, width - marginRight], // [left, right]
  xFormat = ".5~g",                                  // a format specifier string for the x-axis
  xLabel = "Giveaway",                                     // a label for the x-axis
  yPadding = 0.1,                             // amount of y-range to reserve to separate bars
  yDomain,                                    // an array of (ordinal) y-values
  padAngle = 0,     // angular separation between wedges
  yRange,                                     // [top, bottom]
  colors = ['#70AD47','#FF0000']              // [negative, …, positive] colors
  } = {}) {
    // Compute values.
    const X = d3.map(data, x);
    const Y = d3.map(data, y);
    const Z = d3.map(data, z);
    const MAXV = d3.map(data, maxThreshold);
    const MINV = d3.map(data, minThreshold);
    
    //Getting the Threshold values in a dirty way

    let chartRows = X.filter(e => e !== undefined).length

    const MAXI = d3.range(MAXV.length).filter(i => !isNaN(MAXV[i]));
    const MINI = d3.range(MINV.length).filter(i => !isNaN(MINV[i]));
    const maxValue = d3.pie().padAngle(padAngle).sort(null).value(i => MAXV[i])(MAXI);
    const maxThresh = maxValue[0].value
    const minValue = d3.pie().padAngle(padAngle).sort(null).value(i => MINV[i])(MINI);
    const minThresh = minValue[0].value

    // Compute default domains, and unique the y-domain.
    if (xDomain === undefined) xDomain = d3.extent(X);
    if (yDomain === undefined) yDomain = Y;
    if (hideUnknown === true)
      yDomain = new d3.InternSet(yDomain.filter(function(value, index, arr){
      return value !== undefined && value !== "Unknown";
    }));
    else {
      yDomain = new d3.InternSet(yDomain.filter(function(value, index, arr){
        return value !== undefined;
    }));
  }

  
    // Omit any data not present in the y-domain.
    // Lookup the x-value for a given y-value.
    const I = d3.range(X.length).filter(i => yDomain.has(Y[i]));
    const totals = d3.range(Z.length).filter(i => yDomain.has(Y[i]));

    const YX = d3.rollup(I, ([i]) => X[i], i => Y[i]);


  
    // Compute the default height.
    if (height === undefined) height = Math.ceil((yDomain.size + yPadding) * 25) + marginTop + marginBottom;
    if (yRange === undefined) yRange = [marginTop, height - marginBottom];
  
    // Construct scales, axes, and formats.
    const xScale = xType(xDomain, xRange);

    //console.log(xScale)
    const yScale = d3.scaleBand(yDomain, yRange).padding(yPadding);
    const xAxis = d3.axisTop(xScale).ticks(width / 80, xFormat).tickFormat(function(d) {
      return d < 0 ? '-'+d*-1 : d
    });
    

    //console.log(xAxis)
    const yAxis = d3.axisLeft(yScale).tickSize(0).tickPadding(6);
    const format = xScale.tickFormat(100, xFormat);
    console.log(xScale)
    // Compute titles.
    if (title === undefined) {
      title = i => `${Y[i]}\n${format(X[i])} % | ${format(Z[i])} kg`;
    } else if (title !== null) {
      const O = d3.map(data, d => d);
      const T = title;
      title = i => T(O[i], i, data);
    }


    // VIEWBOX //
    const svg = d3.create("svg")
      .attr("width", width+400)
      .attr("height", height)
      .attr("viewBox", [60, 0, width, height])   //x and y axis coordinates, width and height
      .attr("style", "max-width: 100%; height: auto; height: intrinsic; background: #323232")
    
    // TOP VALUES //
    svg.append("g")
      .attr("font-size", 14)
      .attr("transform", `translate(0,${marginTop})`)
      .call(xAxis)
      .call(g => g.selectAll(".tick line")
        .attr('stroke', 'white'))
      .call(g => g.selectAll(".tick text")
        .style('fill', 'white')
        .style('content', "Hello" ))
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll(".tick line").clone()
        .attr("y2", height - marginTop - marginBottom)
        .attr("stroke-opacity", 0.1))
      .call(g => g.append("text")
        .attr("x", xScale(0))
        .attr("y", -22)
        .attr("fill", "white")
        .attr("text-anchor", "center")
        .text(xLabel));
    // THE BAR //
    const bar = svg.append("g")     
      .selectAll("rect")
      .data(I)
      .join("rect")
        .attr("fill", i => colors[X[i] >= MAXV[i] || X[i] <= (MINV[i]) ? colors.length - 1 : 0])
        .attr("x", i => Math.min(xScale(0), xScale(X[i])))
        .attr("y", i => yScale(Y[i]))
        .attr("width", i => Math.abs(xScale(X[i]) - xScale(0)))
        .attr("height", yScale.bandwidth());
    
    // MOUSE OVER BAR VALUES //
    if (title) bar.append("title")
      .text(title);
    
    // VALUES NEXT TO BAR //
    svg.append("g")
        .attr("text-anchor", "end")
        .attr("font-family", "sans-serif")
        .attr("font-size", 12)
      .selectAll("text")
      .data(I)
      .join("text")
        .attr('fill', 'white')
        .attr("text-anchor", i => X[i] < 0 ? "end" : "start")
        .attr("x", i => xScale(X[i]) + Math.sign(X[i] - 0) * 4)
        .attr("y", i => yScale(Y[i]) + yScale.bandwidth() / 2)
        .attr("dy", "0.35em")
        .text(i => X[i] === 0 && Z[i] === 0 && Y[i] === "" ? "" : X[i] < 0 && Z[i] < 0 ? `-${format(X[i]*-1).replace('.', ',')}%  |  -${format(Z[i]*-1).replace('.', ',')} kg` : 
        X[i] < 0 && Z[i] > 0 ? `-${format(X[i]*-1).replace('.', ',')}%  |  ${format(Z[i]).replace('.', ',')} kg` : 
        `${format(X[i]).replace('.', ',')}%  |  ${format(Z[i]).replace('.', ',')} kg`);
  
    svg.append("g")
      .attr("font-size", 14)
      .attr("transform", `translate(${xScale(0)},0)`)
      .call(yAxis)
      .call(g => g.selectAll(".tick line")
        .attr('stroke', 'white'))
      .call(g => g.selectAll(".tick text")
        .style('fill', 'white'))
      .call(g => g.selectAll(".tick text")
        .filter(y => YX.get(y) < 0)
          .attr("text-anchor", "start")
          .attr("x", 6));
    
    // return svg.node();
    return {svg: svg.node(), rows: {chartRows}}
}