import theme from '../theme';
import * as React from 'react';
import { isEmpty }  from "underscore";
import classes from "../styles/reports";
import { debug } from '../general/utils';
import { Navigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LOADING, SUCCEEDED } from '../general/requestStates';
import ReportSetup from '../components/containers/ReportSetup';
import { Typography, Grid, Button, CircularProgress } from '@mui/material';
import { isMobile } from "react-device-detect";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


 /*
Reports are the main re-used component in Panorama
However, most of the code and logic is sitting in its sub-component reportSetup

reports have the following important props that should be assigned on creation:
reportName:   str
type:         str, should be 'table' or 'graph'
downloadable (optional):  bool 
interval (optional):      int - every how many seconds this report should automatically update

*/


class Reports extends React.Component {
  
  state = {
    redirect: false,
    hideFilters: false,
    forceGenerate: false
  }

  
  componentDidMount(){
    debug("reportSetup mounted!")
  }


  componentDidUpdate(prevProps){
    const { user, getSessionDataRS } = this.props  
    if (isEmpty(user) && getSessionDataRS === SUCCEEDED && prevProps.getSessionDataRS === LOADING) {
      this.setState({redirect: "/login"})
    } 
  }


  handleHideFilters(){
    const { hideAllFilters } = this.props
    hideAllFilters()
  }


  handleClearFilters(){
    const { setFilterSelections } = this.props
    let newDateFrom = new Date().getTime()
    let newDateTo = new Date().getTime()
    let selections = { dateFrom: newDateFrom, dateTo: newDateTo, clients: [], countries: [], sites: [], buildings: [], computers: [], orders: [], lines:[], products:[] }
    setFilterSelections({...selections})
    this.setState({forceGenerate: true})
  }


  handleBack(){
    this.setState({redirect: "/home"})
  } 


  handleUnauthorisedReportAccess(reportName){
    const {user} = this.props
    let redirection = false
    if (reportName === "Overview Dashbaord" && user['overview_dashboard_access'] === false ||
        reportName === "Order and Line Status" && user['order_and_line_status_access'] === false ||
        reportName === "Station and Operator Packing Distribution" && user['station_and_operator_performance_access'] === false ||
        reportName === "Station and Operator Packing Speed" && user['station_and_operator_packing_speed_access'] === false ||
        reportName === "Station and Operator Giveaway" && user['station_and_operator_giveaway_access'] === false ||
        reportName === "Station and Operator Waste" && user['station_and_operator_waste_access'] === false ||
        reportName === "Line Productivity" && user['line_productivity_access'] === false ||
        reportName === "Per Product Productivity" && user['per_product_productivity_access'] === false ||
        reportName === "Calibration Report" && user['calibration_report_access'] === false
    ){
      redirection = true
    }
  }


  render(){
    const { redirect } = this.state
    const { reportName, type, downloadable, user, pdf, csv, orderShow, productShow, hideFilters, pandas, getPandasRS } = this.props

    if (user === null) {
      return(
        <Grid container direction="row" alignItems="center">
          <CircularProgress/>
        </Grid>
      ) 
      
    }

    // if (user && reportName){
    //   this.handleUnauthorisedReportAccess(reportName)
    // }
    let interval = this.props.interval || null

    // this.handleUnauthorisedReportAccess(reportName)
    return(
      redirect ? <Navigate to={redirect} replace={true} /> :
        <div style={{...classes(theme, isMobile).mainCard}} >
          <Grid container direction="column" alignItems="left" justifyContent="left" >
            <div>
              <Grid container direction = "row" alignItems="flex-end" justifyContent="left">
              <Button
                  // variant="outlined"
                  // style={classes(theme, isMobile).label}
                  style={classes(theme, isMobile).backButton} 
                  onClick={this.handleBack.bind(this)}
                >
                  <ArrowBackIosIcon/>
                </Button>
                <Typography style={classes(theme).heading} > {reportName} </Typography>
                <div>
                  <Grid container direction="row"  justifyContent="center">
                    <Button variant="outlined" sx={{span:{color:"#059df5"}}} style={classes(theme, isMobile).hideFiltersButton} onClick={this.handleHideFilters.bind(this)}> {hideFilters ? "Show filters" : "Hide Filters"} </Button>
                    <Button variant="outlined" sx={{span:{color:"#059df5"}}} style={classes(theme, isMobile).clearFiltersButton} onClick={this.handleClearFilters.bind(this)}> Clear Filters </Button>
                  </Grid>
                </div>
              </Grid>
            </div>
              <div>
                <ReportSetup reportName={reportName} orderShow={orderShow ? true : false} productShow={productShow ? true : false} downloadable={downloadable ? true : false} pdf={pdf ? true : false} csv={csv ? true: false} type={type} hideFilters={hideFilters} forceGenerate={this.state.forceGenerate} interval={interval}/>
              </div>
          </Grid>
        </div>
    )
  }
}

export default styled(Reports)({theme})
