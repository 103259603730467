import theme from "../theme";
import * as React from "react";
import scale from "../img/Scale.png";
import { debug } from "../general/utils";
import { styled } from "@mui/material/styles";
import classes from "../styles/components/scales";
import { BrowserView, MobileView } from "react-device-detect";
import { FormControl, Typography, TextField } from "@mui/material";

class calibrationReport extends React.Component {
  state = {};


  changeColour(i) {
    const data = this.sortData()
    const today = new Date()
    const scaleDate = new Date(data[i].date)
    const dateDifference = (today - scaleDate)/1000/86400
    let colour = null
    //loop through the data to determine what colour needs to be displayed
    if (dateDifference > data[i].red) {
      colour = "red"    
    }
    else if (dateDifference > data[i].yellow) {
      colour = "orange"
    } else {
        colour = "green"
    }
    return colour
  }

  sortData(){
    const {data} = this.props
    const today = new Date()
    const redData = []
    const yellowData = []
    const greenData = []
    //sort the data in order of urgency
    for (let i = 0; i < data.length; i++){
      let scaleDate = new Date(data[i].date)
      let dateDifference = (today - scaleDate)/1000/86400
      if (dateDifference > data[i].red) {
        redData.push(data[i]) 
      }
      else if (dateDifference > data[i].yellow) {
        yellowData.push(data[i])
      } else {
          greenData.push(data[i])
      }
    }
    const sortedData = redData.concat(yellowData, greenData)
    return sortedData
  }

  generateReportHeadings() {
    let sortedData = this.sortData()
    let elements = []
    //generate the table headings
    for (let i = 0; i < sortedData.length; i++){
        let color = this.changeColour(i)
        let element = <div key={ i } style={{marginTop: "0.3rem"}}>
          <FormControl component="fieldset" style={{...classes(theme).clusterBox, backgroundColor: color}} varient='filled' >
            <div><img style={{...classes(theme).scale}} src={scale} alt="scale" /></div>
            <div><Typography style={{...classes(theme).calibration}}> {sortedData[i].scale} </Typography></div>
            <div><Typography style={{...classes(theme).date}}>Last Calibrated: {sortedData[i].date} </Typography></div>
            <div><Typography style={{...classes(theme).times}}>Time:{sortedData[i].time}</Typography></div>
          </FormControl>
        </div>
        elements.push(element)
    }

    this.scales = <div >{elements}</div>;
  }

  render() {
    const { data } = this.props;
    if (data){
      this.generateReportHeadings()
    }  

    return (
      <div style={{ marginTop: "2em" }}>
        <BrowserView>
          {this.scales}
        </BrowserView>
        <MobileView>
          {this.scales}
        </MobileView>
      </div>
    );
  }
}

export default styled(calibrationReport)({ theme });
