import { createSlice } from '@reduxjs/toolkit';
import { debug } from 'debug';
import {INITIAL, LOADING, SUCCEEDED, FAILED} from '../general/requestStates'

export const userManagement = createSlice({
  name: 'userManagement',
  initialState: {
    user: null,
    newUser: null,
    usernames: [],
    reportOptions: [],
    userData: null,
    oktaUser: false,
    userToEdit: null,
    serverDomain: '',
    sessionExpired: false,
    loginAttemptTime: null,
    loginCredentialsValidation: null,
    loginAttemptNumber: null,
    refreshUserScopeRS: INITIAL,
    loginRS: INITIAL,
    logOutRS: INITIAL,
    setPassword: INITIAL,
    getUserDataRS: INITIAL,
    getSessionDataRS: INITIAL,
    submitUserDataRS: INITIAL,
    updateUserDataRS: INITIAL,
    getAllUsernamesRS: INITIAL,
    getReportOptionsRS: INITIAL,
    loginAttemptNumberRS: INITIAL,
    checkLoginCredentialsRS: INITIAL,
  },

  reducers: {
    /// LOGIN REDUCERS ///
    login: (state, action) => {
      debug("Attempting to Login...")
      state.loginRS = LOADING
      state.sessionExpired = false
    },
    loginSuccess: (state, action) => {
      debug("Successfully Logged in")
      state.loginRS = SUCCEEDED
      state.user = action.payload
      console.log(action.payload)
    },
    invalidCredentials: (state, action) => {
      debug("Login was successful, but invalid credentials")
      state.user = action.payload
      state.loginRS = SUCCEEDED
    },
    loginFailed: (state) => {
      debug("Failed to Login")
      state.loginRS = FAILED
    },
    checkLoginCredentials: (state) => {
      debug("Checking login Credentials...")
      state.checkLoginCredentialsRS = LOADING
    },
    checkLoginCredentialsSucceeded: (state, action) => {
      debug("Login Credentials checked Successfully")
      state.checkLoginCredentialsRS = SUCCEEDED
      state.loginCredentialsValidation = action.payload
    },
    checkLoginCredentialsFailed: (state) => {
      debug("Login Credentials could not be checked")
      state.checkLoginCredentialsRS = FAILED
    },

    /// LOGOUT REDUCERS ///
    logOut: (state) => {
      state.user = null
      state.userData = null
      state.oktaUser= false
      state.userToEdit= null
      state.serverDomain=''
      state.sessionExpired= false
      state.loginAttemptTime= null
      state.loginCredentialsValidation= null
      state.loginAttemptNumber= null
      state.loginRS = INITIAL
      state.logOutRS = LOADING
    },
    autoLogOut: (state) => {
      state.user = null
      state.userData = null
      state.oktaUser= false
      state.userToEdit= null
      state.serverDomain=''
      state.sessionExpired= false
      state.loginAttemptTime= null
      state.loginCredentialsValidation= null
      state.loginAttemptNumber= null
      state.loginRS = INITIAL
      state.sessionExpired = true
    }, 
    logOutSuccess: (state, action) => {
      state.user = null
      debug("Initial Login")
      state.loginRS = INITIAL
      state.logOutRS = SUCCEEDED
    },

    /// SESSION REDUCERS ///
    getSessionData: (state) => {
      console.log("Loading Session Data...")
      state.getSessionDataRS = LOADING
    },
    getSessionDataSucceeded: (state, action) => {
      let response = action.payload
      if (response) {
        state.serverDomain = response["server_domain"]
      }
      state.user = response.user
      if (response.user) {
        state.oktaUser = "okta" in response.user
      }
      debug("Successfully retrieved Session Data")
      state.getSessionDataRS = SUCCEEDED
    },
    getSessionDataFailed: (state) => {
      debug("Failed to retrieve Session Data")
      state.getSessionDataRS = FAILED
    },

    /// GET USERNAME REDUCERS ///
    getAllUsernames: (state) => {
      debug("Loading All Usernames...")
      state.getAllUsernamesRS = LOADING
      debug("reducer for getUsernames called!")
    },
    getAllUsernamesSucceeded: (state, action) => {
      debug("Get usernames success, action is", action)
      state.usernames = action.payload
      debug("Successfully retrieved All Usernames")
      state.getAllUsernamesRS = SUCCEEDED
    },
    getAllUsernamesFail: (state) => {
      debug("Failed to retrieve All Usernames")
      state.getAllUsernamesRS = FAILED
    },

    /// GET USER DATA REDUCERS ///
    getUserData: (state) => {
      debug("reducer for getUserData called!")
      debug("Loading User Data...")
      state.getUserDataRS = LOADING
    },
    getUserDataSucceeded: (state, action) => {
      state.newUser = action.payload
      debug("Successfully retrieved User Data")
      state.getUserDataRS = SUCCEEDED
      debug("Get User Data Succeeded")
      debug("This is the User Data", state.userData)
    },
    getUserDataFailed: (state) => {
      debug("Failed to retrieve User Data")
      state.getUserDataRS = FAILED
    },

    refreshUserScope: (state) => {
      state.refreshUserScopeRS = LOADING
      console.log('Refreshing User Scope')
    },
    refreshUserScopeSucceeded: (state, action) => {
      console.log('User Scope Refreshed Successfully')
      state.user = action.payload
      console.log(action.payload, 'payload')
      console.log(state.user, 'user')
      state.refreshUserScopeRS = SUCCEEDED
    },
    refreshUserScopeFailed: (state) => {
      state.refreshUserScopeRS = FAILED
    },


    getReportOptions: (state) => {
      state.getReportOptionsRS = LOADING
    },
    getReportOptionsSucceeded: (state, action) => {
      state.reportOptions = action.payload
      state.getReportOptionsRS = SUCCEEDED
    },
    getReportOptionsFailed: (state) => {
      state.getReportOptionsRS = FAILED
    },


    /// SEND USER DATA REDUCERS ///
    submitUserData: (state, action) => {
      debug("Loading submit User Data...")
      state.submitUserDataRS = LOADING
      debug("SUBMIT USER DATA CALLED")
    },
    submitUserDataSucceeded: (state, action) => {
      state.userData = action.payload
      state.submitUserDataRS = SUCCEEDED
      console.log("SUBMIT USER DATA SUCCEEDED")
    },
    submitUserDataFailed: (state, action) => {
      debug("failed to retrieve submit User Data...")
      state.submitUserDataRS = FAILED
      console.log("SUBMIT USER DATA FAILED")
    },

    /// UPDATE USER DATA REDUCERS ///
    updateUserData: (state, action) => {
      debug("Loading updated User Data...")
      state.updateUserDataRS = LOADING
      debug("update USER DATA CALLED")
    },
    updateUserDataSucceeded: (state, action) => {
      state.userData = action.payload
      debug("Successfully Updated User Data")
      state.updateUserDataRS = SUCCEEDED
      debug("update USER DATA SUCCEEDED")
    },
    updateUserDataFailed: (state, action) => {
      debug("Failed to update User Data")
      state.updateUserDataRS = FAILED
      console.log("update USER DATA FAILED")
    },
    completeLogIn: (state) => {
      debug("calling complete login reducer")
      state.user = {...state.user}
    },
    clearOktaStatus: (state) => {
      debug("Clearing okta status.")
      state.oktaUser = false
    },
    addLoginAttempt: (state) => {
      debug("Adding this login attempt to total")
      state.loginAttemptNumberRS = LOADING
    },
    addLoginAttemptSucceeded: (state, action) => {
      debug("New login attempt added successfully")
      state.loginAttemptNumber = action.payload.loginAttempt
      state.loginAttemptTime = action.payload.loginAttemptTime
      state.loginAttemptNumberRS = SUCCEEDED
    },
    addLoginAttemptFailed: (state) => {
      debug("New login attempt added unsuccessfully")
      state.loginAttemptNumberRS = FAILED
    },
  },
})

export const {login, logOut, autoLogOut, getSessionData, getAllUsernames, getUserData, setUserToEdit, submitUserData, 
              updateUserData, completeLogIn, clearOktaStatus, addLoginAttempt, checkLoginCredentials, getReportOptions, refreshUserScope } = userManagement.actions

export default userManagement.reducer