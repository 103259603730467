import {connect} from 'react-redux'
import Layout from '../pages/newSetup';
import { postSetup } from '../reducers/setupManagement'


const mapStateToProps = state => ({
    computerName: state.setupManagement.newSetupParentName,
    postSetupRS: state.setupManagement.postSetupRS
})


const mapDispatchToProps = dispatch => ({
    postSetup: (setupData) => dispatch(postSetup(setupData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
