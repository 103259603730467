import React from 'react'
import { styled } from '@mui/material/styles';
import classes from "../styles/components/reportIcon"
import { Typography } from '@mui/material';
import theme from "../theme"
import { Navigate } from 'react-router-dom';
import { isEmpty } from "underscore";
import { debug } from "../general/utils";

class reportIcon extends React.Component {

    state = {
        redirect: false,
        loaded: false,
        imageLoaded: false,
    }


    handleImageLoaded() {
        this.setState({ imageLoaded: true });
      }
    
      //when clicking on home report icons
    handleClick(){
        const { title, url } = this.props
        debug("You clicked on " + title + "!")
        this.setState({redirect: url})
    }

    render(){
        const { icon, title } = this.props
        const { redirect } = this.state

        return(
        <div 
            style={classes(theme).card}
            onClick = {this.handleClick.bind(this)}
            
            >
            <img style={classes(theme).icon} src={icon} onLoad={this.handleImageLoaded.bind(this)} alt={"click to go to " + title}/>
            <Typography style = {classes(theme).title}> {title} </Typography>
            {redirect ? <Navigate to={redirect}  /> : null }
        </div>
        )
    }

}


export default styled(reportIcon)({theme})