import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#065f92", // blue
    },
    secondary: {
      main: "#F79007", // orange
    },
    error: {
      main: "#d32f2f",
    },
    warning: {
      main: "#ed6c02", 
    },
    info: {
      main: "#0288d1",
    },
    success: {
      main: "#2e7d32",
    },
    white: {
      main: "#FFFFFF",
    },
  },
  spacing: (factor) => `${factor}rem`, 
});


  
  
export default theme;