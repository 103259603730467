import { createSlice } from '@reduxjs/toolkit';
import { debug } from '../general/utils';
import {INITIAL, LOADING, SUCCEEDED, FAILED} from '../general/requestStates'

export const setupManagement = createSlice({
  name: 'setupManagement',
  initialState: {
    setupData: [],
    getSetupsRS: INITIAL,
    newSetupParentName: null,
    postSetupRS: INITIAL
  },
  reducers: {
    getSetups: (state, action) => {
        debug("Loading Setups...")
        state.getSetupsRS = LOADING
    },
    getSetupsSuccess: (state, action) => {
        state.setupData = action.payload
        debug("Successfully retrieved Setups")
        state.getSetupsRS = SUCCEEDED
    },
    getSetupsFail: (state, action) => {
        debug("Failed to retrieve Setups")
        state.getSetupsRS = FAILED
    },
    setComputerToAddTo: (state, action) => {
      state.newSetupParentName = action.payload
    },
    postSetup: (state, action) => {
      debug("Loading post Setups...")
      state.postSetupRS = LOADING
    },
    postSetupSuccess: (state, action) => {
      debug("Successfully retrieved post Setups")
      state.postSetupRS = SUCCEEDED
    },
    postSetupFail: (state, action) => {
      debug("Failed to retrieve post Setups")
      state.postSetupRS = FAILED
    },
  },
})

export const {getSetups, setComputerToAddTo, postSetup} = setupManagement.actions

export default setupManagement.reducer