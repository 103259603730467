import commonstyles from "./common";

const styles = (theme) => ({
  centerDivs: commonstyles(theme).centerDivs,
  tips: commonstyles(theme).tips,
  headingDiv: {
    // backgroundColor: theme.palette.primary.main,
  },
  heading: commonstyles(theme).heading,
  reportContainer: {
    ...commonstyles(theme).leftDivs,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderColor: theme.palette.primary.main,
    textColor: "white",
    borderStyle: "solid",
    borderRadius: "2em",
    // backgroundColor: "white",
    backgroundColor: "#323232",
    minHeight: '90%',
  },
  logo: {
    textAlign: "center",
    margin: "auto",
    marginTop: "2em",
    marginBottom: "2em",
    display: "block",
    maxHeight: "2em",
  },
  loginButton: {
    ...commonstyles(theme).button,
    width: "10em",
  },
});

export default styles;
