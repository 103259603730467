import {connect} from 'react-redux'
import Layout from '../pages/users';
import { getUserData, getAllUsernames, setUserToEdit, submitUserData, updateUserData, getReportOptions } from '../reducers/userManagement';
import { getClusterNames } from '../reducers/clusterManagement';
import { submitData } from "../reducers/data"

const mapStateToProps = state => ({
    newUser: state.userManagement.newUser,
    user: state.userManagement.user,
    usersRS: state.userManagement.usersRS,
    userData: state.userManagement.userData,
    submitUserDataRS: state.userManagement.submitUserDataRS,
    updateUserDataRS: state.userManagement.updateUserDataRS,
    usernames: state.userManagement.usernames,
    getAllUsernamesRS: state.userManagement.getAllUsernamesRS,
    getReportOptionsRS: state.userManagement.getReportOptionsRS,
    reportOptions: state.userManagement.reportOptions,
    userToEdit: state.userManagement.userToEdit,
    getUserDataRS: state.userManagement.getUserDataRS,
    clusterNames: state.clusterManagement.clusterNames,
    getClustersNamesRS: state.clusterManagement.getClusterNamesRS,
    submitDataRS: state.data.submitDataRS,
    latestSubmission: state.data.latestSubmission,
    justData: state.data.justData,
})

const mapDispatchToProps = dispatch => ({
  getAllUsernames: () => dispatch(getAllUsernames()),
  getReportOptions: () => dispatch(getReportOptions()),
  getClusterNames: () => dispatch(getClusterNames()),
  setUserToEdit: (user) => dispatch(setUserToEdit(user)),
  getUserData: (sid) => dispatch(getUserData(sid)),
  submitUserData:(payload) => dispatch(submitUserData(payload)),
  updateUserData:(payload) => dispatch(updateUserData(payload)),
  submitData: (database, table, data) => dispatch(submitData(database, table, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)