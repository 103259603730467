import commonstyles from "../common";

const styles = (theme) => ({
  clusterBox: {
    marginLeft: "3%",
    padding: "1em",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "0.5em",
    width: "100%",
    height: "7em",
    borderColor: theme.palette.info.main,
  },
  clusterBoxMobile: {
    marginLeft: "3%",
    padding: "1em",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "0.5em",
    width: "90%",
    height: "8em",
    borderColor: theme.palette.info.main,
  },
  scale: {
    width: "3em",
    marginTop: "1em",
    marginLeft: "0.5em",
  },
  calibration: {
    marginTop: "-2.2em",
    marginLeft: "5em",
    fontSize: "1.2rem",
  },
  date: {
    marginTop: "-2.8em",
    marginLeft: "23em",
    fontSize: "1.2rem"
  },
  times: {
    marginTop: "-1em",
    marginLeft: "23em",
    fontSize: "1.2rem"
  },
});

export default styles;
