import { isMobile } from "react-device-detect";
import commonstyles from "./common";
// import { BrowserView, MobileView, isMobile } from "react-device-detect";

const styles = (theme, isMobile) => (isMobile ? mobile(theme) : browser(theme))

const browser = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#FFFFFF"
    },
    "& .MuiInputLabel-outlined": {
      color: "#FFFFFF"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#FFFFFF"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#FFFFFF"
    }
  },
  mainCard: commonstyles(theme, isMobile).mainCard,
  filterField: { ...commonstyles(theme, isMobile).textField, width: theme.spacing(15) },
  longFilterField: {
    ...commonstyles(theme, isMobile).textField,
    width: theme.spacing(30),
  },
  button: commonstyles(theme, isMobile).button,
  warning: commonstyles(theme, isMobile).warning,
  label: commonstyles(theme, isMobile).label,
  tips: commonstyles(theme, isMobile).tips,

})

const mobile = (theme) => ({
  ...browser(theme),
  longFilterField: {
    ...commonstyles(theme, isMobile).textField,
    width: "90%"
  },
  warning: {...commonstyles(theme, isMobile).warning, width: "90%"},
})

function applyMode(mode, checkClusterValidity) {
  console.log("applyMode called with", mode);

  let hideScope = ["init", "blank"].includes(mode);
  let hideClusterSelect = ["new", "init"].includes(mode);
  let disableClusterSelect = ["modify"].includes(mode);
  let selectClusterLabel = ["modify"].includes(mode)
    ? "Selected Cluster"
    : "Select a cluster to view";
  let hideClusterName = ["init"].includes(mode);
  let newClusterNameLabel = ["view", "modify", "saving", "deleting"].includes(
    mode
  )
    ? "Cluster Name"
    : mode === "new"
    ? "New cluster name"
    : mode === "blank"
    ? "or start typing a new cluster name here"
    : "";
  let hideSpinner = !["init", "saving", "deleting"].includes(mode);
  let spinnerText = mode === "init" ? "Loading..." : mode === "saving" ? "Saving..." : 
                    mode === "deleting" ? "Deleting..." : mode === "refreshing" ? "Refreshing..." : "";
  let hideSaveButton = ["view", "blank", "deleting"].includes(mode);
  let hideCancelButton = ["view", "deleting"].includes(mode);
  let hideDeleteButton = !["view"].includes(mode);
  let disableSaveButton = !checkClusterValidity();
  let disableDeleteButton = ["deleting"].includes(mode);

  let modeStyles = {
    hideScope,
    hideClusterSelect,
    disableClusterSelect,
    selectClusterLabel,
    hideClusterName,
    newClusterNameLabel,
    hideSpinner,
    spinnerText,
    hideSaveButton,
    hideCancelButton,
    hideDeleteButton,
    disableSaveButton,
    disableDeleteButton,
  }
  return modeStyles
}

export { applyMode }
export default styles