import commonstyles from "../common";
import { isMobile } from "react-device-detect";


const styles = (theme, isMobile) => (isMobile ? mobile(theme) : browser(theme))

const browser = (theme) => ({
  textField: {
    margin: theme.spacing(1),
    width: theme.spacing(12),
    color: "white"
  },
  textFieldMobile: commonstyles(theme).textField,
  longField: commonstyles(theme).longField,
  longFieldMobile: commonstyles(theme).longField,
  label: commonstyles(theme).label,
  csvButton: {
    margin: "1em", 
    color: '#059df5', 
    borderColor:'#059df5', 
    marginLeft: "1.2em"
  },
  pdfButton: {
    margin: "1em", 
    color: '#059df5', 
    borderColor:'#059df5', 
    marginLeft: "1.4em"
  },

  root: {

    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      color: "blue",
      visibility: "visible"},
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#FFFFFF"
    },
    "& .MuiInputLabel-outlined": {
      color: "#FFFFFF"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#FFFFFF"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#FFFFFF"
    }
  },
  warning: {
    fontSize: theme.spacing(0.8),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5)
  },
  svg: commonstyles(theme).svg,
  graph: {...commonstyles(theme).label, marginTop: 0}
})

const mobile = (theme) => ({
  ...browser(theme),
  longField: {
    width: "90%",
    margin: theme.spacing(1)},
  lineField: {
      width: "100%", 
      margin: theme.spacing(1)},
  textField: {
    width: "90%",
    margin: theme.spacing(1)
  },
  csvButton: {
    margin: "3em", 
    color: '#059df5', 
    borderColor:'#059df5', 
    marginLeft: "1.2em",
    marginTop: "1em"
  },
  pdfButton: {
    color: '#059df5', 
    borderColor:'#059df5',
    marginTop: "-2em",
    marginLeft: "-0.5em", 
  },
  // label: {...commonstyles(theme).label, margin: theme.spacing(0)},
})

export default styles;
