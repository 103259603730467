import {connect} from 'react-redux'
import Layout from '../pages/reports';
import { getOrders, setFilterSelections, hideAllFilters} from "../reducers/reports";
import { getProducts } from '../reducers/data';

const mapStateToProps = state => ({             //selecting the part of the data from the store that the connected component needs
    user: state.userManagement.user,
    getSessionDataRS: state.userManagement.getSessionDataRS,
    reportFile: state.reports.reportFile,
    getReportRS: state.reports.getReportRS,
    pandas: state.reports.pandas,
    getPandasRS: state.reports.getPandasRS,
    siteOptions: state.clusterManagement.siteOptions,
    countryOptions: state.clusterManagement.countryOptions,
    lines: state.data.latestQuery,
    orders: state.data.latestQuery,
    logOutRS: state.userManagement.logOutRS,
    products: state.data.products,
    getProductsRS: state.data.getProductsRS,
    hideFilters: state.reports.hideFilters
})

const mapDispatchToProps = dispatch => ({
  getOrders: () => dispatch(getOrders()),
  getProducts: () => dispatch(getProducts()),
  setFilterSelections: (selections) => dispatch(setFilterSelections(selections)),
  hideAllFilters: () => dispatch(hideAllFilters()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)