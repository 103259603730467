import React from "react";
import { Typography, Button, TextField, Grid, Autocomplete, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import classes from "../styles/setups";
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import theme from '../theme';
import { clone, isEmpty } from "../general/utils"
import { Navigate } from 'react-router-dom';
import { LOADING } from '../general/requestStates'
import { isMobile } from "react-device-detect";


class setups extends React.Component {

  state = {
    redirect: null,

    client: null,
    country: null,
    site: null,
    building: [],
    computer: []
  }

  emptyOptions = {
    client: null,
    country: null,
    site: null,
    building: [],
    computer: []
  }

  componentDidMount(){
    const { getSetups } = this.props
    getSetups()
  }

  // returns a setupData instance based on a sector
  getSetupDataInstance(sector, sid){
    const { setupData } = this.props
    for (var i in setupData){
      if (setupData[i][sector+"_sid"] === sid){return setupData[i]}
    }
  }

  /**
   * Loops through the current selected computers
   * and takes out computers whose corresponding building
   * is not present in the new building options
   */
  getComputerSpecificFilter(building){
    const { computer } = this.state
    let filteredComputers = []
    for (var i in computer){
      let cmp = computer[i]
      let equal = (element) => element.sid === cmp.sid
      if (building.some(equal)){
        filteredComputers.push(cmp)
      }
    }
    return filteredComputers
  }

  /**
   * Determines which options should be selected
   * based on what value was changed in the selections
   * @returns the new state options
   */
  getSectorSelection(key, value){
    // first extracts a single selection if the sector is multi (buildings and computers)
    let singleValue = !Array.isArray(value) ? value : 
      isEmpty(value) ? null : value[0]

    let setup = singleValue ? this.getSetupDataInstance(key, singleValue.sid) : singleValue
    let stateChanges = clone(this.emptyOptions)

    // if client is selected
    // change client to new changed value
    // and all lower sectors to null (excluding country)
    if (key === "client"){
      stateChanges["client"] = value
      delete stateChanges["country"]
      return stateChanges
    }
    // if country is selected
    // change country to new changed value
    // and all lower sectors to null (excluding client)
    if (key === "country"){
      stateChanges["country"] = value
      delete stateChanges["client"]
      return stateChanges
    }
    // add the client and country to the current selection
    stateChanges["client"] = setup ? {sid:setup["client_sid"], name:setup["client_name"]} : this.state.client
    stateChanges["country"] = setup ? {sid:setup["country_sid"], name:setup["country_name"]} : this.state.country
    // if site is selected
    // change site to new changed value
    // and all lower sectors to null
    if (key === "site"){
      stateChanges["site"] = value
      return stateChanges
    }
    // add the site to the current selection
    stateChanges["site"] = setup ? {sid:setup["site_sid"], name:setup["site_name"]} : this.state.site
    // if building is selected
    // change building to new changed value
    // and all change computer selections to null
    // if a selected computer does not correspond with the new building options
    if (key === "building"){
      stateChanges["building"] = value
      stateChanges["computer"] = this.getComputerSpecificFilter(value)
      return stateChanges
    }
    const { building } = this.state
    // keeps the buildings if present
    // otherwise add the building to the current selection
    stateChanges["building"] = !isEmpty(building) ? building : [{sid:setup["building_sid"], name:setup["building_name"]}]
    // adds the computer to the current selection
    stateChanges["computer"] = value
    return stateChanges
  }

  /**
   * Handles the selection of an option.
   * Sets the state to the new options,
   * causing a re-render.
   */
  handleSelect(key, event, value){
    this.setState(this.getSectorSelection(key, value))
  }

  handleAddNewSetup(key, event){
    const { setComputerToAddTo } = this.props
    setComputerToAddTo(key)
    this.setState({redirect: "/newSetup"})
  }

  /**
   * Default Table headings
   */
  getSetupColumnHeadings(){
    return [
      { field: 'ID', headerName: 'ID', width: 70, type: 'number', editable: false },
      { field: 'Description', headerName: 'Description', width: 150, type: 'string', editable: true },
      { field: 'Tare', headerName: 'Tare', width: 50, type: 'number', editable: true },
      { field: 'Minimum_Weight', headerName: 'Minimum Weight', width: 130, type: 'number', editable: true },
      { field: 'Target_Weight', headerName: 'Target Weight', width: 100, type: 'number', editable: true },
      { field: 'Maximum_Weight', headerName: 'Maximum_Weight', width: 130, type: 'number', editable: true },
      { field: 'Button', headerName: 'Button', width: 70, type: 'number', editable: true },
      { field: 'Effort', headerName: 'Effort', width: 70, type: 'number', editable: true },
      { field: 'Lower_Limit', headerName: 'Lower Limit', width: 100, type: 'number', editable: true },
      { field: 'Upper_Limit', headerName: 'Upper Limit', width: 100, type: 'number', editable: true },
      { field: 'Nett_Weight', headerName: 'Nett Weight', width: 100, type: 'number', editable: true },
      { field: 'Band', headerName: 'Band', width: 70, type: 'number', editable: true },
      { field: 'LEDS', headerName: 'LEDS', width: 70, type: 'number', editable: true }
    ]
  }

  /**
   * Filters the setup data
   * based on the options selected
   * @returns setup data to be rendered
   */
  filterSetupData(){
    const { setupData } = this.props
    const { client, country, site, building, computer } = this.state
    let renderData = []
    for (var setupI in setupData){
      let setup = setupData[setupI]
      if ((client === null || client.sid === setup.client_sid) &&
          (country === null || country.sid === setup.country_sid) &&
          (site === null || site.sid === setup.site_sid) &&
          (isEmpty(building)  || this.optionsContainObject(building, setup.building_sid)) &&
          (isEmpty(computer) || this.optionsContainObject(computer, setup.computer_sid))){
        renderData.push(setup)
      }
    }
    return renderData
  }

  /**
   * Appends 1 of each sector to the sector's options
   * Excludes the duplicates
   * @return the sectors list with possible updates
   */
  addAllOptions(sectors, sids, names){
    for (let i in sectors){
      sectors = this.addToOptions(sectors, i, sids[i], names[i])
    }
    return sectors
  }

  /**
   * Adds an option to a single sector
   * if it does not already exist in that sector
   * @return the sectors list with a single possible update
   */
  addToOptions(sectors, index, sector_sid, sector_name){
    if (!sectors[index].some(e => e.sid === sector_sid)) {
      sectors[index].push({sid:sector_sid, name:sector_name})
    }
    return sectors
  }

  /**
   * Takes in an options array [{sid, name}, {sid, name}, ...]
   * and returns whether a specified sid is contained within
   * @returns boolean whether sid is contained within an dict array
   */
  optionsContainObject(sectorOtions, objectSid){
    for (var i in sectorOtions){
      if (sectorOtions[i].sid === objectSid){
        return true
      }
    }
    return false
  }

  /**
   * Generates the sector options (client, country, site, building, computer)
   * to be selected by a user.
   * Also returns the buildings that have no computers selected under them,
   * if at least 1 computer is selected.
   * @returns [sector options plus building-warning-exclusions]
   */
  getOptions(){
    const { setupData } = this.props
    const { client, country, site, building, computer } = this.state
    let buildingsExcluded = clone(building)

    // clients, countries, sites, buildings, computers opsions to be returned
    let sectors = [[], [], [], [], []]

    // loops through each computer's information and adds them to variables for later
    for (var i in setupData){
      const singleData = setupData[i]
      const { client_sid, client_name, country_sid, country_name, site_sid, site_name,
        building_sid, building_name, computer_sid, computer_name } = singleData
      let sids = [client_sid, country_sid, site_sid, building_sid, computer_sid]
      let names = [client_name, country_name, site_name, building_name, computer_name]

      // takes the building out of the warning messages
      // if the computer is already selected
      if (computer.some(e => e.sid === computer_sid)){
        buildingsExcluded.forEach((item, index, arr) => {
          if (item.sid === building_sid) {arr.splice(index, 1);}
        });
      }

      // COMPUTER
      if (!isEmpty(computer)){
        if (computer[0].sid === computer_sid){
          // adds all the sectors in the current looped computer
          // if the corresponding computer is selected
          // then moves on to the next computer details
          sectors = this.addAllOptions(sectors, sids, names)
          continue
        } else if (this.optionsContainObject(building, building_sid)){
          // adds only the computer if its parent building is selected
          sectors = this.addToOptions(sectors, 4, computer_sid, computer_name)
        }
      }
      // BUILDING
      if (!isEmpty(building)){
        if (isEmpty(computer) && this.optionsContainObject(building, building_sid)){
          // adds all the sectors in the current looped computer
          // if the corresponding building is selected
          // and if no computer is selected
          // then moves on to the next computer details
          sectors = this.addAllOptions(sectors, sids, names)
          continue
        } else if (site.sid === site_sid){
          // adds only the building if its parent site is selected
          sectors = this.addToOptions(sectors, 3, building_sid, building_name)
        }
      }
      // SITE
      if (site){
        if (isEmpty(building) && site.sid === site_sid){
          // adds all the sectors in the current looped computer
          // if the corresponding site is selected
          // and if no building is selected
          // then moves on to the next computer details
          sectors = this.addAllOptions(sectors, sids, names)
          continue
        } else if (country.sid === country_sid && client.sid === client_sid){
          // adds only the site if its parent client and country are selected
          sectors = this.addToOptions(sectors, 2, site_sid, site_name)
        }
      }

      // CLIENT & COUNTRY
      if ((!client && country && country.sid === country_sid) ||
          (!country && client && client.sid === client_sid) ||
          (!site && client && country && country.sid === country_sid && client.sid === client_sid) ||
          (!country && !client)){
          // adds all the sectors in the current looped computer
          // - only country is selected, and matches
          // - only client is selected, and matches
          // - only the country and client are selected, and match
          // - nothing is selected
          // then moves on to the next computer details
        sectors = this.addAllOptions(sectors, sids, names)
        continue
      }
      // if all else is not satisfied then just add the country and client to options
      sectors = this.addToOptions(sectors, 1, country_sid, country_name)
      sectors = this.addToOptions(sectors, 0, client_sid, client_name)
    }
    // lastly, adds the buildings-to-be-excluded list to be returned
    sectors.push(isEmpty(computer) ? [] : buildingsExcluded)
    return sectors
  }

  /**
   * If there are building warnings,
   * returns a warning message with those buildings
   * @returns warning message to be displayed
   */
  getWarningMessage(excludedBuildings){
    if (isEmpty(excludedBuildings)){return null}
    let elements = []
    for (let i = 0; i < excludedBuildings.length; i++){
      let name = excludedBuildings[i].name
      elements.push(<li key={ i }>{ name }</li>)
    }
    let message = <div>
      Warning: the following buildings have no computers selected:
      <ul>{elements}</ul>
    </div>
    return message
  }

  /**
   * Generates an array of tables with the appropriate setup data.
   * First filters the setup data based on the options selected
   * @returns Setup data tables to be displayed
   */
  generateSetupTables(){
    let renderData = this.filterSetupData()
    let dataColumns = this.getSetupColumnHeadings()
    let elements = []
    for (let i = 0; i < renderData.length; i++){
      let sectorData = renderData[i]
      let element = <div key={ i }>
          <Typography style={{...classes(theme, isMobile).label, fontSize: "1.2rem", marginTop: theme.spacing(1.5), marginBottom: theme.spacing(-0.5), marginLeft: theme.spacing(1), color: "white"}}> Building: {sectorData["building_name"]} </Typography>
          <br />
          <Typography style={{...classes(theme, isMobile).label, fontSize: "1.2rem", marginBottom: theme.spacing(-0.5), marginLeft: theme.spacing(1), color: "white"}}> Computer: {sectorData["computer_name"]}  </Typography> 
          <div>       
            <Button 
              style={classes(theme, isMobile).addItem} 
              variant="outlined" 
              color="primary" 
              startIcon={<AddIcon />}
              onClick={this.handleAddNewSetup.bind(this, [sectorData["computer_name"]])}
            >
              Add New Setup
            </Button>
          </div> 
          <DataGrid
              sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}, div:{color:"#FFFFFF"}}}
              style={classes(theme, isMobile).setupTable}
              rows={sectorData["setup_data"]}
              getRowId={(row) => row.ID}
              columns={dataColumns}
              experimentalFeatures={{ newEditingApi: true }}
              initialState={{
                  sorting: {
                      sortModel: [{ field: 'ID', sort: 'asc' }],
                  },
              }}
          />
        </div>
      elements.push(element)
    }

    this.sites = <div>
      {elements}
    </div>
  }

  /**
   * Initial page loading whilst setup data is being requested.
   * @returns Loading page spinner
   */
  getPageLoading(){
    return <div>
        <Grid container direction = "row" style={{margin: theme.spacing(1)}} alignItems="center">
          <Grid item style={classes(theme).reportContainer}>
            <CircularProgress size="1em" style={{marginRight: theme.spacing(1), display: "inline-block"}}/>
            <Typography style={{...classes(theme, isMobile).tips, display: "inline-block", color: "white"}}> Loading... </Typography>
          </Grid>
        </Grid>
      </div>
  }
  
  render(){     
    const { getSetupsRS } = this.props
    const { redirect, client, country, site, building, computer } = this.state

    let pageLoader = this.getPageLoading()
    let [clientOptions, countryOptions, siteOptions, buildingOptions, computerOptions, excludedBuildings] = this.getOptions()
    let warningMessage = this.getWarningMessage(excludedBuildings)
    let computerOptionsNames = computerOptions.map(({ name }) => name)
    this.generateSetupTables()

    return(
      redirect ? <Navigate to={redirect}/> :
      getSetupsRS === LOADING ? pageLoader : 
      <div>
        <Grid container direction = "column" alignItems="left">
          <Grid item style={classes(theme, isMobile).reportContainer}>
          <Typography style={{...classes(theme, isMobile).addItem, color:"white"}}>Choose which setups you would like to view</Typography>
            <Grid container direction= "row">
                <Autocomplete
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                  style={classes(theme, isMobile).userModifyInputField}
                  options = {clientOptions}
                  getOptionLabel = {e => e.name}
                  isOptionEqualToValue = {(o, v) => o.sid === v.sid}
                  value={client}
                  renderInput={(params) => <TextField {...params} label={"Client"} variant="outlined" />}
                  onChange={this.handleSelect.bind(this, "client")}
                />
                <Autocomplete
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                  style={classes(theme, isMobile).userModifyInputField}
                  options = {countryOptions}
                  getOptionLabel = {e => e.name}
                  isOptionEqualToValue = {(o, v) => o.sid === v.sid}
                  value = {country}             
                  renderInput={(params) => <TextField {...params} label={"Country"} variant="outlined" />}
                  onChange={this.handleSelect.bind(this, "country")}
                />
                <Autocomplete
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}                
                  style={classes(theme, isMobile).userModifyInputField}
                  options = {siteOptions}
                  getOptionLabel = {e => e.name}
                  isOptionEqualToValue = {(o, v) => o.sid === v.sid}
                  value = {site}             
                  renderInput={(params) => <TextField {...params} label={"Site"} variant="outlined" />}
                  onChange={this.handleSelect.bind(this, "site")}
                />
                <Autocomplete
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                  multiple
                  id="building-options"
                  style={classes(theme, isMobile).userModifyInputField}
                  options = {buildingOptions}
                  getOptionLabel = {e => e.name}
                  isOptionEqualToValue = {(o, v) => o.sid === v.sid}
                  value = {building}             
                  renderInput={(params) => <TextField {...params} label={"Buildings"} variant="outlined" />}
                  onChange={this.handleSelect.bind(this, "building")}
                />
                <Autocomplete
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                  multiple
                  id="computer-options"
                  style={classes(theme, isMobile).userModifyInputField}
                  options = {computerOptions}
                  getOptionLabel = {e => e.name}
                  isOptionEqualToValue = {(o, v) => o.sid === v.sid}
                  value={computer}
                  renderInput={(params) => <TextField {...params} label={"Computers"} variant="outlined" />}
                  onChange={this.handleSelect.bind(this, "computer")}
                />
            </Grid>

            {warningMessage}

            <div>       
              <Button 
                style={classes(theme, isMobile).addItem} 
                variant="outlined" 
                // color="primary" 
                startIcon={<AddIcon />}
                onClick={this.handleAddNewSetup.bind(this, computerOptionsNames)}
              >
                Add Setup to all selected Computers
              </Button>
            </div> 

            {this.sites}

          </Grid>
        </Grid>
      </div>
    )
  }
}

export default styled(setups)({theme})