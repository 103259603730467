import commonstyles from "./common";

const styles = (theme, isMobile) => (isMobile ? mobile(theme) : browser(theme))

const browser = (theme) => ({
  mainCard: {
    backgroundColor: "#323232",
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    // maxWidth: "80rem"
  },
  textField: commonstyles(theme).textField,
  textFieldMobile: commonstyles(theme).textFieldMobile,
  filterField: {...commonstyles(theme).textField, width: theme.spacing(15)},
  longFilterField: {...commonstyles(theme).textField, width: theme.spacing(30)},
  button: commonstyles(theme).button,
  warning: commonstyles(theme).warning,
  label: commonstyles(theme).label,
  tips: commonstyles(theme).tips,
  longField: commonstyles(theme).longField,
  longFieldMobile: commonstyles(theme).longFieldMobile,
  svg: commonstyles(theme).svg,
  whiteText: commonstyles(theme).whiteText,
  heading: commonstyles(theme).heading,
  hideFiltersButton: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(0.6),
    color: '#059df5',
    borderColor:'#059df5',
  },
  clearFiltersButton: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(0.6),
    color: '#059df5',
    borderColor:'#059df5',
  },
  backButton: {
    marginBottom: theme.spacing(0.8)
  },
})

const mobile = (theme) => ({
  ...browser(theme),
  hideFiltersButton: {
    margin: theme.spacing(1.1),
    marginBottom: theme.spacing(0.6),
    color: '#059df5',
    borderColor:'#059df5',
  },
  clearFiltersButton: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(0.6),
    color: '#059df5',
    borderColor:'#059df5',
  },
})

export default styles;