import commonstyles from "../common"

const styles = theme => ({ 
    centerDivs: commonstyles(theme).centerDivs,
    card: {
        ...commonstyles(theme).centerDivs,
        width: "17em",
        borderColor: theme.palette.primary.main,
        borderRadius: "0.8em",
        borderWidth: "0.1em",
        padding: "0.5em",
        margin: theme.spacing(1),
        cursor: "pointer",

    },
    title: {
        fontWeight: "600",
        color: "#FFFFFF",
    },    
    icon: {
        maxHeight: '6em',
    },
    loginButton: {
        ...commonstyles(theme).button,
        width: "10em"
    }
    
})

export default styles