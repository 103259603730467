import React from 'react'
import { styled } from '@mui/material/styles';
import { Link, NavLink, Navigate } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Popup from 'reactjs-popup';
import { Typography, Grid } from '@mui/material';
import classes from "../styles/components/navigation"
import logo from '../img/Ergopak-logo.png';
import panorama from '../img/panorama_bb.png';
import theme from '../theme';
import { INITIAL, LOADING } from '../general/requestStates'
import {BrowserView, MobileView} from 'react-device-detect';
import DrawerComp from './DrawerComp';

class Navigation extends React.Component {
  redirecting = false
  getOut = false
  redirectToEditProfile = false


  componentDidMount(){
    const { getSessionData, getSessionDataRS } = this.props
    if (getSessionDataRS !== LOADING) {        
      getSessionData()
    }
  }


  refresh(){
    this.setState({})
  }


  navigateUser() {
    const { oktaUser, clearOktaStatus } = this.props
    if (oktaUser) {
      clearOktaStatus()
      return <Navigate to="/oktaLogin" /> //navigate back to okta home page
    }
    return <Navigate to="/" />  //navigate back to home page
  }


  handleLogOut(){
    const { logOut, oktaUser, serverDomain } = this.props
    if (oktaUser){
      window.location.href = serverDomain + "/okta/logout" // logs out okta user
    } else{
      logOut() // logs out user
    }
  }


  render(){
    const { user, getSessionDataRS, logOutRS } = this.props
    let block = false
    let url = window.location.href
    let url_parts = url.replace(/\/\s*$/,'').split('/'); 
    let loc = url_parts[url_parts.length - 1]

    if (loc === "login" || loc === "" || loc === "okta" || loc === "adminConsole" || loc === "localhost:3000" || loc === "panorama.today" || [LOADING, INITIAL].includes(getSessionDataRS)
        || logOutRS === LOADING){
      return "" // if on home page basically
    } 
    
    else if (user === null || user.error === "cluster" || user.error === 'both' || user.error === 'okta' || user.error === 'username' || user.error === 'blocked' || user.error === 'password') {
      return this.navigateUser()  // navigate back to home page if error
    }

    let admin = user && user.is_admin
    return (
      <div style={classes(theme).white}>
        <BrowserView>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <Grid container direction="row"  alignItems="flex-end">
                <Grid item style={classes(theme).logoContainer}>
                  <Link style={classes(theme).sightPlanContainer} to="/" >
                    <img style={classes(theme).panorama} src={panorama} alt="panorama" />
                  </Link>
                  <img style={classes(theme).logo} src={logo} alt="logo" />
                </Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="flex-start">
                    {!block ?
                    <Grid container direction="row" alignItems="flex-end">
                      <NavLink
                        to="/home"
                        style={{...classes(theme).navTab, marginLeft:"3em"}}
                        activestyle={classes(theme).activeNavTab}
                        onClick={this.refresh.bind(this)}
                      >
                        <Typography style={loc === "home" ? {fontWeight: 600, color: theme.palette.secondary.main} : {}} > Reports </Typography>
                      </NavLink>
                      <Typography style={classes(theme).lines} hidden={!user.item_setup_access}> | </Typography>
                      <NavLink 
                        to="/setups" 
                        style={classes(theme).navTab}
                        activestyle={classes(theme).activeNavTab}
                        onClick={this.refresh.bind(this)}
                        hidden={!user.item_setup_access}
                      >
                          <Typography style={loc === "setups" ? {fontWeight: 600, color: theme.palette.secondary.main} : {}}> Setups </Typography>
                      </NavLink>
                      {admin ? 
                      <Typography style={classes(theme).lines}> | </Typography> : null}
                      <NavLink 
                        to="/users" 
                        style={classes(theme).navTab}
                        activestyle={classes(theme).activeNavTab}
                        onClick={this.refresh.bind(this)}
                        sx = {{display: user.is_admin === false ? 'none' : null}}
                        hidden = {!admin}
                      >
                        <Typography style={loc === "users" ? {fontWeight: 600, color: theme.palette.secondary.main} : {}}> Users </Typography>
                      </NavLink> 
                      {admin ? 
                      <Typography style={classes(theme).lines}> | </Typography> : null}
                                
                      <NavLink 
                        to="/manageClusters" 
                        style={classes(theme).navTab}
                        activestyle={classes(theme).activeNavTab}
                        onClick={this.refresh.bind(this)}
                        hidden = {!admin}
                      >
                        <Typography style={loc === "manageClusters" ? {fontWeight: 600, color: theme.palette.secondary.main} : {}} > Clusters </Typography>
                      </NavLink>
                    </Grid> : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
              
            <Grid item style={{paddingRight:"2em", paddingLeft:"2em", display: "flex", justifyContent:"top", alignItems:"top"}}>
              <div>
                <Popup
                  trigger={<Grid container direcion="row" alignItems="center" >  <AccountCircleIcon style={{margin: "0.5em"}}/> <Typography> {user.first_name} </Typography> &nbsp;</Grid>}
                  position="bottom right"
                  on="hover"
                  offsetY={-10}
                  offsetX={-4}
                  closeOnDocumentClick
                  mouseLeaveDelay={300}
                  mouseEnterDelay={0}
                  contentStyle={{ padding: "0px", border: "none" }}
                  arrow={false}
                >
                  <div style={classes(theme).menu}>
                    <div style={classes(theme).menuItems} onClick={this.handleLogOut.bind(this)} > 
                      <Typography > Log out </Typography> 
                    </div>
                  </div>
                </Popup>
              </div>
            </Grid>
          </Grid>
        </BrowserView>

        <MobileView>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <DrawerComp links={this.props}/>
            <div>
              <Popup
                trigger={<Grid container direcion="row" alignItems="center" >  <AccountCircleIcon style={{margin: "0.5em"}}/> <Typography> {user.first_name} </Typography> &nbsp; </Grid>}
                position="bottom right"
                on="hover"
                offsetY={-10}
                offsetX={-4}
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                contentStyle={{ padding: "0px", border: "none" }}
                arrow={false}
              >
                <div style={classes(theme).menu}>
                  <div style={classes(theme).menuItems} onClick={this.handleLogOut.bind(this)}> 
                    <Typography > Log out </Typography> 
                  </div>
                </div>
              </Popup>
            </div>
          </Grid>
        </MobileView>
      </div>
    )
  };
}

export default styled(Navigation)({theme})