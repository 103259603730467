import React from "react";
import theme from '../theme';
import classes from '../styles/login';
import { isEmpty } from '../general/utils';
import { Navigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { isMobile } from "react-device-detect";
import ergopakLogo from '../img/Ergopak-logo.png';
import panoramaLogo from '../img/panorama_bb_blue.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { inputLabelClasses } from "@mui/material/InputLabel";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FAILED, LOADING, SUCCEEDED } from '../general/requestStates';
import { Button, CircularProgress, Grid, Typography, TextField, IconButton } from '@mui/material';

class Login extends React.Component {

  state = {
    username: "",
    password: "",
    redirectToHome: false,
    showPassword: false,
  };

  componentDidMount() {
    const { getSessionData, logOutRS } = this.props;
    document.addEventListener("keydown", this.handleKeyDown);
    if (logOutRS !== LOADING) {
      getSessionData();
      console.log("This is runnign twice")
    }
  }

  componentDidUpdate(prevProps) {
    const { user, loginRS } = this.props;
    if (loginRS === SUCCEEDED && !isEmpty(user) && !this.state.redirectToHome) {
      this.setState({ redirectToHome: true });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (target) => {
    if (target.keyCode === 13) {
      this.handleLogIn();
    }
  }

  handleLogIn = () => {
    const { username, password } = this.state;
    this.props.login({ username, password });
  }

  handleLoginForm = (key, event) => {
    this.setState({ [key]: event.target.value });
  }

  loginWarningMessage = () => {
    const { user, loginRS, sessionExpired } = this.props;
    let message;
    const date = new Date().toISOString();
    const today = new Date();
    const url = `${window.location.href}/okta`;
    const link = <a href={url} style={classes(theme, isMobile).oktaWarning}>Okta Login</a>;

    if (loginRS === FAILED) {
      message = <Typography style={classes(theme, isMobile).warning} align="center">
        Can't connect to server. Please contact your administrator.</Typography>
    } else if (!isEmpty(user) && user.error === 'password' && loginRS === SUCCEEDED) {
      message = <Typography style={classes(theme, isMobile).warning}>
        Incorrect password. Please try again.</Typography>;
    } else if (!isEmpty(user) && user.error === 'blocked' && user.nr_login_attempts === 3 &&
      (user.last_login_attempt + 30000) !== today && loginRS === SUCCEEDED) {
      message = <Typography style={classes(theme, isMobile).warning}>
        You have been temporarily been locked from your account. Try again later</Typography>;
    } else if (!isEmpty(user) && user.error === 'username' && loginRS === SUCCEEDED) {
      message = <Typography style={classes(theme, isMobile).warning}>
        User does not exist. Please try again.</Typography>;
    } else if (!isEmpty(user) && user.error === 'okta' && loginRS === SUCCEEDED) {
      message = <Typography style={classes(theme, isMobile).warning}>
        This user is registered to an Okta account. Please redirect to the {link} page.
      </Typography>;
    } else if (!isEmpty(user) && user.error === 'both' && loginRS === SUCCEEDED) {
      message = <Typography style={classes(theme, isMobile).warning}>
        Incorrect username/password. Please try again.</Typography>;
    } else if (sessionExpired && isEmpty(user)) {
      message = <Typography style={classes(theme, isMobile).warning}>
        Your session has expired due to inactivity. Please log in again to continue.</Typography>
    } else if (!isEmpty(user) && user.error === "cluster" && loginRS === SUCCEEDED && isEmpty(user.scope)) {
      message = <Typography style={classes(theme, isMobile).warning}>
        Your account has not been assigned a cluster. Please contact your administrator.</Typography>
    }
    return message;
  }

  render() {
    const { user, loginRS } = this.props;
    const { showPassword, redirectToHome } = this.state;

    if (redirectToHome) {
      return <Navigate to="/home" replace={true} />;
    }

    const loginMessage = this.loginWarningMessage();

    return (
      <Grid container direction="column" style={classes(theme, isMobile).background}>
        <Grid container direction="row" style={classes(theme, isMobile).header}>
          <img style={classes(theme, isMobile).panoramaLogo} src={panoramaLogo} alt="panorama-logo" />
        </Grid>
        <div style={{ marginTop: "5%" }}>
          <Grid container direction="column" alignItems="center" justifyContent="center" style={classes(theme, isMobile).loginContainer}>
            <Grid container direction="row">
              <Typography style={classes(theme, isMobile).loginText}>
                Please log in with your email address and password
              </Typography>
            </Grid>

            <Grid container direction="row" alignItems="center" justifyContent="center">
              <TextField
                type="email"
                label="Email"
                variant="standard"
                value={this.state.username}
                inputProps={{ style: { color: "white" } }}
                style={classes(theme, isMobile).emailField}
                onChange={(e) => this.handleLoginForm("username", e)}
                InputLabelProps={{ sx: { color: "#FFFFFF", [`&.${inputLabelClasses.shrink}`]: { color: "#FFFFFF" } } }}
              />
            </Grid>

            <Grid container direction="row" alignItems="center" justifyContent="center">
              <TextField
                label="Password"
                variant="standard"
                value={this.state.password}
                inputProps={{ style: { color: "white" } }}
                type={showPassword ? "text" : "password"}
                style={classes(theme, isMobile).passwordField}
                onChange={(e) => this.handleLoginForm("password", e)}
                InputLabelProps={{ sx: { color: "#FFFFFF", [`&.${inputLabelClasses.shrink}`]: { color: "#FFFFFF" } } }}
              />
            </Grid>

            <IconButton
              style={classes(theme, isMobile).showPassword}
              color="primary"
              aria-label="toggle password visibility"
              onClick={() => this.setState({ showPassword: !showPassword })}
            >
              {showPassword ? <VisibilityOffIcon style={{ color: 'white' }} /> : <VisibilityIcon style={{ color: 'white' }} />}
            </IconButton>

            {loginMessage}

            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={this.handleLogIn}
                style={classes(theme, isMobile).loginButton}
              >
                {loginRS === LOADING ? <CircularProgress color="white" size="2em" /> : "Log In"}
              </Button>
            </Grid>

            <Grid container direction="row" alignItems="center" justifyContent="center">
              <img style={classes(theme, isMobile).ergopakLogo} src={ergopakLogo} alt="Ergopak-Logo" />
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  }
}

export default styled(Login)({ theme });
