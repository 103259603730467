import {connect} from 'react-redux'
import Layout from '../pages/manageClusters';
import { getFilterOptions, getClusters } from "../reducers/clusterManagement"
import { submitData, deleteData } from "../reducers/data"


const mapStateToProps = state => ({             //selecting the part of the data from the store that the connected component needs
    user: state.userManagement.user,
    oktaUser: state.userManagement.oktaUser,
    clientOptions: state.clusterManagement.clientOptions,
    countryOptions: state.clusterManagement.countryOptions,
    siteOptions: state.clusterManagement.siteOptions,
    buildingOptions: state.clusterManagement.buildingOptions,
    computerOptions: state.clusterManagement.computerOptions,
    clusters: state.clusterManagement.clusters,
    getClustersRS: state.clusterManagement.getClustersRS,
    latestSubmission: state.data.latestSubmission,
    submitDataRS: state.data.submitDataRS,
    deleteDataRS: state.data.deleteDataRS,
})


const mapDispatchToProps = dispatch => ({
    getFilterOptions: (selections) => dispatch(getFilterOptions(selections)),
    getClusters: () => dispatch(getClusters()),
    submitData: (database, table, data) => dispatch(submitData(database, table, data)),
    deleteData: (database, table, data) => dispatch(deleteData(database, table, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
