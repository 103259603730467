import commonstyles from "../common";
import { isMobile } from "react-device-detect";

const styles = (theme, isMobile) => (isMobile ? mobile(theme) : browser(theme))

const browser = (theme) => ({
  mainCard: commonstyles(theme).mainCard,
  warning: commonstyles(theme).warning,
  filterField: { ...commonstyles(theme, isMobile).textField, width: theme.spacing(22) },
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#FFFFFF"
    },
    "& .MuiInputLabel-outlined": {
      color: "#FFFFFF"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#FFFFFF"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#FFFFFF"
    }
  },
  tips: {
    color: "blue",
    fontWeight: 600,
  },
  button: {
    margin: theme.spacing(1.65),
    width: "10em",
    marginRight: "1.8em",
    marginTop: "2em",
  },
  inputField: {
    marginTop: "1%",
    marginLeft: "3%",
    width: "25em",
  },
  clusterInputField: {
    marginTop: "1%",
    marginLeft: "3%",
    width: "15em",
  },
  inputFieldRight: {
    marginTop: "-3.5em",
    marginLeft: "22em",
    width: "53.5%",
  },
  passwordInputField: {
    marginTop: "5%",
    marginLeft: "3%",
    width: "25em",
  },
  warningText: {
    marginTop: "0.2em",
    color: "red",
    fontSize: "11px",
  },
  small: {
    margin: 0,
    width: "8em",
  },
  bullet2: {
    marginTop: "0.55em",
    width: "7.5em",
    color: "white"
  },
  bullet: {
    marginTop: "0.55em",
    width: "5.7em",
    color: "white"
    
  },
  bullet2: {
    marginTop: "0.55em",
    width: "7.6em",
    color: "white"
    
  },
  bulletBox: {
    // marginLeft: "3.5%",
    padding: "1em",
    borderStyle: "solid",
    marginTop: "1.5em",
    borderWidth: "1px",
    borderRadius: "0.3em",
    borderColor: "white",
    width: theme.spacing(26),
  },
  clusterBox: {
    marginLeft: "3%",
    padding: "1em",
    borderStyle: "solid",
    marginTop: "1em",
    borderWidth: "1px",
    borderRadius: "0.3em",
    width: "30em",
    borderColor: theme.palette.info.main,
  },
})

const mobile = (theme) => ({
  ...browser(theme),
  mainCard: commonstyles(theme).mainCard,
  filterField: { margin: theme.spacing(1), width: '245%'},
  usernameField: { margin: theme.spacing(1), width: theme.spacing(20), marginTop: "-0.1em" },
  bullet2: {
    marginTop: "0.55em",
    width: "5.6em",
    color: "white"
    
  },
  bulletBox: {
    // marginLeft: "3.5%",
    padding: "1em",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "0.3em",
    borderColor: theme.palette.info.main,
  },
  inputField: {
    marginTop: "1%",
    marginLeft: "3%",
    width: "80%",
  },

})

// const styles = (theme) => ({
//   mainCard: commonstyles(theme).mainCard,
//   warning: commonstyles(theme).warning,
//   tips: {
//     color: "blue",
//     fontWeight: 600,
//   },
//   button: {
//     margin: theme.spacing(2.5),
//     width: "10em",
//     marginRight: "2em",
//     marginTop: "2em",
//   },
//   inputField: {
//     marginTop: "1%",
//     marginLeft: "3%",
//     width: "25em",
//   },
//   clusterInputField: {
//     marginTop: "1%",
//     marginLeft: "3%",
//     width: "15em",
//   },
//   inputFieldRight: {
//     marginTop: "-3.5em",
//     marginLeft: "22em",
//     width: "53.5%",
//   },
//   passwordInputField: {
//     marginTop: "5%",
//     marginLeft: "3%",
//     width: "25em",
//   },
//   warningText: {
//     marginTop: "0.2em",
//     color: "red",
//     fontSize: "11px",
//   },
//   small: {
//     margin: 0,
//     width: "8em",
//   },
//   bullet: {
//     marginTop: "0.55em",
//     width: "8.6em",
//   },
//   bulletBox: {
//     marginLeft: "3%",
//     padding: "1em",
//     borderStyle: "solid",
//     marginTop: "1.5em",
//     borderWidth: "1px",
//     borderRadius: "0.3em",
//     borderColor: theme.palette.info.main,
//   },
//   clusterBox: {
//     marginLeft: "3%",
//     padding: "1em",
//     borderStyle: "solid",
//     marginTop: "1em",
//     borderWidth: "1px",
//     borderRadius: "0.3em",
//     width: "30em",
//     borderColor: theme.palette.info.main,
//   },
// });

export default styles;
