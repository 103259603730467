import commonstyles from "../common"
import { isMobile } from "react-device-detect";

const styles = (theme, isMobile) => (isMobile ? mobile(theme) : browser(theme))

const browser = theme => ({ 
    centerDivs: commonstyles(theme).centerDivs,
    label: commonstyles(theme).label,
    lines: commonstyles(theme).lines,
    white: commonstyles(theme).white,
    logo: {
        ...commonstyles(theme).logo,
        maxHeight: "1.5em",
    },
    panorama: {
        ...commonstyles(theme).logo,
        maxHeight: "2.5em",
        marginBottom: theme.spacing(-0.5)
    },
    logoContainer: {
        margin: theme.spacing(1)
    },
    sightPlanContainer: {
        textDecoration: 'none',
        color: "#7d7d7d",
    },
    sightPlan: {
        fontSize: "2em",
        marginBottom: "-0.3em",
        color: theme.palette.primary.main,
    },
    activeNavTab: {
        // padding: "0.5em", 
        // paddingTop: "0.1em", 
        color: theme.palette.secondary.main, 
        fontWeight: 900,
        margin: theme.spacing(1),
        textDecoration: 'none',
    },
    navTab: {
        // padding: "0.5em", 
        // paddingTop: "0.1em", 
        color: theme.palette.primary.main,
        // margin: theme.spacing(1),
        margin: theme.spacing(1),
        textDecoration: 'none',
    },
    title: {
        fontWeight: "600",
        color: theme.palette.primary.main,
    },    
    icon: {
        maxHeight: '5em',
    },
    loginButton: {
        ...commonstyles(theme).button,
        width: "10em"
    },
    menu: {
        backgroundColor: "white",
        textAlign: "left",
        borderRadius: "0.3em",
        borderColor: "grey",
        borderStyle: "solid",
        borderWidth: 2,
        padding: theme.spacing(0.5)
    },
    menuItems: {
        textDecoration: 'none',
        cursor: "pointer",
        textAlign: "left",
        
    }
    
})

const mobile = (theme) => ({})

export default styles
