import commonstyles from "./common";

const styles = (theme, isMobile) => (isMobile ? mobile(theme) : browser(theme))

const browser = (theme) => ({
  mainCard: commonstyles(theme).mainCard,
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF"
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#FFFFFF"
    },
    "& .MuiInputLabel-outlined": {
      color: "#FFFFFF"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#FFFFFF"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#FFFFFF"
    }
  },
  button: {
    margin: theme.spacing(2.3),
    width: "10em",
    marginRight: "1em",
    marginTop: "3%",
  },
  reportContainer: {
    margin: "0.9%",
    padding: theme.spacing(2),
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderRadius: "2em",
    backgroundColor: "#323232",
    maxWidth: "97.5%",
    maxheight: "97.5%",
    minWidth: "97.5%",
    minheight: "97.5%",
  },
  usersInputField: {
    marginTop: "5%",
    marginLeft: "3%",
    width: "80%",
  },
  inputField: {
    marginTop: "1%",
    marginLeft: "3%",
    width: "20em",
  },
  userModifyInputField: {
    margin: theme.spacing(1),
    width: "20em",
  },
  clusterInputField: {
    marginTop: "1%",
    marginLeft: "3%",
    width: "15em",
  },
  inputFieldRight: {
    marginTop: "-3.5em",
    marginLeft: "22em",
    width: "53.5%",
  },
  passwordInputField: {
    marginTop: "5%",
    marginLeft: "3%",
    width: "25em",
  },
  warningText: {
    marginTop: "0.2em",
    color: "red",
    fontSize: "11px",
  },
  small: {
    padding: "0.5em",
    margin: 0,
    marginLeft: "0.5em",
    marginRight: "1em",
    width: "10em",
  },
  bullet: {
    padding: 0,
    margin: 0,
    marginRight: "1%",
  },
  bulletBox: {
    marginLeft: "3%",
    padding: "0.5em",
    borderStyle: "solid",
    marginTop: "1.5em",
    borderWidth: "1px",
    borderRadius: "0.3em",
    borderColor: theme.palette.info.main,
  },
  clusterBox: {
    marginLeft: "3%",
    padding: "1em",
    borderStyle: "solid",
    marginTop: "1em",
    borderWidth: "1px",
    borderRadius: "0.3em",
    width: "30em",
    borderColor: theme.palette.info.main,
  },
  setupTable: {
    margin: theme.spacing(1),
    height: 284,
    width: "94%",
    color: "white"
  },
  addItem: {
    margin: theme.spacing(1),
    color: '#059df5',
    borderColor:'#059df5'
  },
})

const mobile = (theme) => ({
  ...browser(theme),
  userModifyInputField: {
    margin: theme.spacing(1),
    width: "100%",
  },

})

// const styles = (theme) => ({
//   mainCard: commonstyles(theme).mainCard,
//   button: {
//     margin: theme.spacing(2.3),
//     width: "10em",
//     marginRight: "1em",
//     marginTop: "3%",
//   },
//   reportContainer: {
//     margin: "0.9%",
//     padding: theme.spacing(2),
//     borderColor: theme.palette.primary.main,
//     borderStyle: "solid",
//     borderRadius: "2em",
//     backgroundColor: "white",
//     maxWidth: "97.5%",
//     maxheight: "97.5%",
//     minWidth: "97.5%",
//     minheight: "97.5%",
//   },
//   usersInputField: {
//     marginTop: "5%",
//     marginLeft: "3%",
//     width: "80%",
//   },
//   inputField: {
//     marginTop: "1%",
//     marginLeft: "3%",
//     width: "20em",
//   },
//   userModifyInputField: {
//     margin: theme.spacing(1),
//     width: "20em",
//   },
//   clusterInputField: {
//     marginTop: "1%",
//     marginLeft: "3%",
//     width: "15em",
//   },
//   inputFieldRight: {
//     marginTop: "-3.5em",
//     marginLeft: "22em",
//     width: "53.5%",
//   },
//   passwordInputField: {
//     marginTop: "5%",
//     marginLeft: "3%",
//     width: "25em",
//   },
//   warningText: {
//     marginTop: "0.2em",
//     color: "red",
//     fontSize: "11px",
//   },
//   small: {
//     padding: "0.5em",
//     margin: 0,
//     marginLeft: "0.5em",
//     marginRight: "1em",
//     width: "10em",
//   },
//   bullet: {
//     padding: 0,
//     margin: 0,
//     marginRight: "1%",
//   },
//   bulletBox: {
//     marginLeft: "3%",
//     padding: "0.5em",
//     borderStyle: "solid",
//     marginTop: "1.5em",
//     borderWidth: "1px",
//     borderRadius: "0.3em",
//     borderColor: theme.palette.info.main,
//   },
//   clusterBox: {
//     marginLeft: "3%",
//     padding: "1em",
//     borderStyle: "solid",
//     marginTop: "1em",
//     borderWidth: "1px",
//     borderRadius: "0.3em",
//     width: "30em",
//     borderColor: theme.palette.info.main,
//   },
//   setupTable: {
//     margin: theme.spacing(1),
//     height: 284,
//     width: "94%",
//   },
//   addItem: {
//     margin: theme.spacing(1),
//   },
// });

export default styles;
