const styles = (theme, isMobile) => (isMobile ? mobile(theme) : browser(theme))

const browser = (theme) => ({
  button: {
    // color: theme.palette.primary.main,
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
    width: theme.spacing(18),
  },
  textFieldMobile: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    // width: theme.spacing(12),
  },
  longField: {
    margin: theme.spacing(1),
    width: theme.spacing(26),
  },
  longFieldMobile: {
    margin: theme.spacing(1),
    width: "90%",
  },
  tips: {
    margin: theme.spacing(1),
    color: "white",
    // fontWeight: 600,
  },
  centerDivs: {
    textAlign: "center",
  },
  leftDivs: {
    textAlign: "left",
  },
  welcomeDiv: {
    backgroundColor: theme.palette.primary.main,
    height: "10em" 
  },
  headerDiv: {},
  logo: {
    display: "block",
    maxHeight: "2.5em",
  },
  lines:{
    margin: theme.spacing(1),
    color: "black",
  },
  label: {
    margin: theme.spacing(1),
    color: "white",
  },
  white: {
    backgroundColor: "white",
  },
  mainCard: {
    backgroundColor: "#323232",
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    // maxWidth: "80rem"
  },
  warning: {
    color: "red",
    margin: theme.spacing(1),
    marginTop: theme.spacing(-0.2),
    fontSize: theme.spacing(0.8),
  },
  svg: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    backgroundColor: "black",
    padding: theme.spacing(1),
    background: "#eee",
    width: "40em",
    height: "30em",
  },
  whiteText: {
    color: "white",
  },
  heading: {
    fontSize: "2em",
    fontFamily: "Segoe UI",
    // margin: theme.spacing(0.1),
    color: "white",
    // color: theme.palette.primary.main,
    // padding: theme.spacing(0.5),
    textAlign: "center",
    borderRadius: "0.5em",
    // backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(-0.5),
    marginRight: theme.spacing(0)
  },
})

const mobile = (theme) => ({
  ...browser(theme),
  textField: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    // width: theme.spacing(12),
  },
  longField: {
    margin: theme.spacing(1),
    width: "90%",
  },
})

// const styles = (theme) => ({
//   button: {
//     // color: theme.palette.primary.main,
//     margin: theme.spacing(1),
//   },
//   textField: {
//     margin: theme.spacing(1),
//     width: theme.spacing(18),
//   },
//   textFieldMobile: {
//     marginBottom: theme.spacing(1),
//     marginTop: theme.spacing(1),
//     // width: theme.spacing(12),
//   },
//   longField: {
//     margin: theme.spacing(1),
//     width: theme.spacing(26),
//   },
//   longFieldMobile: {
//     margin: theme.spacing(1),
//     width: "90%",
//   },
//   tips: {
//     margin: theme.spacing(1),
//     color: "blue",
//     // fontWeight: 600,
//   },
//   centerDivs: {
//     textAlign: "center",
//   },
//   leftDivs: {
//     textAlign: "left",
//   },
//   welcomeDiv: {
//     backgroundColor: theme.palette.primary.main,
//   },
//   headerDiv: {},
//   logo: {
//     display: "block",
//     maxHeight: "2.5em",
//   },
//   label: {
//     margin: theme.spacing(1),
//   },
//   white: {
//     backgroundColor: "white",
//   },
//   mainCard: {
//     backgroundColor: "white",
//     margin: theme.spacing(1),
//     borderRadius: theme.spacing(1),
//     padding: theme.spacing(1),
//   },
//   warning: {
//     color: "red",
//     margin: theme.spacing(1),
//     marginTop: theme.spacing(-0.2),
//     fontSize: theme.spacing(0.8),
//   },
//   svg: {
//     margin: theme.spacing(1),
//     marginTop: theme.spacing(3),
//     backgroundColor: "black",
//     padding: theme.spacing(1),
//     background: "#eee",
//     width: "40em",
//     height: "30em",
//   },
//   whiteText: {
//     color: "white",
//   },
//   heading: {
//     fontSize: "2em",
//     fontFamily: "Segoe UI",
//     // margin: theme.spacing(0.1),
//     // color: "white",
//     color: theme.palette.primary.main,
//     // padding: theme.spacing(0.5),
//     textAlign: "center",
//     borderRadius: "0.5em",
//     // backgroundColor: theme.palette.primary.main,
//     marginBottom: theme.spacing(0.5),
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1)
//   },
// });

export default styles;
