import React from "react";
import theme from '../theme';
import classes from '../styles/oktaLogin';
import logo from '../img/Ergopak-logo.png';
import oktaLogo from '../img/okta_logo.png';
import { Navigate } from 'react-router-dom';
import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { isMobile } from "react-device-detect";
import { isEmpty, debug } from '../general/utils';
import panoramaLogo from '../img/panorama_bb_blue.png';
import oktaLoginLogo from '../img/Okta-Logo.png';
import { loginWarningMessage } from '../styles/login';
import { inputLabelClasses } from "@mui/material/InputLabel";
import { LOADING, SUCCEEDED, FAILED } from '../general/requestStates';
import { CircularProgress, Typography, Button, FormControlLabel, Checkbox, TextField, Grid, Paper } from '@mui/material';

class OktaLogin extends React.Component {

  state = {
    username: "",
  };


  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    console.log("Mounted")
  };


  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  };


  handleOktaLogIn() {
    if (window.location.href === "http://localhost:3000/login/okta") {
      window.location.href = "http://localhost:5000/okta/login"
    }
    else if (window.location.href === "https://panorama.today/login/okta") {
      window.location.href = "https://panorama.today/okta/login"
    }
    // TODO change this to make it work in all cases
    // Changing the link on frontend dev won't be allowed by Okta
     // If ever you need this again, there should be the server domain in usermanagement
  };


  handleKeyDown = (target) => {
    if (target.keyCode === 13) {
      this.handleOktaLogIn();
    };
  };


  handleLoginForm(key, event) {
    let current = { ...this.state };
    current[key] = event.target.value;
    this.setState({ ...current });
  };


  loginWarningMessage(){
    const { user, loginRS, sessionExpired } = this.props;
   
    let message

    if (loginRS === FAILED){
      message = <Typography style={classes(theme, isMobile).warning} align="center">
                  Can't connect to server. Please contact your administrator.</Typography>
    }
    else if (!isEmpty(user) && user.error === 'username' && loginRS === SUCCEEDED){
      message = <Typography style={classes(theme, isMobile).warning}>
                  User does not exist. Please try again.</Typography>;
    }
    else if (sessionExpired){
      message = <Typography style={classes(theme, isMobile).warning}>
                  Your session has expired due to inactivity. Please log in again to continue.</Typography>
    }
    else if (!isEmpty(user) && user.error === "" && loginRS === SUCCEEDED && isEmpty(user.scope)){
      message = <Typography style={classes(theme, isMobile).warning}>
                  Your account has not been assigned a cluster. Please contact your administrator.</Typography> 
    }
    return message
  }
  

  render() {
    const { user, loginRS } = this.props;

    let loginMessage = this.loginWarningMessage();
    let redirect = isEmpty(user) || !user.scope ? "" : <Navigate to="/home" replace={true}/>
    return(
      <Grid container direction="column" style={classes(theme, isMobile).background}>
        <Grid container direction="row" style={classes(theme, isMobile).header}>
          <img style={classes(theme, isMobile).panoramaLogo} src={panoramaLogo} alt="panorama-logo" />
        </Grid>

        <Grid container direction="column" alignItems="center" justifyContent="center" style={classes(theme, isMobile).loginContainer}>
          <Grid container direction="row" >
            <Typography style={classes(theme, isMobile).loginText}>
            Please enter your email address to log in with Okta
            </Typography>
          </Grid>

          <Grid container direction="row" alignItems="center" justifyContent="center">
            <TextField
              type="email"
              label="Email"
              variant="standard"
              value={this.state.username}
              inputProps={{style: {color: "white"}}}
              style={classes(theme, isMobile).emailField}
              onChange={this.handleLoginForm.bind(this, "username")}
              InputLabelProps={{sx: {color: "#FFFFFF", [`&.${inputLabelClasses.shrink}`]:{color: "#FFFFFF"}}}}
            />
          </Grid>

          {loginMessage}

          <Grid container direction="row" alignItems="center" justifyContent="center">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={this.handleOktaLogIn.bind(this)}
              style={classes(theme, isMobile).loginButton}
            >   
            {loginRS === LOADING ? <CircularProgress color="white" size="2em" /> : "Log In"}
            </Button>
          </Grid>
          {loginMessage}
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <img style={classes(theme, isMobile).oktaLogo} src={oktaLoginLogo} alt="Ojta-Logo" />
          </Grid>
        </Grid>
        {redirect}
      </Grid>
    );
  }
}

export default styled(OktaLogin)({ theme });
