import {connect} from 'react-redux';
import Layout from '../reportSetup';
import { getData, getOrders, getProducts, getLines } from "../../reducers/data";
import { getPandas, getReportDataFile, getReportData, setFilterSelections, setLatestReportFilters, refreshData, setPdfFilters } from "../../reducers/reports";
import { getFilterOptions } from "../../reducers/clusterManagement";
import { login,refreshUserScope } from "../../reducers/userManagement";


const mapStateToProps = state => ({          
  user: state.userManagement.user,
  getSessionDataRS: state.userManagement.getSessionDataRS,
  siteOptions: state.clusterManagement.siteOptions,
  countryOptions: state.clusterManagement.countryOptions,
  buildingOptions: state.clusterManagement.buildingOptions,
  computerOptions: state.clusterManagement.computerOptions,
  lines: state.data.latestQuery,
  orders: state.data.orders,
  products: state.data.products,
  getProductsRS: state.data.getProductsRS,
  refreshUserScopeRS: state.userManagement.refreshUserScopeRS,
  clusterLines: state.data.clusterLines,
  overviewOrders: state.data.overviewOrders,
  reportData: state.reports.reportData,
  pandas : state.reports.pandas,
  getPandasRS: state.reports.getPandasRS,
  getReportDataFileRS: state.reports.getReportDataFileRS,  
  getOrdersRS: state.data.getOrdersRS,
  getLinesRS: state.data.getLinesRS,
  filterSelections: state.reports.filterSelections,
  latestRequestId: state.reports.latestRequestId,
  latestReportFilters: state.reports.latestReportFilters,
  refreshDataRS: state.reports.refreshDataRS,
  refreshData: state.reports.refreshData,
  pdfFilters: state.reports.pdfFilters

})


const mapDispatchToProps = dispatch => ({
  login: (username,password, mode) => dispatch(login(username,password, mode)),
  refreshUserScope: (payload) => dispatch(refreshUserScope(payload)),
  getLines: () => dispatch(getLines()),
  getFilterOptions: (selections) => dispatch(getFilterOptions(selections)),
  getPandas: (payload) => dispatch(getPandas(payload)),
  getReportDataFile: (payload) => dispatch(getReportDataFile(payload)),
  getReportData:(payload) => dispatch(getReportData(payload)),
  getOrders: () => dispatch(getOrders()),
  getProducts: () => dispatch(getProducts()),
  setPdfFilters: (payload) => dispatch(setPdfFilters(payload)),
  setFilterSelections: (selections) => dispatch(setFilterSelections(selections)),
  setLatestReportFilters: (selections) => dispatch(setLatestReportFilters(selections)),
  refreshData: (reportName) => dispatch(refreshData(reportName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)