import {connect} from 'react-redux'
import Layout from '../pages/setups';
import { setLine } from "../reducers/setups";
import { getSetups, setComputerToAddTo } from '../reducers/setupManagement';


const mapStateToProps = state => ({
    setupData: state.setupManagement.setupData,
    getSetupsRS:state.setupManagement.getSetupsRS // Not currently used
})


const mapDispatchToProps = dispatch => ({
    setLine: (line) => dispatch(setLine(line)), // Also don't think we need this
    getSetups: () => dispatch(getSetups()),
    setComputerToAddTo: (details) => dispatch(setComputerToAddTo(details)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
