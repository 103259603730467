import React from "react";
import { Navigate } from "react-router-dom";
import theme from "../theme";
import { blue } from '@mui/material/colors';
import { styled } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import classes from "../styles/components/modUser";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { generateUUID, debug, isEmpty } from "../general/utils";
import { FAILED, LOADING, SUCCEEDED } from "../general/requestStates";
import { clone, passwordFeedback, emailWarning, validateEmail, checkPassword, comparePassword } from "../general/utils";
import { FormControl, FormControlLabel, Typography, Button, TextField, Grid, Radio, Autocomplete, RadioGroup, CircularProgress, Checkbox } from "@mui/material";

class users extends React.Component {

  state = {
    initial: null,
    initialText: "",
    usernames: [],
    clusters: [],
    reports: [],
    selectedReports: [],
    selectAllReports: false,
    showPassword: false,
    showConfirmPassword: false,
    current: {
      item_setup_access: false,
      chosenUser: null,
      chosenCluster: null,
      sid: null,
      username: "",
      first_name: "",
      last_name: "",
      password_hash: "",
      confirmPassword: "",
      clusterName: null,
      cluster_sid: null,
      is_admin: false,
      user_active: true,
      report_active_threshold: 1,
      api_key: null,
      okta_user: "normal",
      overview_dashboard_access: false,
      order_and_line_status_access: false,
      station_and_operator_performance_access: false,
      station_and_operator_packing_speed_access: false,
      station_and_operator_giveaway_access: false,
      station_and_operator_waste_access: false,
      line_productivity_access: false,
      per_product_productivity_access: false,
      calibration_report_access: false,
    },
  };


  componentDidMount() {
    const { getAllUsernames, getClusterNames, getUserData, getReportOptions } = this.props;
    getAllUsernames();
    getClusterNames();
    getReportOptions();
  }


  componentDidUpdate(prevProps) {
    const { usernames, getAllUsernamesRS, getUserDataRS, userData, clusterNames, submitDataRS, latestSubmission, justData } = this.props;
    const {current} = this.state
    if (getAllUsernamesRS === SUCCEEDED && prevProps.getAllUsernamesRS === LOADING){
      this.setState({ usernames })} 
    
    else if (submitDataRS === SUCCEEDED && prevProps.submitDataRS === LOADING){
      window.location.reload(false);
      // let replacedUser = this.state.usernames.findIndex((e) => e.sid === latestSubmission.sid);
      // let new_users;
      // if (replacedUser !== -1) {
      //   new_users = clone(this.state.usernames);
      //   new_users[replacedUser] = latestSubmission;
      // } else {
      //   new_users = clone(this.state.usernames);
      //   new_users.push(latestSubmission);
      // }

      // this.setState({ usernames: new_users});
    }
  }


  renderRedirect = () => {
    const { user } = this.props;
    let _ = require("underscore");
    if (!_.isEmpty(user) || !user.is_admin) {
      return <Navigate to={"/"} />;
    };
  };


  handleUserSelect(event, value) {
    const {usernames, clusterNames} = this.props
    const {current, selectedReports} = this.state
    let selectedReportOptions = []
    let selectedAllReportOptions = false
    if (!isEmpty(value)) {
      current.okta_user = value.okta_user
      current.sid = value.sid
      current.username = value.username
      current.first_name = value.first_name
      current.last_name = value.last_name
      let clusterIndex = clusterNames.findIndex((e) => e.sid === value.cluster_sid)
      if (clusterIndex === -1){
        current.cluster_sid = null
        current.chosenCluster = null
      }
      else{
        current.cluster_sid = value.cluster_sid
        current.chosenCluster = clusterNames.filter(e => e.sid === value.cluster_sid)[0]
      }
      current.item_setup_access = value.item_setup_access
      current.user_active = value.user_active
      current.report_active_threshold = value.report_active_threshold
      current.is_admin = value.is_admin
      if (value.overview_dashboard_access === true){selectedReportOptions.push({sid: 1, name: 'Overview Dashboard'})}
      if (value.order_and_line_status_access === true){selectedReportOptions.push({sid: 2, name: 'Order and Line Status'})}
      if (value.station_and_packing_distribution_access === true){selectedReportOptions.push({sid: 3, name: 'Station and Operator Packing Distribution'})}
      if (value.station_and_operator_packing_speed_access === true){selectedReportOptions.push({sid: 4, name: 'Station and Operator Packing Speed'})}
      if (value.station_and_operator_giveaway_access === true){selectedReportOptions.push({sid: 5, name: 'Station and Operator Giveaway'})}
      if (value.station_and_operator_waste_access === true){selectedReportOptions.push({sid: 9, name: 'Station and Operator Waste'})}
      if (value.station_and_operator_performance_access === true){selectedReportOptions.push({sid: 10, name: 'Station and Operator Performance'})}
      if (value.line_productivity_access === true){selectedReportOptions.push({sid: 6, name: 'Line Productivity'})}
      if (value.per_product_productivity_access === true){selectedReportOptions.push({sid: 7, name: 'Per Product Productivity'})}
      if (value.calibration_report_access === true){selectedReportOptions.push({sid: 8, name: 'Calibration Report'})} 
      if (selectedReportOptions.length === 8){selectedAllReportOptions = true}   
    }
    else {
      current.okta_user = "normal"
      current.sid = null
      current.username = ""
      current.first_name = ""
      current.last_name = ""
      current.chosenCluster = null
      current.item_setup_access = false
    }
    current.chosenUser = value
    this.setState({current, selectedReports: selectedReportOptions, selectAllReports: selectedAllReportOptions})
  }

  handleUsernameChange(event) {
    const { current } = this.state;
    current.username = event.target.value;
    this.setState({current});
  } 

  handleFirstNameChange(event) {
    const { current } = this.state;
    current.first_name = event.target.value;
    this.setState({current});
  }

  handleLastNameChange(event) {
    const { current } = this.state;
    current.last_name = event.target.value;
    this.setState({current});
  }

  handlePasswordChange(event) {
    const { current } = this.state;
    current.password_hash = event.target.value;
    this.setState({current});
  }

  handleThresholdChange(event) {
    const { current } = this.state;
    current.report_active_threshold = event.target.value;
    this.setState({current});
  }

  handleConfirmPasswordChange(event) {
    const { current } = this.state;
    current.confirmPassword = event.target.value;
    this.setState({current});
  }


  handleClusterSelect(key, value) {
    const { current } = this.state;
    if (value) {
      current.clusterName = value.name
      current.cluster_sid = value.sid
    }
    else{
      current.clusterName = null
      current.cluster_sid = null
    }
    current.chosenCluster = value    
    this.setState({current});
    
  }

  handleReportSelect(key, value) {
    this.setState({selectedReports:value}); 
  }

  handleSelectAllReports(e){
    const {reportOptions} = this.props
    let value = e.target.checked
    let selected = []
    if (value === true) {
      selected = reportOptions
    }
    else if (value === false) {
      selected = []
    }
    this.setState({selectAllReports: e.target.checked, selectedReports: selected})
  }


  handleSave() {
    const { submitUserData, updateUserData, usernames, submitData } = this.props;
    const { current, selectedReports } = this.state;
    let sid = current.sid
    let okta_user = current.okta_user
    let username = current.username
    let first_name = current.first_name
    let last_name = current.last_name
    let password_hash = current.password_hash
    let cluster_sid = current.cluster_sid
    let is_admin = current.is_admin 
    let user_active = current.user_active
    let report_active_threshold = current.report_active_threshold
    let item_setup_access = current.item_setup_access
    let api_key = current.api_key
    let overview_dashboard_access = current.overview_dashboard_access
    let order_and_line_status_access= current.order_and_line_status_access
    let station_and_operator_performance_access= current.station_and_operator_performance_access
    let station_and_operator_packing_speed_access= current.station_and_operator_packing_speed_access
    let station_and_operator_giveaway_access= current.station_and_operator_giveaway_access
    let station_and_operator_waste_access = current.station_and_operator_waste_access
    let line_productivity_access= current.overview_line_productivity_accessdashboard_access
    let per_product_productivity_access= current.per_product_productivity_access
    let calibration_report_access= current.calibration_report_access
    let data = {}

    selectedReports.some((e) => e.sid === 1) ? overview_dashboard_access = true : overview_dashboard_access = false
    selectedReports.some((e) => e.sid === 2) ? order_and_line_status_access = true : order_and_line_status_access = false
    selectedReports.some((e) => e.sid === 3) ? station_and_operator_performance_access = true : station_and_operator_performance_access = false
    selectedReports.some((e) => e.sid === 4) ? station_and_operator_packing_speed_access = true : station_and_operator_packing_speed_access = false
    selectedReports.some((e) => e.sid === 5) ? station_and_operator_giveaway_access = true : station_and_operator_giveaway_access = false
    selectedReports.some((e) => e.sid === 9) ? station_and_operator_waste_access = true : station_and_operator_waste_access = false
    selectedReports.some((e) => e.sid === 6) ? line_productivity_access = true : line_productivity_access = false
    selectedReports.some((e) => e.sid === 7) ? per_product_productivity_access = true : per_product_productivity_access = false
    selectedReports.some((e) => e.sid === 8) ? calibration_report_access = true : calibration_report_access = false

    if (current.sid){
      let thisUsersData = usernames.filter(e => e.sid === current.sid)[0]
      console.log(thisUsersData)
      if (
        username !== thisUsersData.username || 
        first_name !== thisUsersData.first_name || 
        last_name !== thisUsersData.last_name ||
        cluster_sid !== thisUsersData.cluster_sid || 
        is_admin !== thisUsersData.is_admin || 
        user_active !== thisUsersData.user_active || 
        report_active_threshold !== thisUsersData.report_active_threshold || 
        item_setup_access !== thisUsersData.item_setup_access || 
        current.okta_user !== thisUsersData.okta_user || 
        overview_dashboard_access !== thisUsersData.overview_dashboard_access || 
        order_and_line_status_access !== thisUsersData.order_and_line_status_access ||
        station_and_operator_performance_access !== thisUsersData.station_and_operator_performance_access || 
        station_and_operator_packing_speed_access !== thisUsersData.station_and_operator_packing_speed_access ||
        station_and_operator_giveaway_access !== thisUsersData.station_and_operator_giveaway_access || 
        station_and_operator_waste_access !== thisUsersData.station_and_operator_waste_access || 
        line_productivity_access !== thisUsersData.line_productivity_access ||
        per_product_productivity_access !== thisUsersData.per_product_productivity_access || 
        calibration_report_access !== thisUsersData.calibration_report_access){
          if (!current.password_hash && !current.confirmPassword){
            data = {sid, username, first_name, last_name, cluster_sid, is_admin, user_active, report_active_threshold, item_setup_access, okta_user, overview_dashboard_access,
              order_and_line_status_access, station_and_operator_performance_access, station_and_operator_packing_speed_access, station_and_operator_giveaway_access, station_and_operator_waste_access,
              line_productivity_access, per_product_productivity_access, calibration_report_access}
            submitData({ database: "ergopak_applications", table: "user", record: data });
          }
          else {
            data = {sid, username, first_name, last_name, password_hash, cluster_sid, is_admin, user_active, report_active_threshold, okta_user, item_setup_access, overview_dashboard_access,
              order_and_line_status_access, station_and_operator_performance_access, station_and_operator_packing_speed_access, station_and_operator_giveaway_access, station_and_operator_waste_access,
              line_productivity_access, per_product_productivity_access, calibration_report_access}
            submitData({ database: "ergopak_applications", table: "user", record: data });
          }  
      }
    }
    else if (current.okta_user === "okta" && !current.sid){
      api_key = generateUUID();
      data = {username, cluster_sid, is_admin, user_active, report_active_threshold, api_key, item_setup_access, okta_user, overview_dashboard_access,
        order_and_line_status_access, station_and_operator_performance_access, station_and_operator_packing_speed_access, station_and_operator_giveaway_access, station_and_operator_waste_access,
        line_productivity_access, per_product_productivity_access, calibration_report_access}
      submitData({ database: "ergopak_applications", table: "user", record: data });
    }
    else {
      api_key = generateUUID();
      data = {username:username, first_name:first_name, last_name:last_name, password_hash:password_hash, cluster_sid:cluster_sid, is_admin:is_admin, 
        user_active:user_active, report_active_threshold: report_active_threshold, api_key:api_key, okta_user:okta_user, item_setup_access:item_setup_access, overview_dashboard_access,
        order_and_line_status_access, station_and_operator_performance_access, station_and_operator_packing_speed_access, station_and_operator_giveaway_access, station_and_operator_waste_access,
        line_productivity_access, per_product_productivity_access, calibration_report_access}
      submitData({ database: "ergopak_applications", table: "user", record: data });
    }
  }


  handleCancel() {
    const {current} = this.state
    current.okta_user = "normal"
    current.username = ""
    current.first_name = ""
    current.last_name = ""
    current.password_hash = ""
    current.confirmPassword = ""
    current.chosenCluster = null
    current.chosenUser = null
    this.setState({current, selectedReports: [], selectAllReports: false});
  }


  emailDuplicate(username, selectedUser){
    const { usernames } = this.props
    const {chosenUser} = this.state.current
    let usernameArray = []
    let message = ""

    for (let i = 0; i<usernames.length; i++){
      usernameArray.push(usernames[i].username)
    }

    if(selectedUser){

    }
    if ((selectedUser && username && selectedUser.username !== username && usernameArray.includes(username)) || !selectedUser && username && usernameArray.includes(username)){
        message = <Typography color="error" style={{fontSize:"12px"}}>
                    This Username already exists</Typography>
    }
    return message
  }

  render() {
    const { clusterNames, usernames, submitUserDataRS, updateUserDataRS, submitDataRS, userData, reportOptions } = this.props;
    const { username, current, initial, userList, reports, selectedReports, showPassword, showConfirmPassword} = this.state;
    const { first_name, last_name, password_hash, confirmPassword } = this.state.current

    
    let dropdownLabel = username ? "User to Modify" : "Select a user to modify";
    let usernameLabel = current.username ? "User name (must be an email address)" : "or start typing to create a new user";
    let passwordMatch = "";
    let passwordWarning = passwordFeedback(password_hash);
    let emailFeedback = emailWarning(current.username)
    let emailWarnings = this.emailDuplicate(current.username, current.chosenUser)
    let dontSave = true

    if (current.sid){
      if (!current.password_hash.length && !current.confirmPassword.length && validateEmail(current.username) && emailWarnings === "" && !isEmpty(current.chosenCluster) && first_name.length && last_name.length){
      dontSave = false
      }
      else if (current.password_hash.length && current.confirmPassword.length && validateEmail(current.username) && emailWarnings === "" && !isEmpty(current.chosenCluster) && first_name.length && last_name.length && checkPassword(password_hash) === 'ok' &&
      confirmPassword === password_hash){
        dontSave = false
      }
      else if (this.state.current.okta_user === "okta"){
        if (validateEmail(current.username) && emailWarnings === "" && !isEmpty(current.chosenCluster) && !isEmpty(emailWarnings)){
          dontSave = false
        }
      }
    }
    
    else if (!current.sid){
      if (validateEmail(current.username) && emailWarnings === "" && !isEmpty(current.chosenCluster) && first_name.length && last_name.length && checkPassword(password_hash) === 'ok' &&
          confirmPassword === password_hash){
        dontSave = false
      }
      else if (this.state.current.okta_user === "okta"){
        if (validateEmail(current.username) && emailWarnings === "" && !isEmpty(current.chosenCluster)){
          dontSave = false
        }
      }
    }
    if (!comparePassword(password_hash, confirmPassword) && password_hash ) {
      passwordMatch = (
        <Typography color="error" style={{fontSize:"12px"}}>

          Passwords don't match{" "}
        </Typography>
      );
    }
    let message
    if (submitDataRS === SUCCEEDED){
      message = <Typography style={{ color:"white", margin: theme.spacing(2) }}> User Saved!</Typography>
    }
    else{
      message = ""
    }
    let redirect = isEmpty(this.props.user) || this.props.user.is_admin ? "" :  <Navigate to="/home" replace={true}/>
    

    return (
      <div>
        <div style={classes(theme, isMobile).mainCard}>
          <Grid>
              <Grid container direction="row" alignItems="flex-end">
                <div>
                  <Autocomplete
                      sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                      style={isMobile ? {...classes(theme, isMobile).filterField} : classes(theme, isMobile).filterField}
                      options={this.state.usernames}
                      getOptionLabel={(e) => e.username}
                      isOptionEqualToValue={(o, v) => o.sid === v.sid}
                      value={current.chosenUser}
                      hidden={current.username && isEmpty(current.chosenUser)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={dropdownLabel}
                          variant="outlined"
                        />
                      )}
                      onChange={this.handleUserSelect.bind(this)}
                    />
                </div>

                <div>
                  <TextField
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{sx: {color: "#FFFFFF", [`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}}
                    variant="standard"
                    label={usernameLabel}
                    style={isMobile ? {...classes(theme, isMobile).usernameField} : isEmpty(current.chosenUser) && !current.username ? {...classes(theme, isMobile).filterField, width: theme.spacing(23)} : isEmpty(current.chosenUser) && current.username? {...classes(theme, isMobile).filterField, width: theme.spacing(22)} : {...classes(theme, isMobile).filterField, width: theme.spacing(22)}}
                    value={current.username}
                    onChange={this.handleUsernameChange.bind(this)}
                  />
                </div>
              </Grid>

              <Grid container direction="row">              
                <div style={isMobile ? { marginLeft: "4em", marginTop: "-0.5em"} : isEmpty(current.chosenUser) && current.username ? { marginLeft: "1em", marginTop: "-0.5em"} : { marginLeft: "25em", marginTop: "-0.5em"}}>
                  {emailFeedback}
                  {emailWarnings}
                </div>
              </Grid>              
              
              <Grid container direction="row" alignItems="left">
                <div hidden={!current.username}>
                  <TextField
                    sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                    type="text"
                    label={"First Name"}
                    style={isMobile ? {...classes(theme, isMobile).filterField} : {...classes(theme, isMobile).filterField, width: theme.spacing(22)}}
                    value={current.first_name}
                    onChange={this.handleFirstNameChange.bind(this)}
                  /> 
                </div>          

                <div hidden={!current.username}>
                  <TextField
                      sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                      type="text"
                      label={"Last Name"}
                      style={isMobile ? {...classes(theme, isMobile).filterField} : {...classes(theme, isMobile).filterField, width: theme.spacing(22)}}
                      value={current.last_name}
                      onChange={this.handleLastNameChange.bind(this)}
                    />
                </div>
              </Grid>
              <div style={isMobile ? { marginBottom:"1em", marginTop:"-0.5em"} : {marginLeft: "1em", marginBottom:"1em", marginTop:"-0.5em" }}>
                <FormControl
                  component="fieldset"
                  style={classes(theme, isMobile).bulletBox}
                  sx={{ display: !current.username ? "none" : null }}
                >
                  <RadioGroup
                    direction="row"
                    aria-label="okta_user"
                    name="okta_user"
                    value={current ? current.okta_user : null}
                    onChange={(e) => {
                      this.setState({
                        current: { ...current, okta_user: e.target.value },
                      });
                    }}
                    sx={{ display: current.username === "" ? "none" : null }}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="left"
                      sx={{ display: current.username === "" ? "none" : null }}
                    >
                      <Typography style={classes(theme, isMobile).bullet2}>
                        Account Type:
                      </Typography>
                      <FormControlLabel
                        sx={{ display: current.username === "" ? "none" : null }}
                        value={"normal"}
                        control={<Radio style={{ marginLeft: "1em" }}
                        sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}/>}
                        label={<span style={{ color:"white" }}> Normal </span>} 
                      />
                      <FormControlLabel
                        sx={{ display: current.username === "" ? "none" : null }}
                        value={"okta"}
                        control={<Radio sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}/>}
                        label={<span style={{ color:"white" }}> Okta </span>} 
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </div>            
              <Grid container direction="row" alignItems="left">
                <div hidden={!current.username || current.okta_user === 'okta'}>
                  <TextField
                    type={showPassword ? "string" : "password"}
                    sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                    style={isMobile ? {...classes(theme, isMobile).filterField} : {...classes(theme, isMobile).filterField, width: theme.spacing(22)}}
                    onChange={this.handlePasswordChange.bind(this)}
                    label={"Password"}
                    value={current.password_hash}
                  />
                
                <FormControlLabel
                    control={<Checkbox 
                                size="small" 
                                name="showClosed" 
                                sx={{color: blue[800],'&.Mui-checked' : {  color: blue[600]}}} />}
                    checked={showPassword}
                    label={<span style={{ fontSize: "0.8em", color:"white" }}> Show password </span>}
                    onChange={(e) => this.setState({ showPassword: !showPassword })}
                    style={{...classes(theme, isMobile).passwordCheckbox, marginTop: "1.5em"}}
                  />
                  </div>
              </Grid>

              <Grid container direction="row" alignItems="left">
                <div style={{marginLeft:"1rem", marginTop:"-0.5rem"}}>
                  {passwordWarning}
                </div>
              </Grid>
              <div hidden={!current.password_hash || checkPassword(password_hash) !== 'ok' || !current.username || current.okta_user === 'okta'}>          
                <Grid container direction="row" alignItems="left">
                  <TextField
                    type={showConfirmPassword ? "string" : "password"}
                    sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                    style={isMobile ? {...classes(theme, isMobile).filterField} : {...classes(theme, isMobile).filterField, width: theme.spacing(22)}}
                    onChange={this.handleConfirmPasswordChange.bind(this)}
                    label={"Confirm Password"}
                    value={this.state.current.confirmPassword}
                  />  
                  <FormControlLabel
                    control={<Checkbox 
                                size="small" 
                                name="showClosed" 
                                sx={{color: blue[800],'&.Mui-checked' : {  color: blue[600]}}} />}
                    checked={showConfirmPassword}
                    label={<span style={{ fontSize: "0.8em", color:"white" }}> Show password </span>}
                    onChange={(e) => this.setState({ showConfirmPassword: !showConfirmPassword })}
                    style={classes(theme, isMobile).passwordCheckbox}
                  />       
                </Grid>  
              </div> 

              <Grid container direction="row" alignItems="left">
                <div style={{marginLeft:"1rem", marginTop:"-0.5rem"}} hidden={!current.password_hash || checkPassword(password_hash) !== 'ok'}>
                  {passwordMatch}
                </div>
              </Grid>

              <Grid container direction="row" alignItems="left">
                <div hidden={!current.username}>
                  <Autocomplete
                    sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                    style={isMobile ? {...classes(theme, isMobile).filterField} : {...classes(theme, isMobile).filterField, width: theme.spacing(22)}}
                    options={clusterNames}
                    getOptionLabel={(e) => e.name}
                    isOptionEqualToValue={(o, v) => o.sid === v.sid}
                    value={this.state.current.chosenCluster}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"User's Cluster"}
                        variant="outlined"
                      />
                    )}
                    onChange={this.handleClusterSelect.bind(this)}
                  />
                </div>
              </Grid>

              <Grid container direction="row" alignItems="left">
                <div hidden={!current.username}>
                  <Autocomplete
                    multiple
                    sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                    style={isMobile ? {...classes(theme, isMobile).filterField} : {...classes(theme, isMobile).filterField, width: theme.spacing(22)}}
                    options={reportOptions}
                    getOptionLabel={(e) => e.name}
                    isOptionEqualToValue={(o, v) => o.sid === v.sid}
                    value={selectedReports}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={selectedReports.length > 1 ? "User's Reports" : "User's Report"}
                        variant="outlined"
                        sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, path:{color:"#FFFFFF"}}}
                      />
                    )}
                    onChange={this.handleReportSelect.bind(this)}
                  />
                </div>
                <div hidden={!current.username} style={{marginTop: "1.4em"}}>
                  <FormControlLabel
                    control = {<Checkbox
                      size="small"
                      sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}
                      checked = {this.state.selectAllReports}
                      onChange={this.handleSelectAllReports.bind(this)}
                      inputProps = {{ 'aria-label': 'controlled' }} />}
                      
                      label={<span style={{ fontSize: "0.8em", color:"white" }}> Select All Reports </span>}
                  />
                </div>
              </Grid>

              
              <div style={isMobile ? {marginLeft:"0em"} : { marginLeft: "1em", marginTop:"-0.5em" }}>
                <FormControl
                  component="fieldset"
                  style={classes(theme, isMobile).bulletBox}
                  sx={{ display: !current.username ? "none" : null }}
                >
                  <FormControlLabel
            control = {<Checkbox
              sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}

              checked = {this.state.current.item_setup_access}
              onChange={(e) => {
                this.setState({
                  current: { ...current, item_setup_access: e.target.checked },
                });
              }}
              inputProps = {{ 'aria-label': 'controlled' }} />}
            label={<span style={{ color:"white" }}> Item Setup Accessibility </span>} 

          />
                  <RadioGroup
                    direction="row"
                    aria-label="user_active"
                    name="user_active"
                    value={current ? current.user_active : null}
                    onChange={(e) => {
                      this.setState({
                        current: { ...current, user_active: e.target.value },
                      });
                    }}
                    sx={{ display: current.username === "" ? "none" : null }}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="left"
                      sx={{ display: current.username === "" ? "none" : null }}
                    >
                      <Typography style={classes(theme, isMobile).bullet}>
                        User Status:
                      </Typography>
                      <FormControlLabel
                        sx={{ display: current.username === "" ? "none" : null }}
                        value={true}
                        control={<Radio style={{ marginLeft: "1em" }}
                        sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}/>}
                        label={<span style={{ color:"white" }}> Active </span>} 
                      />
                      <FormControlLabel
                        sx={{ display: current.username === "" ? "none" : null }}
                        value={false}
                        control={<Radio sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}/>}
                        label={<span style={{ color:"white" }}> Cancelled </span>} 
                      />
                    </Grid>
                  </RadioGroup>
                  <RadioGroup
                    direction="row"
                    aria-label="user_active"
                    name="user_active"
                    value={current ? current.is_admin : null}
                    onChange={(e) => {
                      this.setState({
                        current: { ...current, is_admin: e.target.value },
                      });
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="left"
                      sx={{ display: current.username === "" ? "none" : null }}
                    >
                      <Typography style={classes(theme, isMobile).bullet}>
                        User Role:
                      </Typography>
                      <FormControlLabel
                        value={true}
                        control={<Radio style={{ marginLeft: "1em" }}
                        sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}/>}
                        label={<span style={{ color:"white" }}> Admin </span>} 
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio  style={{ marginLeft: "-0.1em" }}
                        sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}/>}
                        label={<span style={{ color:"white" }}>Client</span>}
                      />
                    </Grid>
                  </RadioGroup>
                  <Grid
                      style={{marginTop: "1em"}}
                      container
                      direction="row"
                      alignItems="left"
                      sx={{ display: current.username === "" ? "none" : null }}
                    >
                      <TextField 
                        value={current.report_active_threshold}
                        inputProps={{ type: 'number'}}  
                        label={<span style={{ color:"white" }}>Active Threshold (minutes)</span>} 
                        sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, path:{color:"#FFFFFF"}}}
                        onChange={this.handleThresholdChange.bind(this)}    
                        />        

                    </Grid>
                </FormControl>
              </div>
              <div hidden={!current.username}>
                <Grid item>
                  <Grid container direction="row">
                    <Button
                      style={classes(theme, isMobile).button}
                      variant="contained"
                      onClick={this.handleSave.bind(this)}
                      disabled={dontSave}
                    >
                      {" "}
                      Save
                    </Button>
                    <Button
                      style={classes(theme, isMobile).button}
                      variant="contained"
                      onClick={this.handleCancel.bind(this)}
                    >
                      {" "}
                      Cancel
                    </Button>
                    {message}
                  </Grid>
                  
                </Grid>
              </div>
          </Grid>
          
        </div>
        {redirect}
      </div>
    )
  }
}

export default styled(users)({ theme });