import React from "react";
import { Typography, Grid, TextField as MuiTextField, Button, CircularProgress} from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '../theme';
import { clone } from "../general/utils"
import { LOADING, SUCCEEDED } from '../general/requestStates'
import classes from "../styles/newSetup"
import { Navigate } from 'react-router-dom';
import { isEmpty } from "underscore";
import { isMobile } from "react-device-detect";
import { inputLabelClasses } from "@mui/material/InputLabel";



class ManageClusters extends React.Component {

  emptyFields = {
    Description: "",
    Tare: "",
    Minimum_Weight: "",
    Target_Weight: "",
    Maximum_Weight: "",
    Button: "",
    Effort: "",
    Lower_Limit: "",
    Upper_Limit: "",
    Nett_Weight: "",
    Band: "",
    LEDS: ""
  }
  
  state = {
    current: clone(this.emptyFields),
    redirect: false
  }

  componentDidUpdate(prevProps){
    const { postSetupRS } = this.props
    if (postSetupRS === SUCCEEDED && prevProps.postSetupRS === LOADING){
      this.setState({redirect: "/setups"})
    }
  }

  onChange(key, event){
    const { current } = this.state
    current[key] = event.target.value
    this.setState({})
  }

  checkSetupValidity(){
    const { current } = this.state
    return Object.values(current).map(e => e ? true : false).every(v => v === true)
  }

  handleClear(){
    this.setState({current: clone(this.emptyFields)})
  }

  handleCancel(){
    this.setState({redirect: "/setups"})
  }

  handleSave(){
    const { current } = this.state
    const { computerName, postSetup } = this.props
    current["Database"] = computerName
    postSetup(current)
  }

  getSaving(){
    const { postSetupRS } = this.props
    let saving = <div>
        <CircularProgress size={10.0} style={{marginRight: theme.spacing(0.9), marginLeft: theme.spacing(0.9)}}/>
      </div>
    return postSetupRS === LOADING ? saving : "Save"
  }

  namesToList(){
    const { computerName } = this.props
    if (!computerName){return null}
    let computerList = []
    for (let i = 0; i < computerName.length; i++){
      let name = computerName[i]
      computerList.push(<li key={ i }>{ name }</li>)
    }
    return computerList
  }
  
  render(){
    let computerNames = this.namesToList()
    const { redirect, current } = this.state
    let saver = this.getSaving()
    return(
      redirect ? <Navigate to={redirect} replace={true} /> :
      !computerNames ? <Navigate to={"/setups"} replace={true} /> :
      <div style={classes(theme, isMobile).mainCard}>
      <Grid container direction="column" alignItems="flex-start" justifyContent="center" >
          <Typography style={classes(theme, isMobile).label}> Add a new setup for the following Computers: </Typography>
          
          <Typography style={{...classes(theme, isMobile).label, marginTop:"-0.5em"}}>{computerNames}</Typography>
          
          <div>
            <MuiTextField variant="standard" style={classes(theme, isMobile).textField} label="Description" inputProps={{ style: { color: "white" } }} InputLabelProps={{sx: {color: "#FFFFFF",[`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}} onChange={this.onChange.bind(this, "Description")} value={current.Description}/>
            <MuiTextField variant="standard" type="number" style={classes(theme, isMobile).textField} label="Tare" inputProps={{ style: { color: "white" } }} InputLabelProps={{sx: {color: "#FFFFFF",[`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}} onChange={this.onChange.bind(this, "Tare")} value={current.Tare}/>
          </div>
          <div>
            <MuiTextField variant="standard" type="number" style={classes(theme, isMobile).textField} label="Minimum Weight (g)" inputProps={{ style: { color: "white" } }} InputLabelProps={{sx: {color: "#FFFFFF",[`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}} onChange={this.onChange.bind(this, "Minimum_Weight")} value={current.Minimum_Weight} />
            <MuiTextField variant="standard" type="number" style={classes(theme, isMobile).textField} label="Target Weight  (g)" inputProps={{ style: { color: "white" } }} InputLabelProps={{sx: {color: "#FFFFFF",[`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}} onChange={this.onChange.bind(this, "Target_Weight")} value={current.Target_Weight}/>
            <MuiTextField variant="standard" type="number" style={classes(theme, isMobile).textField} label="Maximum Weight  (g)" inputProps={{ style: { color: "white" } }} InputLabelProps={{sx: {color: "#FFFFFF",[`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}} onChange={this.onChange.bind(this, "Maximum_Weight")} value={current.Maximum_Weight}/>
          </div>
          <div>
            <MuiTextField variant="standard" type="number" style={classes(theme, isMobile).textField} label="Button" inputProps={{ style: { color: "white" } }} InputLabelProps={{sx: {color: "#FFFFFF",[`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}} onChange={this.onChange.bind(this, "Button")} value={current.Button}/>
            <MuiTextField variant="standard" type="number" style={classes(theme, isMobile).textField} label="Effort" inputProps={{ style: { color: "white" } }} InputLabelProps={{sx: {color: "#FFFFFF",[`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}} onChange={this.onChange.bind(this, "Effort")} value={current.Effort}/>
          </div>
          <div>
            <MuiTextField variant="standard" type="number" style={classes(theme, isMobile).textField} label="Lower Limit" inputProps={{ style: { color: "white" } }} InputLabelProps={{sx: {color: "#FFFFFF",[`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}} onChange={this.onChange.bind(this, "Lower_Limit")} value={current.Lower_Limit}/>
            <MuiTextField variant="standard" type="number" style={classes(theme, isMobile).textField} label="Upper Limit" inputProps={{ style: { color: "white" } }} InputLabelProps={{sx: {color: "#FFFFFF",[`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}} onChange={this.onChange.bind(this, "Upper_Limit")} value={current.Upper_Limit}/>
            <MuiTextField variant="standard" type="number" style={classes(theme, isMobile).textField} label="Nett Weight" inputProps={{ style: { color: "white" } }} InputLabelProps={{sx: {color: "#FFFFFF",[`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}} onChange={this.onChange.bind(this, "Nett_Weight")} value={current.Nett_Weight}/>
          </div>
          <div>
            <MuiTextField variant="standard" type="number" style={classes(theme, isMobile).textField} label="Band" inputProps={{ style: { color: "white" } }} InputLabelProps={{sx: {color: "#FFFFFF",[`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}} onChange={this.onChange.bind(this, "Band")} value={current.Band}/>
            <MuiTextField variant="standard" type="number" style={classes(theme, isMobile).textField} label="LEDS" inputProps={{ style: { color: "white" } }} InputLabelProps={{sx: {color: "#FFFFFF",[`&.${inputLabelClasses.shrink}`] : {color: "#FFFFFF"}}}} onChange={this.onChange.bind(this, "LEDS")} value={current.LEDS}/>
          </div>
          <div>
            <Button variant="contained" style={classes(theme, isMobile).button} disabled={!this.checkSetupValidity()} onClick={this.handleSave.bind(this)}> {saver} </Button>
            <Button variant="contained" style={classes(theme, isMobile).button} onClick={this.handleClear.bind(this)}> Clear </Button>
            <Button variant="contained" style={classes(theme, isMobile).button} onClick={this.handleCancel.bind(this)}> Cancel </Button>
          </div>
        </Grid>
      </div>
    )
  }
}

export default styled(ManageClusters)({theme})