import {connect} from 'react-redux';
import Layout from '../pages/oktaLogin';
import { login, getSessionData, addLoginAttempt, checkLoginCredentials } from "../reducers/userManagement"
import { submitData } from "../reducers/data"

const mapStateToProps = state => ({             
    user: state.userManagement.user,
    sessionExpired: state.userManagement.sessionExpired,
    latestSubmission: state.data.latestSubmission,
    loginRS: state.userManagement.loginRS,
    logOutRS: state.userManagement.logOutRS,
    addLoginAttemptRS: state.userManagement.addLoginAttemptRS,
    submitDataRS: state.data.submitDataRS,
})

const mapDispatchToProps = dispatch => ({
    getSessionData: () => dispatch(getSessionData()),
  
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)