import theme from "../theme";
import * as React from "react";
import { styled } from "@mui/material/styles";
import { blue } from '@mui/material/colors';
import { BrowserView, MobileView } from "react-device-detect";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControlLabel, Checkbox, Typography} from "@mui/material";

class overviewDashboard extends React.Component {
  constructor() {
    super();
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {width: 0, height: 0, tableView: false, showFullLinePath: false}; 
  }

  /**********************************************
   * Calculate & Update state of new dimensions *
   **********************************************/
  updateDimensions() {
    if (window.innerWidth < 717) {
      this.setState({width: window.innerWidth, height: window.innerWidth / 4.4, tableView: true});
    }
    else {
      this.setState({ width: window.innerWidth, height: window.innerWidth, tableView: false});
    }
  }

  //* Add event listener
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  
  //* Remove event listener
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  /*********************************************
   * Gives the table row its determined colour *
   *********************************************/
  changeColour(data){
    let colors = [];
    let ipmColour = null; 
    let overWeightcolour = null;
    let underWeightcolour = null;
    
    if (data.ipm < data.ipm_red){
      ipmColour = "red";
    }
    else if (data.ipm < data.ipm_yellow){
      ipmColour = "orange";
    }
    else{
      ipmColour = "green";
    }
    if (data.overweight > data.overweight_red){
      overWeightcolour = "red";
    }
    else if (data.overweight > data.overweight_yellow){
      overWeightcolour = "orange";
    }
    else{
      overWeightcolour = "green";
    }
    if (data.underweight > data.underweight_red){
      underWeightcolour = "red";
    }
    else if (data.underweight > data.underweight_yellow){
      underWeightcolour = "orange";
    }
    else{
      underWeightcolour = "green";
    }
    colors.push(ipmColour, overWeightcolour, underWeightcolour);
    return colors;
  }   

  /*******************************************
   * Sorts the data in order of its "colour" *
   *******************************************/
  sortData(){
    const {data} = this.props;
    let redData  = [];
    let yellowData = [];
    let greenData = [];

    for (let i = 0; i < data.length; i++){
      if (data.ipm > data.ipm_red ||data.overweight > data.overweight_red ||data.underweight > data.underweight_red) {
        redData.push(data[i]) ;
      }
      else if (data.ipm > data.ipm_yellow ||data.overweight > data.overweight_yellow ||data.underweight > data.underweight_yellow) {
        yellowData.push(data[i]);
      } 
      else {
        greenData.push(data[i]);
      }
    }
    let sortedData = redData.concat(yellowData, greenData);
    return sortedData;
  }

  /****************************
   * Generates the table rows *
   ****************************/
  generateRows() {
    let sortedData = this.sortData();
    const {showFullLinePath} = this.state
    let elements = [];
    
    for (let i = 0; i < sortedData.length; i++){
      let colors = this.changeColour(sortedData[i]);
      let ipmColour = colors[0];
      let overWeightcolour = colors[1];
      let underWeightcolour = colors[2];
      let lineStatus = <Typography style={{color: "green"}}> (ONLINE) </Typography>
      let time = new Date(sortedData[i].last_time_active)
      let currentTime = new Date(sortedData[i].current_time)
      if ((currentTime - time) >= 120000){
        lineStatus = <Typography style={{color: "red"}}> (OFFLINE) </Typography>
      }
      let element = 
        <TableRow key={i}>
          <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid"}} align="center"> {showFullLinePath ? sortedData[i].line_path : sortedData[i].line} {lineStatus}</TableCell>
          <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="center"> {sortedData[i].total_items.toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
          <TableCell style={{ color: "black", backgroundColor: ipmColour, borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="center"> {sortedData[i].ipm.toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
          <TableCell style={{ color: "black", backgroundColor: overWeightcolour, borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="center"> {sortedData[i].overweight.toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
          <TableCell style={{ color: "black", backgroundColor: underWeightcolour, borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="center"> {sortedData[i].underweight.toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
        </TableRow>
      elements.push(element);
    }
    this.rows = elements;
  }

  /****************************
   * Generates the table rows for mobile*
   ****************************/
  generateRowsMobile() {
    let sortedData = this.sortData();
    const {showFullLinePath} = this.state
    let elements = [];
    for (let i = 0; i < sortedData.length; i++){
      let colors = this.changeColour(sortedData[i]);
      let ipmColour = colors[0];
      let overWeightcolour = colors[1];
      let underWeightcolour = colors[2];
      let lineStatus = <Typography style={{color: "green"}}> (ONLINE) </Typography>
      let time = new Date(sortedData[i].last_time_active)
      let currentTime = new Date(sortedData[i].current_time)
      if ((currentTime - time) >= 120000){
        lineStatus = <Typography style={{color: "red"}}> (OFFLINE) </Typography>
      }
      let element = 
      <div style={{marginBottom:"2em"}}>
        <TableContainer component={Paper}>
          <Table sx={{ maxWidth: 310}}  size="small" aria-label="a dense table">
            <TableHead>
              <TableRow key={i} sx={{ backgroundColor: "#065f92" }}>
                <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="center" colSpan={2}> {showFullLinePath ? sortedData[i].line_path : sortedData[i].line} {lineStatus} </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Total Weight Packed (kg) </TableCell>
            <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {sortedData[i].total_items.toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
            </TableBody>
            <TableBody>
            <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Items Per Minute </TableCell>
            <TableCell style={{ color: "black", backgroundColor: ipmColour, borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {sortedData[i].ipm.toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
            </TableBody>
            <TableBody>
            <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Items Overweight % </TableCell>
            <TableCell style={{ color: "black", backgroundColor: overWeightcolour, borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {sortedData[i].overweight.toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
            </TableBody>   
            <TableBody>
            <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Items Underweight % </TableCell>
            <TableCell style={{ color: "black", backgroundColor: underWeightcolour, borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {sortedData[i].underweight.toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
            </TableBody>           
          </Table>
        </TableContainer>
        </div>
        
      elements.push(element);
    }
    this.mobileRows = elements;
  }



  render(){
    const { data } = this.props;

    if (data){
      this.generateRows()
      this.generateRowsMobile()
    }

    return(
      <div style={{marginLeft:"1em", marginTop: "1em"}}>
        <FormControlLabel
            control = {<Checkbox
              sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}
              checked = {this.state.showFullLinePath}
              onChange={(e) => {
                this.setState({
                  showFullLinePath: e.target.checked,
                });
              }}
              inputProps = {{ 'aria-label': 'controlled' }} />}
            label={<span style={{ color:"white" }}> Show Full Line Path </span>} 

          />
        <BrowserView>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}  size="small" aria-label="a dense table">
              <TableHead>
                <TableRow style={{marginTop: "5em"}} sx={{ backgroundColor: "#065f92" }}>
                  <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="center"> Line </TableCell>
                  <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="center"> Total Weight Packed (kg) </TableCell>
                  <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="center"> Items Per Minute </TableCell>
                  <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="center"> Items Overweight % </TableCell>
                  <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="center"> Items Underweight % </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.rows}
              </TableBody>
            </Table>
          </TableContainer>
        </BrowserView>
        <MobileView>
          {this.mobileRows}
        </MobileView>
      </div>
    );
  }
}

export default styled(overviewDashboard)({ theme })
