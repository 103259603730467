import React, { useState } from "react";
import { Drawer, IconButton, ListItemButton, ListItemIcon, ListItemText, List, useTheme, useMediaQuery, Typography } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import classes from "../styles/components/navigation"
import { NavLink } from "react-router-dom";

const DrawerComp = ({links}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  let url = window.location.href
  let url_parts = url.replace(/\/\s*$/,'').split('/'); 
  let loc = url_parts[url_parts.length - 1]
  
  // HANDLES ALL THE LINKS IN THE NAVIGATION BAR
  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <List>
          <ListItemButton>
            <ListItemIcon>
              <ListItemText>
                <NavLink
                  to="/home"
                  style={{...classes(theme).navTab, marginLeft:"3em"}}
                  activestyle={classes(theme).activeNavTab}
                  onClick={() => setOpen(open)}
                >
                <Typography style={loc === "home" ? {fontWeight: 600, color: theme.palette.secondary.main} : {}} > Reports </Typography>                  
                </NavLink>

                <NavLink 
                  to="/setups" 
                  style={classes(theme).navTab}
                  activestyle={classes(theme).activeNavTab}
                  onClick={() => setOpen(!open)}
                >
                <Typography style={loc === "setups" ? {fontWeight: 600, color: theme.palette.secondary.main} : {}}> Setups </Typography>
                </NavLink>

                <NavLink 
                  to="/users" 
                  style={classes(theme).navTab}
                  activestyle={classes(theme).activeNavTab}
                  onClick={() => setOpen(!open)}
                  sx = {{display: links.user.is_admin === false ? 'none' : null}}
                >
                <Typography style={loc === "users" ? {fontWeight: 600, color: theme.palette.secondary.main} : {}}> Users </Typography>
                </NavLink>

                <NavLink 
                  to="/manageClusters" 
                  style={classes(theme).navTab}
                  activestyle={classes(theme).activeNavTab}
                  onClick={() => setOpen(!open)}
                >
                <Typography style={loc === "manageClusters" ? {fontWeight: 600, color: theme.palette.secondary.main} : {}} > Clusters </Typography>
                </NavLink>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>
        </List>
      </Drawer>

      <IconButton onClick={() => setOpen(!open)}>
        <MenuRoundedIcon />
      </IconButton>
    </>
  );
};

export default DrawerComp;
