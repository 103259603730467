import "./global.css";
import React from "react";
import theme from './theme';
import store from "./app/store";
import Home from "./containers/Home";
import { Provider } from 'react-redux';
import Login from "./containers/Login";
import Users from "./containers/Users";
import Setups from "./containers/Setups";
import Reports from "./containers/Reports";
import NewSetup from "./containers/NewSetup";
import OktaLogin from "./containers/OktaLogin";
import { ThemeProvider } from '@mui/material/styles';
import ManageClusters from "./containers/ManageClusters";
import Navigation from "./components/containers/Navigation";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return ( 
    <>
    <ThemeProvider theme={theme} >
      <Provider store={store} >
          <Router>
          <Navigation />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              {/* <Route path="/login/okta" element={<OktaLogin />} /> */}
              <Route path="/okta" element={<OktaLogin />} />
              <Route path="/home" element={<Home />} />
              <Route path="/home/overviewDashboard" element={<Reports reportName="Overview Dashboard" type="table" interval={60} />} />
              <Route path="/home/orderLineStatus" element={<Reports reportName="Order and Line Status" orderShow={true} type="graph" csv={true} />} />
              <Route path="/home/stationOperatorPackingDistribution" element={<Reports orderShow={true} reportName="Station and Operator Packing Distribution" csv={true} type="graph"/>} />
              <Route path="/home/stationOperatorPackingSpeed" element={<Reports productShow={true} orderShow={true} reportName="Station and Operator Packing Speed" csv={true} type="graph"/>} />
              <Route path="/home/stationOperatorWaste" element={<Reports orderShow={true} reportName="Station and Operator Waste" csv={true} type="graph"/>} />
              <Route path="/home/stationOperatorGiveaway" element={<Reports orderShow={true} reportName="Station and Operator Giveaway" csv={true} type="graph"/>} />
              <Route path="/home/stationOperatorWaste" element={<Reports orderShow={true} reportName="Station and Operator Waste" csv={true} type="graph"/>} />
              <Route path="/home/stationOperatorPerformance" element={<Reports  orderShow={true} reportName="Station and Operator Performance" csv={true} pdf={true} downloadable={true} type="table"/>} />
              <Route path="/home/lineProductivity" element={<Reports orderShow={true} reportName="Line Productivity" type="table" csv={true} pdf={true} downloadable={true}/>} />
              <Route path="/home/perProductProductivity" element={<Reports productShow={true} reportName="Per Product Productivity" type="table" csv={true} pdf={true} downloadable={true}/>} />
              <Route path="/home/calibrationReport" element={<Reports reportName="Calibration Report" type="table"/>} />
              <Route path="/manageClusters" element={<ManageClusters />} />
              <Route path="/users" element={<Users />} />
              <Route path="/setups" element={<Setups />} />
              <Route path="/newSetup" element={<NewSetup />} />
            </Routes>
          </Router>
          <div style={{clear:"both", "height": "40px"}}></div>
      </Provider>
    </ThemeProvider>
    </>
  );
}

export default App;