import * as d3 from "d3";
import { debug } from "../general/utils";
// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/pie-chart
export function pieChart(data, {
  graphTitle = ([x]) => x,                                      // given d in data, returns the (ordinal) label
  value = ([, y]) => y,                                   // given d in data, returns the (quantitative) value
  totalItems = ([a]) => a,
  totalItemsValue = ([, b]) => b,
  avgItemsPerMinute = ([c]) => c,
  avgItemsPerMinuteValue = ([, d]) => d,
  batchGiveaway = ([e]) => e,
  batchGiveawayValue = ([, f]) => f,
  orderComplete = ([g]) => g,
  orderCompleteValue = ([, h]) => h,
  title,                                                  // given d in data, returns the title text
  totalItemsLabel,
  avgItemsPerMinuteLabel,
  batchGiveawayLabel,
  orderCompleteLabel,
  width = 640,                                            // outer width, in pixels
  height = 400,                                           // outer height, in pixels
  innerRadius = 0,                                        // inner radius of pie, in pixels (non-zero for donut)
  outerRadius = Math.min(width, height) / 2,              // outer radius of pie, in pixels
  labelRadius = (innerRadius * 0.2 + outerRadius * 0.6),  // center radius of labels
  format = ",",                                           // a format specifier for values (in the label)
  names,                                                  // array of names (the domain of the color scale)
  colors = ['#70AD47','#FFFF00','#FF0000'],               // array of colors for names
  stroke = innerRadius > 0 ? "none" : "white",            // stroke separating widths
  strokeWidth = 1,                                        // width of stroke separating wedges
  strokeLinejoin = "round",                               // line join of stroke separating wedges
  padAngle = stroke === "none" ? 1 / outerRadius : 0,     // angular separation between wedges
  } = {}) {
    // Compute values.
    const N = d3.map(data, graphTitle);
    const V = d3.map(data, value);
    const I = d3.range(N.length).filter(i => !isNaN(V[i]));


    const TP = d3.map(data, totalItems);
    const TPV = d3.map(data, totalItemsValue);
    console.log('TPV', TPV[0]) 
    const TPI = d3.range(TP.length).filter(i => !isNaN(TPV[i]));


    const Aipm = d3.map(data, avgItemsPerMinute);
    const AipmV = d3.map(data, avgItemsPerMinuteValue);
    const AipmI = d3.range(Aipm.length).filter(i => !isNaN(AipmV[i]));

    const BG = d3.map(data, batchGiveaway);
    const BGV = d3.map(data, batchGiveawayValue);
    const BGI = d3.range(BG.length).filter(i => !isNaN(BGV[i]));

    const OC = d3.map(data, orderComplete);
    const OCV = d3.map(data, orderCompleteValue);
    console.log('OCV', OCV)
    const OCI = d3.range(OC.length).filter(i => !isNaN(OCV[i]));
  
    // Unique the names.
    if (names === undefined) names = N;
    names = new d3.InternSet(names);
  
    // Chose a default color scheme based on cardinality.
    if (colors === undefined) colors = d3.schemeSpectral[names.size];
    if (colors === undefined) colors = d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), names.size);
  
    // Construct scales.
    const color = d3.scaleOrdinal(names, colors);
  
    // Compute titles.
    if (title === undefined) {
      const formatValue = d3.format(format);
      title = i => `${formatValue(V[i])}`;
      totalItemsLabel = i => `${TP[i]} :`;
      totalItemsValue = i => `${formatValue(TPV[i])}`;
      avgItemsPerMinuteLabel = i => `${Aipm[i]} :`;
      avgItemsPerMinuteValue = i => `${formatValue(AipmV[i])}`
      batchGiveawayLabel = i => `${BG[i]} :`;
      batchGiveawayValue = i => `${formatValue(BGV[i])} kg`
      orderCompleteLabel = i => `${OC[i]} :`;
      orderCompleteValue = i => `${formatValue(OCV[i])}%`;
    } /*else {
      const O = d3.map(data, d => d);
      const T = title;
      title = i => T(O[i], i, data);
    }*/
  
    // Construct arcs.
    const arcs      = d3.pie().padAngle(padAngle).sort(null).value(i => V[i])(I);
    const arc       = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
    const arcLabel  = d3.arc().innerRadius(labelRadius+45).outerRadius(labelRadius+5);

    const totalItemsDisplay         = d3.pie().padAngle(padAngle).sort(null).value(i => TPV[i])(TPI);
    const avgItemsPerMinuteDisplay  = d3.pie().padAngle(padAngle).sort(null).value(i => AipmV[i])(AipmI);
    const batchGiveawayDisplay        = d3.pie().padAngle(padAngle).sort(null).value(i => BGV[i])(BGI);
    const orderCompleteDisplay        = d3.pie().padAngle(padAngle).sort(null).value(i => OCV[i])(OCI);


    console.log("testing",(String(TPV[0].toLocaleString())).length )
    let totalItemsLength = (String(TPV[0].toLocaleString())).length
    
    const svg = d3.create("svg")
      .attr("width", width+140)
      .attr("height", height+130)
      .attr("viewBox", [(-width / 2) + 150, -height / 2 , width+60, height+50])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic; background: #323232; margin-Top: 2em");
    
    // Main Pie Chart
    svg.append("g")
      .attr("stroke-width", strokeWidth)
      .attr("stroke-linejoin", strokeLinejoin)
      .selectAll("path")
      .data(arcs)
      .join("path")
      .attr("fill", d => color(N[d.data]))
      .attr("d", arc)
      .append("title")
      .text(d => title(d.data));
      
    svg.append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 13)
      .attr("font-weight", "bold")
      .attr("text-anchor", "middle")
      .selectAll("text")
      .data(arcs)
      .join("text")
      .attr("transform", d => `translate(${arcLabel.centroid(d)})`)
      .selectAll("tspan")
      .data(d => {
        const lines = `${title(d.data)}`.split(/\n/);
        return lines;
      })
      .join("tspan")
      .attr("x", 4)
      .attr("y", (_, i) => `${i-1}em`)
      .attr("font-weight", "bold")
      .text();

    /****************
    * Total Items *
    *****************/
    svg.append("text")
    .attr("font-family", "sans-serif")
    .attr("font-size", 18)
    .attr("font-weight", "bold")
    .attr("text-anchor", "middle")
    .attr("x", 294)
    .attr("y", -30)
    .style("fill", "white")
    .text("Total Items :");

    svg.append("text")  
    .attr("font-family", "sans-serif")
    .attr("font-size", 18)
    .attr("font-weight", "bold")
    .attr("text-anchor", "middle")
    .attr("x", 420)
    .attr("y", -30)
    .style("fill", "white")
    .text((TPV[0].toLocaleString('en-US')).replace(",", " ").replace(".", ","));
    
    /******************
    * Avg Items p/m *
    *******************/
    svg.append("text")
    .attr("font-family", "sans-serif")
    .attr("font-size", 18)
    .attr("font-weight", "bold")
    .attr("text-anchor", "middle")
    .attr("x", 280)
    .attr("y", 0)
    .style("fill", "white")
    .text("Avg Items p/m :");

    svg.append("text")  
    .attr("font-family", "sans-serif")
    .attr("font-size", 18)
    .attr("font-weight", "bold")
    .attr("text-anchor", "middle")
    .attr("x", 420)
    .attr("y", 0)
    .style("fill", "white")
    .text((AipmV[0].toLocaleString('en-US')).replace(",", " ").replace(".", ","));

    /*****************
    * Batch Giveaway *
    ******************/
     svg.append("text")
     .attr("font-family", "sans-serif")
     .attr("font-size", 18)
     .attr("font-weight", "bold")
     .attr("text-anchor", "middle")
     .attr("x", 284.5)
     .attr("y", 30)
     .style("fill", "white")
     .text("Batch Giveaway :");
 
     svg.append("text")  
     .attr("font-family", "sans-serif")
     .attr("font-size", 18)
     .attr("font-weight", "bold")
     .attr("text-anchor", "middle")
     .attr("x", 420)
     .attr("y", 30)
     .style("fill", "white")
     .text(`${BGV[0].toLocaleString('en-US').replace(",", " ").replace(".", ",")} kg`);

    /*******************
    * Order % Complete *
    ********************/
      svg.append("text")
      .attr("font-family", "sans-serif")
      .attr("font-size", 18)
      .attr("font-weight", "bold")
      .attr("text-anchor", "middle")
      .attr("x", 275.5)
      .attr("y", 60)
      .style("fill", "white")
      .text("Order % Complete :");
  
      svg.append("text")  
      .attr("font-family", "sans-serif")
      .attr("font-size", 18)
      .attr("font-weight", "bold")
      .attr("text-anchor", "middle")
      .attr("x", 420)
      .attr("y", 60)
      .style("fill", "white")
      .text(`${OCV[4].toLocaleString('en-US').replace(",", " ").replace(".", ",")} %`);

    
  svg.append("circle")
    .attr("cx",-130)
    .attr("cy",180)
    .attr("r", 5)
    .style("fill", "#FF0000");

  svg.append("text")
    .attr("x",-120)
    .attr("y",185)
    .text("Items Underweight")
    .style("font-size", "13px")
    .attr("font-family", "sans-serif")
    .style("fill", "white");

  svg.append("circle")
    .attr("cx",40)
    .attr("cy",180)
    .attr("r", 5)
    .style("fill", "#FFFF00");

  svg.append("text")
    .attr("x",50)
    .attr("y",185)
    .text("Items Overweight")
    .style("font-size", "13px")
    .attr("font-family", "sans-serif")
    .style("fill", "white");

  svg.append("circle")
    .attr("cx",200)
    .attr("cy",180)
    .attr("r", 5)
    .style("fill", "#70AD47");

  svg.append("text")
    .attr("x",210)
    .attr("y",185)
    .text("Items On Weight")
    .style("font-size", "13px")
    .attr("font-family", "sans-serif")
    .style("fill", "white");

    svg.append("text")
    .attr("x",85)
    .attr("y",200)
    // .attr("x",-80)
    // .attr("y",200)
    .text((V[1].toLocaleString('en-US')).replace(",", " ").replace(".", ","))
    .style("font-size", "13px")
    .attr("font-family", "sans-serif")
    .style("fill", "white");
    
    svg.append("text")
    // .attr("x",85)
    // .attr("y",200)
    .attr("x",-80)
    .attr("y",200)
    .text((V[2].toLocaleString('en-US')).replace(",", " ").replace(".", ","))
    .style("font-size", "13px")
    .attr("font-family", "sans-serif")
    .style("fill", "white");

    svg.append("text")
    .attr("x",240)
    .attr("y",200)
    .text((V[3].toLocaleString('en-US')).replace(",", " ").replace(".", ","))
    .style("font-size", "13px")
    .attr("font-family", "sans-serif")
    .style("fill", "white");

  // Note from Pieka: I'm leaving the below as is, since we may want to make graphs downloadable in the future
  //return Object.assign(svg.node(), {scales: {color}});
  return {svg: svg.node(), otherStuff: {scales: {color}}}
}