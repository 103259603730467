import { createSlice } from '@reduxjs/toolkit';
import {INITIAL, LOADING, SUCCEEDED, FAILED} from '../general/requestStates'
import { debug } from '../general/utils';

export const thisReducer = createSlice({
  name: 'clusterManagement',
  initialState: {
    clientOptions: null,
    countryOptions: null,
    siteOptions: null,
    buildingOptions: null,
    computerOptions: null,
    clusterNames: [],
    getClusterNamesRS: INITIAL,
    getFilterOptionsRS: INITIAL,
    getClustersRS: INITIAL,
    clusters: [],
    getClusterNames: INITIAL,
  },
  reducers: {
    getFilterOptions: (state) => {
      debug("Getting Filter Options...")
      state.getFilterOptionsRS = LOADING
    },
    getFilterOptionsSuccess: (state, action) => {
      debug("reducer for getFilterOptionsSuccess called!", action)
      debug("Filters successfully retrieved")
      state.getFilterOptionsRS = SUCCEEDED
      console.log('action.payload', action.payload)
      state.clientOptions = action.payload.clients
      state.countryOptions = action.payload.countries
      state.siteOptions = action.payload.sites
      state.buildingOptions = action.payload.buildings
      state.computerOptions = action.payload.computers
    },
    getFilterOptionsFail: (state, action) => {
      debug("Failed to retrieve filters")
      state.getFilterOptionsRS = FAILED
    },
    getClusters: (state, action) => {
      debug("Getting clusters...")
      state.getClustersRS = LOADING
    },
    getClustersSuccess: (state, action) => {
      debug("Clusters successfully retrieved")
      state.getClustersRS = SUCCEEDED
      state.clusters = action.payload
    },
    getClustersFail: (state, action) => {
      debug("Failed to retrieve clusters")
      state.getClustersRS = FAILED
    },
    getClusterNames: (state) => {
      debug("Loading Cluster Names...")
      state.getClusterNamesRS = LOADING
      debug("reducer for getClusterNames called!")
    },
    getClusterNamesSuccess: (state, action) => {
      debug("Get clusterNames success")
      state.clusterNames = action.payload
      debug("Cluster Names successfully retrieved")
      state.getClusterNamesRS = SUCCEEDED
    },
    getClusterNamesFail: (state) => {
      debug("Failed to retrieve cluster names")
      state.getClusterNamesRS = FAILED
    },
  },
})

export const { getFilterOptions, getClusters, getClusterNames } = thisReducer.actions

export default thisReducer.reducer