import {connect} from 'react-redux'
import Layout from '../navigation';
import { logOut, getSessionData, clearOktaStatus } from '../../reducers/userManagement'


const mapStateToProps = state => ({
    user: state.userManagement.user,
    getSessionDataRS: state.userManagement.getSessionDataRS,
    loginRS: state.userManagement.loginRS,
    logOutRS: state.userManagement.logOutRS,
    getOrdersRS: state.data.getOrdersRS,
    getProductsRS: state.data.getProductsRS,
    loginRS: state.userManagement.loginRS,
    oktaUser: state.userManagement.oktaUser,
    serverDomain: state.userManagement.serverDomain
})

const mapDispatchToProps = dispatch => ({
    logOut: () => dispatch(logOut()),
    getSessionData: () => dispatch(getSessionData()),
    clearOktaStatus: () => dispatch(clearOktaStatus()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)