import { createSlice } from '@reduxjs/toolkit';
import {INITIAL, LOADING, SUCCEEDED, FAILED} from '../general/requestStates';
import { debug } from '../general/utils';

export const thisReducer = createSlice({
  name: 'data',
  initialState: {
    latestSubmission: null,
    justData: null,
    latestQuery: null,
    orders: [],
    products:[],
    clusterLines: [],
    overviewOrders:[],
    getDataRS: null,
    getLinesRS: null,
    getOrdersRS: INITIAL,
    getProductsRS: INITIAL,
    getOverviewOrdersRS: INITIAL,
    submitDataRS: INITIAL,
    deleteDataRS: INITIAL,
  },
  reducers: {
    submitData: (state, action) => {
      debug("Loading submitted data...")
      state.submitDataRS = LOADING
    },
    submitDataSuccess: (state, action) => {
      debug("reducer for submitDataSuccess called!", action)
      state.submitDataRS = SUCCEEDED
      state.latestSubmission = action.payload
      state.justData = action.payload.data
      console.log("in reducer", action.payload)
      console.log("in reducer", state.latestSubmission)
    },
    submitDataFail: (state, action) => {
      debug("Failed to retrieve submitted data")
      state.submitDataRS = FAILED
    },
    deleteData: (state, action) => {
      debug("Deleting data...")
      state.deleteDataRS = LOADING
    },
    deleteDataSuccess: (state, action) => {
      debug("reducer for deleteDataSuccess called!", action)
      state.deleteDataRS = SUCCEEDED
      state.latestSubmission = action.payload.records
    },
    deleteDataFail: (state, action) => {
      debug("Failed to delete data")
      state.deleteDataRS = FAILED
    },

    getData: (state) => {
      state.getDataRS = LOADING
    },
    getDataSuccess: (state, action) => {
      debug("Data retrieved successfully")
      state.getDataRS = SUCCEEDED
      state.latestQuery = action.payload
    },
    getDataFail: (state) => {
      debug("Failed to retrieve data")
      state.getDataRS = FAILED
    },

    getOrders: (state) => {
      state.getOrdersRS = LOADING
    },
    getOrdersSuccess: (state, action) => {
      let orders = action.payload.filter(e => (e.batchnumber !== null && e.batchnumber.length !== 0))
      // for (let i = 0; i < orders.length; i++) {
      //   let parts = orders[i].Date.split("-")
      //   orders[i].Date = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2])).getTime()
      // }
      state.orders = orders
      state.getOrdersRS = SUCCEEDED
    },
    getOrdersFail: (state) => {
      state.getOrdersRS = FAILED
    },
    getProducts: (state) => {
      state.getProductsRS = LOADING
    },
    getProductsSuccess: (state, action) => {
      state.products = action.payload
      state.getProductsRS = SUCCEEDED
    },
    getProductsFail: (state) => {
      state.getProductsRS = FAILED
    },
    getOverviewOrders: (state) => {
      state.getOverviewOrdersRS = LOADING
    },
    getOverviewOrdersSuccess: (state, action) => {
      state.overviewOrders = action.payload
      state.getOverviewOrdersRS = SUCCEEDED
    },
    getOverviewOrdersFail: (state) => {
      state.getOverviewOrdersRS = FAILED
    },
    getLines: (state) => {
      state.getOverviewOrdersRS = LOADING
    },
    getLinesSuccess: (state, action) => {
      state.clusterLines = action.payload
      state.getOverviewOrdersRS = SUCCEEDED
    },
    getLinesFail: (state) => {
      state.getOverviewOrdersRS = FAILED
    },
  },
})

export const { submitData, deleteData, getData, saveOrderData, getOrders, getProducts, getOverviewOrders, getLines } = thisReducer.actions

export default thisReducer.reducer